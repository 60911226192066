/* eslint-disable @typescript-eslint/no-unused-vars */
import PptxGenJS from "pptxgenjs";
import { SlideHeader } from "../Components/SlideHeader";
import {
  containerWidth,
  headerHeight,
  heroSectionHeight,
  secondaryColor,
  sideMargin,
  slideWidth,
} from "../Components/SlideConstants";
import { SlideFooter } from "../Components/SlideFooter";
import { SlideSource } from "../Components/SlideSource";
import { getInchesFromPixels } from "../Components/getInchesfromPixels";
import { textCapitalize } from "../../../utils/textCapitalize";

export const TOC2Slide = (pptx: PptxGenJS, pageNo?: number, data?: any) => {
  const slide = pptx.addSlide();
  SlideHeader(slide, data);

  const columnWidths = [0.4, slideWidth - sideMargin * 2 - 0.5 - 0.2, 0.5];

  const rowData = [
    {
      title: "Frost & Sullivan Analytics Methodology & Next Steps",
      pageNo: "18",
      inner: false,
    },
    {
      title: "Frost & Sullivan Analytics Methodology",
      pageNo: "19",
      inner: true,
    },
    {
      title: "Next Steps: Benefits & Impacts of Growth Opportunities",
      pageNo: "20",
      inner: true,
    },
    {
      title: "Next Steps: The Transformational Growth Partnership",
      pageNo: "20",
      inner: true,
    },
    {
      title: "Next Steps: Competitive Benchmarking with the Frost Radar",
      pageNo: "21",
      inner: true,
    },
    {
      title:
        "Frost Radar Metrics: 2 Major Indices, 10 Analytical Ingredients, 1 Platform",
      pageNo: "22",
      inner: true,
    },
    {
      title:
        "Next Steps: Best Practices Implementation with the 10 Growth Processes",
      pageNo: "23",
      inner: true,
    },
    {
      title: "Next Steps: Companies to Action (C2A)",
      pageNo: "24",
      inner: true,
    },
  ];

  const tocRow = [
    ...rowData.map((item) => {
      return getTOCRow(item.title, item.pageNo, item.inner);
    }),
  ];

  slide.addTable(tocRow, {
    x: 0.2, // x position
    y: headerHeight + heroSectionHeight + 0.3, // y position
    w: slideWidth, // width
    h: 0.4, // height
    border: { pt: 1, color: "ffffff" }, // Border for the table
    fontSize: 14, // Font size for text in the table
    // fill: ["CCCCCC"],

    colW: columnWidths,
  });

  const rowData2 = [
    {
      title:
        "Exhibit 1:The Strategic Imperative 8™: Factors Creating Pressure on Growth",
      pageNo: "3",
      inner: false,
    },
    {
      title: "Exhibit 2: The Strategic Imperative 8™ Defined",
      pageNo: "4",
      inner: false,
    },
    {
      title: `Exhibit 3: Top Transformations Impacting Growth in ${textCapitalize(
        data?.title.sector
      )}, ${data?.title.region}, ${data?.title.date}`,
      pageNo: "5",
      inner: false,
    },
    {
      title: `Exhibit 4: Top Transformations Impacting Growth in ${textCapitalize(
        data?.title.sector
      )}, ${data?.title.region}, ${data?.title.date}`,
      pageNo: "6",
      inner: false,
    },
  ];

  const tocRow2 = [
    ...rowData2.map((item) => {
      return getTOCRow(item.title, item.pageNo, item.inner);
    }),
  ];
  slide.addText("Exhibits", {
    x: sideMargin,
    y: headerHeight + heroSectionHeight + 3.6,
    w: containerWidth,
    color: secondaryColor,
    fontSize: 16,
    h: getInchesFromPixels(16),
    bold: false,
  });
  slide.addTable(tocRow2, {
    x: 0.2, // x position
    y: headerHeight + heroSectionHeight + 3.8, // y position
    w: slideWidth, // width
    h: 0.4, // height
    border: { pt: 1, color: "ffffff" }, // Border for the table
    fontSize: 14, // Font size for text in the table
    // fill: ["CCCCCC"],

    colW: columnWidths,
  });

  SlideFooter(slide, pageNo);
};

export const getTOCRow = (title: string, pageNo: string, inner?: boolean) => {
  const totalSpace = 115;
  const adjustedTitle = title;
  //   const adjustedTitle =
  //     title +
  //     "   " +
  //     "-".repeat(title.length < 115 ? totalSpace - title.length - 25 : 0);
  const textCol = {
    text: `${adjustedTitle}`.trim(),
    options: {
      align: "left",
      color: "000000",
      valign: "center",
      margin: [5, 10, 5, 20],
      colspan: inner ? 1 : 2,
    },
  };
  const pageNoCol = {
    text: pageNo,
    options: { align: "right", color: "000000", valign: "center" },
  };
  const dummyCol = {
    text: "",
  };
  return inner ? [dummyCol, textCol, pageNoCol] : [textCol, pageNoCol];
};
