import PptxGenJS from "pptxgenjs";
import { slideHeight, slideWidth } from "../Components/SlideConstants";

import logo from "../../assets/pdf/logo.png";
import coverImage from "../../assets/pdf/coverContent.png";
import { getInchesFromPixels } from "../Components/getInchesfromPixels";
import { textCapitalize } from "../../../utils/textCapitalize";

const logoWidth = 4.17;
const date = new Date();

// Calculate x for centering
const centerX = (slideWidth - logoWidth) / 2;

export const CoverSlide = (pptx: PptxGenJS, data: any) => {
  // console.log("CoverSlide -> data", data);
  const slide = pptx.addSlide();

  slide.background = { color: "ffffff" };

  slide.addShape(pptx.ShapeType.rect, {
    x: 0,
    y: 0,
    w: slideWidth,
    h: slideHeight,
    fill: { color: "172C55" },
  });

  slide.addImage({
    path: logo,
    x: centerX,
    y: 0.3,
    w: logoWidth,
    h: 0.4,
  });
  slide.addText(
    [
      {
        text: "TRANSFORMATIONAL GROWTH JOURNEY​",
        options: { color: "ffffff", breakLine: true, bold: true, fontSize: 28 },
      },
      {
        text: `“Powered by the Growth Pipeline Engine”`,
        options: { color: "ffffff" },
      },
    ],
    {
      x: (slideWidth - (slideWidth - 0.6)) / 2,
      y: 1.3,
      w: slideWidth - 0.6,
      fontSize: 18,
      h: getInchesFromPixels(40),
      color: "ffffff",
      align: "center",
    }
  );

  slide.addImage({
    path: coverImage,
    x: (slideWidth - (slideWidth - 2)) / 2,
    y: 2.3,
    w: slideWidth - 2,
    h: 5,
  });

  slide.addShape(pptx.ShapeType.line, {
    x: 3.1,
    y: 7.8,
    w: 0,
    h: 3.2,
    line: { color: "ffffff", width: 1 },
  });

  slide.addText(
    [
      {
        text:
          `Top 10 Strategic Imperatives in ${textCapitalize(
            data?.title.sector
          )}, ${data?.title.region}, ${data?.title.date}` + "\n",
        options: {
          color: "ffffff",
          breakLine: true,
          bold: true,
          fontSize: 24,
        },
      },
      {
        text: `Date: ${date.toLocaleDateString()}`,
        options: { color: "ffffff" },
      },
    ],
    {
      x: 0.3,
      y: slideHeight - 2.5,
      w: 2.6,
      fontSize: 14,
      // h: getInchesFromPixels(40),
      color: "ffffff",
      align: "left",
    }
  );

  slide.addText("Our Focus Today", {
    x: 3.5,
    y: 7.8,
    fontSize: 18,
    color: "FFFFFF",
    bold: true,
    underline: { style: "sng" },
  });

  slide.addText(
    "How is your organization maximizing its future growth potential?​",
    {
      x: 0.3,
      y: slideHeight - 0.3,
      fontSize: 20,
      w: slideWidth - 0.6,
      color: "F26710",
    }
  );

  const tableData = [
    [
      {
        text: "STRATEGIC IMPERATIVE",
        options: {
          x: 0.3,
          y: 0.5,
          fontSize: 16,
          color: "00AFF0",
          bold: true,
        },
      },
      {
        text: "BENEFITS & IMPACTS",
        options: {
          x: 3.5,
          y: 0.5,
          fontSize: 16,
          color: "00AFF0",
          bold: true,
        },
      },
    ],
    [
      {
        text: "Why Now? Why This? Why You?" + "\n",
        options: {
          x: 0.3,
          y: 1.5,
          fontSize: 10,
          color: "FFFFFF",
          bold: true,
        },
      },
      {
        text: "Survive & Thrive: Transformation" + "\n",
        options: {
          x: 3.5,
          y: 1.5,
          fontSize: 10,
          color: "FFFFFF",
          bold: true,
        },
      },
    ],
    [
      {
        text: "OUR SOLUTION",
        options: {
          x: 0.3,
          y: 3,
          fontSize: 16,
          color: "00AFF0",
          bold: true,
        },
      },
      {
        text: "NEXT STEP",
        options: {
          x: 3.5,
          y: 3,
          fontSize: 16,
          color: "00AFF0",
          bold: true,
        },
      },
    ],
    [
      {
        text:
          "The Top Transformations Impacting Future Growth Potential" + "\n",
        options: {
          x: 0.3,
          y: 3.5,
          fontSize: 10,
          color: "FFFFFF",
          bold: true,
        },
      },
      {
        text: "Growth Pipeline Dialog" + "\n",
        options: {
          x: 3.5,
          y: 3.5,
          fontSize: 10,
          color: "FFFFFF",
          bold: true,
        },
      },
    ],
    [
      {
        text: "Participants",
        options: {
          x: 0.3,
          y: 5,
          fontSize: 16,
          color: "00AFF0",
          bold: true,
        },
      },
      {
        text: "Presenters",
        options: {
          x: 3.5,
          y: 5,
          fontSize: 16,
          color: "00AFF0",
          bold: true,
        },
      },
    ],
    [
      {
        text: "<Client Name>​",
        options: {
          x: 0.3,
          y: 5.5,
          fontSize: 10,
          color: "FFFFFF",
          bold: true,
        },
      },
      {
        text: "<Frost Team>​",
        options: {
          x: 3.5,
          y: 5.5,
          fontSize: 10,
          color: "FFFFFF",
          bold: true,
        },
      },
    ],
  ];

  slide.addTable(tableData, {
    x: 3.4,
    y: 8.1,
    w: slideWidth - 3.2,
    colW: [2.4, 2.4],
  });
};
