import PptxGenJS from "pptxgenjs";
import { SlideHeader } from "../Components/SlideHeader";
import {
  headerHeight,
  heroSectionHeight,
  primaryColor,
  secondaryColor,
  sideMargin,
  slideWidth,
  tableBorderColor,
  tertiaryColor,
} from "../Components/SlideConstants";
import { SlideFooter } from "../Components/SlideFooter";
import { SlideSource } from "../Components/SlideSource";
import { getInchesFromPixels } from "../Components/getInchesfromPixels";

export const SI8Slide = (pptx: PptxGenJS, pageNo?: number, data?: any) => {
  const slide = pptx.addSlide();
  SlideHeader(slide, data);

  slide.addText(`Exhibit 2: The Strategic Imperative 8™ Defined`, {
    x: 0.4,
    y: headerHeight + heroSectionHeight + 0.5,
    color: secondaryColor,
    fontSize: 14,
    h: getInchesFromPixels(14),
    bold: false,
    w: "90%",
  });

  const tableData = [
    {
      title: "Innovative Business Models",
      description:
        "A new revenue model that defines how a company creates and capitalizes economic value, typically impacting its value proposition, product offering, operational strategies, and brand positioning.",
    },
    {
      title: "Customer Value Chain Compression",
      description:
        "Customer value chain compression because of advanced technologies, internet platforms, and other direct-to-consumer models that enables reduction in friction and the number of steps in customer journeys.",
    },
    {
      title: "Transformative Mega Trends",
      description:
        "Global forces that define the future world with their far-reaching impact on business, societies, economies, cultures, and personal lives.",
    },
    {
      title: "Internal Challenges",
      description:
        "The internal organizational behaviors that prevent a company from making required changes.",
    },
    {
      title: "Competitive Intensity",
      description:
        "A new wave of competition from start-ups and digital business models that challenge the standing conventions of the past, compelling established industries to re-think their competitive stance.",
    },
    {
      title: "Geopolitical Chaos",
      description:
        "Chaos and disorder arising from political discord, natural calamities, pandemics, and social unrest that impact global trade, collaboration, and business security.",
    },
    {
      title: "Disruptive Technologies",
      description:
        "New, disruptive technologies that are displacing the old, and significantly altering the way consumers, industries, or businesses operate.",
    },
    {
      title: "Industry Convergence",
      description:
        "Collaboration between previously disparate industries to deliver on whitespace cross-industry growth opportunities.",
    },
  ];

  const columnWidths = [2, 5.5];

  const rows = [
    ...tableData.map((item: any) => {
      return [
        {
          text: item?.title,
          options: {
            align: "right" as PptxGenJS.HAlign,
            valign: "middle" as PptxGenJS.VAlign,
            color: "ffffff",
            fill: { color: "1582C5" },
            bold: true,
          },
        },
        {
          text: item?.description,
          options: {
            align: "left" as PptxGenJS.HAlign,
            valign: "middle" as PptxGenJS.VAlign,
          },
        },
      ];
    }),
  ];

  slide.addTable(rows, {
    x: 0.4, // x position
    y: headerHeight + heroSectionHeight + 0.8,
    w: "100%", // width
    h: "55%", // height
    border: { pt: 1, color: tableBorderColor }, // Border for the table
    fontSize: 12, // Font size for text in the table
    // fill: ["#FFFFFF"], // Background color for cells
    align: "left", // Center align text in cells
    colW: columnWidths,
  });

  SlideSource(slide, 9 + heroSectionHeight);

  SlideFooter(slide, pageNo);
};
