import React, { memo, useEffect, useRef, useState } from "react";
import TableRow from "./tableRow";
import Icon from "../icons";
import { TableWrapper } from "./table.styled";

interface TableProps {
  setTableInfo?: any;
  data?: any;
  setRowData?: any;
  sortBy?: any;
  addNew?: boolean;
  updateHandler?: (id: string, item: any) => void;
  deleteHandler?: (id: string) => void;
  handleRowSubmit?: (rowData: any) => void;
  rowId?: string;
  setData?: any;
  setAddNew?: (value: boolean) => void;
  setSorting?: (value: string) => void;
  sorting?: string;
  order?: string;
  setOrder?: (value: string) => void;
  edited?: any;
  sort?: any;
  filterData?: any;
}

const Table = ({
  data,
  setRowData,
  sortBy,
  updateHandler,
  deleteHandler,
  handleRowSubmit,
  setAddNew,
  setData,
  setSorting,
  sorting,
  order,
  setOrder,
  edited,
  addNew,
  filterData,
}: TableProps) => {
  const tableRef = useRef<any>();

  // useEffect(() => {
  //   if (addNew) {
  //     if (tableRef.current) {
  //       tableRef.current
  //         .querySelector("table tbody tr:last-child")
  //         .scrollIntoView({
  //           behavior: "smooth",
  //           block: "end",
  //         });
  //     }
  //   }
  // }, [addNew]);

  const [isAnyRowEditing, setIsAnyRowEditing] = useState(false);
  const handleEditModeChange = (isEditing) => {
    setIsAnyRowEditing(isEditing);
  };

  return (
    <TableWrapper>
      <React.Fragment>
        <div className="box">
          <div className="table-holder" ref={tableRef}>
            <table className="table" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th>Strategic Imperative Category</th>
                  <th>Strategic Imperative</th>
                  <th>Details</th>
                  <th>
                    <button
                      className={
                        sorting === "ImpactScore" && order === "Asc"
                          ? "up"
                          : sorting === "ImpactScore" && order === "Desc"
                          ? "down"
                          : ""
                      }
                      onClick={() => {
                        setSorting && setSorting("ImpactScore");

                        setOrder &&
                          setOrder(
                            order === "" ? "Asc" : order === "Asc" ? "Desc" : ""
                          );
                      }}
                    >
                      Impact Score (%)
                      <Icon icon="triangles" size="15" />
                    </button>
                  </th>
                  <th>
                    <button
                      className={
                        sorting === "ImpactStartYear" && order === "Asc"
                          ? "up"
                          : sorting === "ImpactStartYear" && order === "Desc"
                          ? "down"
                          : ""
                      }
                      onClick={() => {
                        setSorting && setSorting("ImpactStartYear");

                        setOrder &&
                          setOrder(
                            order === "" ? "Asc" : order === "Asc" ? "Desc" : ""
                          );
                      }}
                    >
                      Impact Starts During
                      <Icon icon="triangles" size="15" />
                    </button>
                  </th>
                  <th>
                    <button
                      className={
                        sorting === "ImpactDuration" && order === "Asc"
                          ? "up"
                          : sorting === "ImpactDuration" && order === "Desc"
                          ? "down"
                          : ""
                      }
                      onClick={() => {
                        setSorting && setSorting("ImpactDuration");

                        setOrder &&
                          setOrder(
                            order === "" ? "Asc" : order === "Asc" ? "Desc" : ""
                          );
                      }}
                    >
                      Duration of Impact (years)
                      <Icon icon="triangles" size="15" />
                    </button>
                  </th>
                  <th>Duration Curve</th>
                  <th>Financial Impact (%)</th>
                  <th>Source</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.length ? (
                  data?.map((item) => {
                    return (
                      <TableRow
                        key={item?.id}
                        item={item}
                        setRowData={setRowData}
                        updateHandler={updateHandler}
                        deleteHandler={deleteHandler}
                        handleRowSubmit={handleRowSubmit}
                        setAddNew={setAddNew}
                        setData={setData}
                        data={data}
                        edited={edited}
                        filterData={filterData}
                        isAnyRowEditing={isAnyRowEditing}
                        onEditModeChange={handleEditModeChange}
                      />
                    );
                  })
                ) : (
                  <tr>
                    <div className="no-data">No Data Found</div>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
      {/* ) : null} */}
    </TableWrapper>
  );
};

export default memo(Table);
