/* eslint-disable @typescript-eslint/no-unused-vars */
import PptxGenJS from "pptxgenjs";
import { CoverSlide } from "./Slides/CoverSlide";
import { StrategicImperativeTableSlide } from "./Slides/StrategicImperativeTableSlide";
import { TransformationSlide } from "./Slides/TransformationSlide";
import { BestPracticesSlide } from "./Slides/BestPracticesSlide";
import { SI8Slide } from "./Slides/SI8Slide";
import { C2ASlide } from "./Slides/C2ASlide";
import { DiagramSlide } from "./Slides/DiagramSlide";
import { ChartSlide } from "./Slides/ChartSlide";
import { TOC1Slide } from "./Slides/TOC1Slide";
import { LegalDisclaimerSlide } from "./Slides/LegalDisclaimerSlide";
import { NextStepCoverSlide } from "./Slides/NextStepCoverSlide";
import { AnalyticsMethodologySlide } from "./Slides/AnalyticsMethodologySlide";
import { BenefitsAndImpactSlide } from "./Slides/BenefitsAndImpactsSlide";
import { BenchMarkSlide } from "./Slides/BenchMarkSlide";
import { AxisSlide } from "./Slides/AxisSlide";
import { TOC2Slide } from "./Slides/TOC2Slide";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { PrimaryButton } from "../../components/button/button";
import { textCapitalize } from "../../utils/textCapitalize";
import { useEffect, useState } from "react";

export default function PPT() {
  const [pptDownloadLoading, setPPTDownloadLoading] = useState(false);
  const pdfExportSlice = useSelector(
    (state: RootState) => state.pdfExportSlice
  );
  const createPpt = () => {
    const pptx = new PptxGenJS();

    // Set custom A4 layout size (29.7 cm x 21 cm)
    pptx.defineLayout({
      name: "A4",
      width: 8.27, // 21 cm in inches
      height: 11.69, // 29.7 cm in inches
    });
    pptx.layout = "A4";

    // Call each slide component function and pass the pptx instance
    CoverSlide(pptx, pdfExportSlice);
    TOC1Slide(pptx, 1, pdfExportSlice);
    TOC2Slide(pptx, 2, pdfExportSlice);
    DiagramSlide(pptx, 3, pdfExportSlice);
    SI8Slide(pptx, 4, pdfExportSlice);
    StrategicImperativeTableSlide(pptx, 5, pdfExportSlice);
    ChartSlide(pptx, 6, pdfExportSlice);
    pdfExportSlice?.transformationData?.forEach((item: any, index: number) => {
      TransformationSlide(pptx, pdfExportSlice, item, index + 7);
    });
    LegalDisclaimerSlide(pptx, 17, pdfExportSlice);
    NextStepCoverSlide(pptx, 18, pdfExportSlice);
    AnalyticsMethodologySlide(pptx, 19, pdfExportSlice);
    BenefitsAndImpactSlide(pptx, 20, pdfExportSlice);
    BenchMarkSlide(pptx, 21, pdfExportSlice);
    AxisSlide(pptx, 22, pdfExportSlice);
    BestPracticesSlide(pptx, 23, pdfExportSlice);
    C2ASlide(pptx, 24, pdfExportSlice);

    // Generate the PPT as a Blob
    // return pptx.write("blob").then((blob) => {
    //   setPptBlob(blob);
    //   return blob;
    // });

    // Export PPT
    const fileName = `Top-10-Transformation-Model-${textCapitalize(
      pdfExportSlice?.title?.sector
    )}`;
    pptx.writeFile({ fileName });
    setPPTDownloadLoading(false);
  };

  useEffect(() => {
    if (pptDownloadLoading) {
      createPpt();
    }
  }, [pptDownloadLoading]);

  return (
    <PrimaryButton
      text="Download PPTX"
      onClick={() => {
        setPPTDownloadLoading(true);
        // createPpt();
      }}
      disabled={pptDownloadLoading}
    />
  );
}
