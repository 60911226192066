import PptxGenJS from "pptxgenjs";
import { SlideHeader } from "../Components/SlideHeader";
import {
  headerHeight,
  secondaryColor,
  sideMargin,
  slideWidth,
  tertiaryColor,
} from "../Components/SlideConstants";
import { SlideFooter } from "../Components/SlideFooter";
import { SlideSource } from "../Components/SlideSource";
import selectionProcessImage from "../../V1/image/selectionProcessImage.png";
import { SlideBlueBorderText } from "../Components/SlideBlueBorderText";
import { getInchesFromPixels } from "../Components/getInchesfromPixels";

export const BenchMarkSlide = (
  pptx: PptxGenJS,
  pageNo?: number,
  data?: any
) => {
  const slide = pptx.addSlide();
  SlideHeader(slide, data);

  slide.addText("Next Steps: Competitive Benchmarking with the Frost Radar", {
    x: sideMargin,
    y: headerHeight + 1.4,
    color: tertiaryColor,
    fontSize: 16,
    h: getInchesFromPixels(16),
    bold: false,
  });

  slide.addText(
    "Frost & Sullivan’s global team of analysts and consultants continuously research a wide range of industries across the globe. The primary and secondary analysis spans the entire value chain, identifying organizations that consistently develop new growth strategies based on a visionary understanding of the future and a proven ability to effectively address emerging challenges and opportunities. The research and analysis is the foundation of the Frost Radar, a robust analytical tool that benchmarks companies’ innovation focus and growth performance in a given market. The dynamic solution offers frequent rating and positioning of companies that are agitating, contending or revolutionizing the industry.",
    {
      x: sideMargin,
      y: headerHeight + 2,
      w: slideWidth - sideMargin * 2,
      h: "10%",
      color: "000000",
      fontSize: 12,
      bold: false,
    }
  );

  SlideBlueBorderText(slide, 5.5, "Our Selection Process:");

  slide.addImage({
    path: selectionProcessImage,
    x: sideMargin,
    y: 6,
    w: 7.6,

    h: 4,
  });
  SlideSource(slide, 10.5);

  SlideFooter(slide, pageNo);
};
