import React, { ReactNode } from "react";
import { Btn, PrimaryButtonStyle } from "./botton.styled";

interface ButtonType {
  variant?: "primary" | "secondary" | "secondary-outline" | string;
  title?: string | ReactNode;
  icon?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  btnClassName?: string;
  disabled?: boolean;
  id?: string;
  type?: "button" | "submit" | "reset";
  size?: "sm" | "md" | "lg";
  fontSize?: string;
}

const Button = ({
  variant,
  title,
  onClick,
  btnClassName,
  disabled,
  id,
  icon,
  type,
  fontSize = "16",
  size = "md",
  ...rest
}: ButtonType) => {
  return (
    <Btn
      className={btnClassName}
      id={id}
      size={size}
      type={type}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      <span
        className="d-flex align-items-center justify-content-center"
        style={{ fontSize: `${fontSize}px` }}
      >
        {title}
        {icon && <i className="material-icons ms-1 icon">{icon}</i>}
      </span>
    </Btn>
  );
};

export default Button;

interface PrimaryButtonType {
  text: string | any;
  onClick: () => void;
  id?: any;
  disabled?: boolean;
}
export const PrimaryButton = (props: PrimaryButtonType) => {
  const { text, ...rest } = props;
  return <PrimaryButtonStyle {...rest}> {text}</PrimaryButtonStyle>;
};
