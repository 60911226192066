import React, { useContext, useEffect, useState } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useParams,
} from "react-router-dom";
import NavHeader from "../components/NavHeader";
import { FilterContext } from "../context/FilterProvider";
import useFrostSi8Api from "../hooks/useFrostSi8Api";
import useGetTopTenDataForVisualization from "../hooks/get/useGetTopTenDataForVisualization";
import { PrimaryButton } from "../components/button/button";
import { AiGenerateFilterInitial } from "../utils/initialValue";
import TransformationGeneration from "../pages/Transformation/TransformationGeneration";
import Icon from "../components/icons";
import styled from "styled-components";
import GlobalPoolingStatus from "../components/GlobalPoolingStatus/GlobalPoolingStatus";

export default function TransformationPagesLayout() {
  const context: any = useContext(FilterContext);
  const [id, setId] = useState<string>("");
  const location = useLocation();

  const updateId = (ID: string) => {
    // id and updateId is used to persist the si8DataRequestId between routes and passed through outlet as props
    setId(ID);
  };
  // console.log(context);
  const {
    setToggle,

    data,

    setData,
    setSi8DataResponse,

    filterData,
  } = context;
  const { si8DataRequestId } = useParams();

  const { handleRequest } = useFrostSi8Api();
  const { topTenData } = useGetTopTenDataForVisualization(
    handleRequest,
    data?.length > 0 ? filterData?.si8DataRequestId : ""
  );

  useEffect(() => {
    if (location.pathname === "/transformation") {
      setToggle(1);
      setData(undefined);
      setSi8DataResponse(undefined);
    }
  }, [location.pathname, setToggle]);
  return (
    <div>
      <StyledTabs>
        <GlobalPoolingStatus />
        <NavLink
          className={() => {
            return location.pathname === "/transformation" ? "active" : "";
          }}
          to="/transformation"
        >
          <Icon size="17" icon="home" /> History
        </NavLink>
        <NavLink
          style={() => {
            return !id ? { pointerEvents: "none", opacity: 0.5 } : {};
          }}
          to={`/transformation/strategic-imperative/${id}`}
        >
          <Icon size="17" icon="table2" /> Table
        </NavLink>
        <NavLink
          style={() => {
            return !id ? { pointerEvents: "none", opacity: 0.5 } : {};
          }}
          to={`/transformation/visualization/${id}`}
        >
          <Icon size="17" icon="stats-dots" />
          Visualization
        </NavLink>
        <NavLink
          style={() => {
            return !id ? { pointerEvents: "none", opacity: 0.5 } : {};
          }}
          to={`/transformation/sub-segment/${id}`}
        >
          <Icon size="17" icon="delicious" />
          Sub-segment
        </NavLink>
        <NavLink to="/transformation/glossary">
          {" "}
          <Icon size="17" icon="question" />
          Glossary
        </NavLink>
      </StyledTabs>
      <TransformationGeneration />
      <Outlet context={{ id, updateId }} />
    </div>
  );
}

export type TransformationPageContextType = {
  id: string;
  updateId: (string) => void;
};

const StyledTabs = styled.div`
  /* background-color: red; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* gap: 20px; */
  margin-bottom: 20px;
  a {
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    padding: 10px 16px;
    font-size: 16px;
    line-height: 16px;
    color: #555;
    border-bottom: 2px solid transparent;
    &:hover {
      color: #172d55 !important;
    }
    &.active {
      /* color: green; */
      /* background-color: #172d55; */
      color: #172d55 !important;
      border-bottom: 2px solid #172d55;
    }
  }
`;
