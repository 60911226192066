import styled from "styled-components";
import { colors } from "../../constants/themeConstants";

export const PaginationContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  padding-top: 44px;
  padding-top: 20px;
  margin-bottom: 30px;

  .pagging {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 19px;
  }
  .curr-item,
  .total-item {
    background: #eef2f5;
    border-radius: 5px;
    color: #414141;
    display: block;
    height: 35px;
    line-height: 35px;
    padding: 0 3px;
    text-align: center;
    width: 35px;
  }
  .btn-prev,
  .btn-next {
    background: none;
    border: 2px solid #bdc1ca;
    border-radius: 50%;
    font-weight: 700;
    height: 22px;
    line-height: 20px;
    padding: 0;
    width: 22px;

    &:hover {
      background: ${colors.primary};
      border: none;
      .btn-hover {
        color: white;
      }
    }
  }
`;
