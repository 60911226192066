import { useMutation } from "react-query";
import { toast } from "react-toastify";

import { useDispatch } from "react-redux";
import {
  FResponseDto_1OfOfFSi8_Dtos_TopRankRequests_Si8TopRankRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
  Si8TopRankRequestService,
} from "../../services/frost-si8-services";
import {
  setSi8TopRankRequestId,
  setTopTenViewDetailsStatus,
} from "../../store/slices/TopTenViewDetailsSlice";
import useLocalStorage from "../customHooks/useLocalStorage";
import { setVisualizationPoolingState } from "../../store/slices/GlobalPoolingStatusSlice";
const usePostTopTenDetails = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): any => {
  const dispatch = useDispatch();
  return useMutation(
    async (
      si8DataRequestId?: FResponseDto_1OfOfFSi8_Dtos_TopRankRequests_Si8TopRankRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
    ) => {
      {
        return await handleRequest(
          Si8TopRankRequestService.postCoreApiApiAppSi8TopRankRequestTopRankRequest(
            si8DataRequestId as string
          )
        );
      }
    },
    {
      onSuccess: async (response) => {
        if (response != null && response?.data != null) {
          if (response?.success) {
            dispatch(
              setSi8TopRankRequestId(
                response?.data?.si8TopRankRequestId as string
              )
            );
            dispatch(setTopTenViewDetailsStatus(true));
            dispatch(
              setVisualizationPoolingState({
                isPooling: true,
                si8RequestId: response?.data?.si8TopRankRequestId as string,
              })
            );
          } else {
            toast.error(response?.message);
          }
        }
      },
      onError: async (error) => {
        console.error("Mutation error:", error);
      },
    }
  );
};

export default usePostTopTenDetails;
