import { Text } from "@react-pdf/renderer";

interface TitleProps {
  text: string;
}

export default function BlueLineTitle(props: TitleProps) {
  const { text } = props;
  return (
    <Text
      style={{
        fontSize: 16,
        marginTop: 10,
        marginBottom: 5,
        color: "#00B0F0",
        borderBottom: "1px solid #00B0F0",
      }}
    >
      {text}
    </Text>
  );
}
