import PptxGenJS from "pptxgenjs";
import { slideHeight, slideWidth } from "./SlideConstants";

export const SlidePageNum = (slide: PptxGenJS.Slide, pageNo?: number) => {
  slide.addShape("roundRect", {
    x: slideWidth - 0.7,
    y: slideHeight - 0.85,
    w: 0.4,
    h: 0.4,
    fill: { color: "00BFFF" },
    line: { color: "FFFFFF", width: 1 },
    rectRadius: 0.1,
  });

  // Adding text inside the shape
  slide.addText(`${pageNo}`, {
    x: slideWidth - 0.7,
    y: slideHeight - 0.85,
    w: 0.4,
    h: 0.4,
    align: "center",
    valign: "middle",
    fontSize: 10,
    color: "FFFFFF",
  });
};
