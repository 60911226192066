import PptxGenJS from "pptxgenjs";
import { slideHeight, slideWidth } from "../Components/SlideConstants";
import stripImage from "../../V1/image/strip.png";
import { SlideHeader } from "../Components/SlideHeader";
import { SlideFooter } from "../Components/SlideFooter";
import { getInchesFromPixels } from "../Components/getInchesfromPixels";

export const NextStepCoverSlide = (
  pptx: PptxGenJS,
  pageNo: number,
  data?: any
) => {
  const slide = pptx.addSlide();

  slide.background = { color: "ffffff" };
  SlideHeader(slide, data);
  slide.addImage({
    path: stripImage,
    x: 0,
    y: slideHeight - 7,
    w: slideWidth - 0.05, // Width of the image
    h: 2.8, // Height of the image
  });

  slide.addText("Frost & Sullivan Analytics Methodology & Next Steps", {
    x: 0.3,
    y: slideHeight - 5.4,
    w: slideWidth - 2,
    fontSize: 18,
    h: getInchesFromPixels(18),
    color: "ffffff",
    align: "left",
    bold: false,
  });
  SlideFooter(slide, pageNo);
};
