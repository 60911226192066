import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../pages/auth/authSlice";
import notifyReducer from "../pages/auth/notify";
import routeReducer from "../pages/configuration/routeSlice";
import SubSegmentState from "./slices/subSegmentSlice";
import GrowthOpportunitiesReducer from "./slices/GrowthSlice";
import TopTenViewDetailsSlice from "./slices/TopTenViewDetailsSlice";
import PdfExportSlice from "./slices/PdfExportSlice";
import GlobalPoolingStatusSlice from "./slices/GlobalPoolingStatusSlice";
import SI8PoolingStatusSlice from "./slices/SI8PoolingStatusSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    route: routeReducer,
    notify: notifyReducer,
    subSegment: SubSegmentState,
    growthOpportunitiesSlice: GrowthOpportunitiesReducer,
    topTenViewDetailsSlice: TopTenViewDetailsSlice,
    pdfExportSlice: PdfExportSlice,
    GlobalPoolingStatusSlice: GlobalPoolingStatusSlice,
    si8PoolingStatusSlice: SI8PoolingStatusSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
