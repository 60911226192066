import React, { useState } from "react";
import { StyledGlossary } from "./glossary.styled";
import sheet1 from "../../../assets/images/glossary/sheet1-571349591d688608a583.png";
import sheet2 from "../../../assets/images/glossary/sheet2-6d8f5a1cdfb2ad5ceb46.png";
import sheet3 from "../../../assets/images/glossary/sheet3-db94d3186acc134cf9fe.png";
import sheet4 from "../../../assets/images/glossary/sheet4-8d76e688f0dfd2c6b42b.png";
import sheet5 from "../../../assets/images/glossary/sheet5-3af8e5e87baedccc8a9b.png";
import sheet6 from "../../../assets/images/glossary/sheet6-96803529bc6c2c88b97d.png";
import sheet7 from "../../../assets/images/glossary/sheet7-f45864d8ae10b8a2ad3a.png";
import sheet8 from "../../../assets/images/glossary/sheet8-b1ebb5db5d62da1c2c7e.png";
import sheet9 from "../../../assets/images/glossary/sheet9-fcc4f35c71bf3b92e796.png";
import sheet10 from "../../../assets/images/glossary/sheet10-6f5fa32ed1479d4c0857.png";
import sheet11 from "../../../assets/images/glossary/sheet12-6817fe6901a1cb411115.png";

import sheet13 from "../../../assets/images/glossary/sheet13-8cd18f88e17132a8ac48.png";
import sheet14 from "../../../assets/images/glossary/sheet14-f8c7a5720c96aa089c25.png";
import sheet15 from "../../../assets/images/glossary/sheet15-beb28801af4b9164ee3a.png";
import sheet16 from "../../../assets/images/glossary/sheet16-5443d48eb93c25ba9817.png";
import sheet17 from "../../../assets/images/glossary/sheet17-782b0fe6d482b183131e.png";
import sheet18 from "../../../assets/images/glossary/sheet18-09928cca5090549e98ac.png";
import sheet19 from "../../../assets/images/glossary/sheet19-d87cee03f442e8713f42.png";
import sheet20 from "../../../assets/images/glossary/sheet20-a26275a7c28f902635e1.png";

import glo1 from "../../../assets/images/glossary/glo1.png";
import glo2 from "../../../assets/images/glossary/glo2.png";
import glo3 from "../../../assets/images/glossary/glo3.png";

import curve1 from "../../../assets/images/glossary/curve1.png";
import curve2 from "../../../assets/images/glossary/curve2.png";
import curve3 from "../../../assets/images/glossary/curve3.png";
import curve4 from "../../../assets/images/glossary/curve4.png";
import curve5 from "../../../assets/images/glossary/curve5.png";

const Glossary = () => {
  const [isImactScore, setIsImpactScore] = useState<boolean>(true);
  return (
    <StyledGlossary className="img-wrapper-tab">
      <div className="glossary__wrapper">
        <div className="gw-header">
          <div className="gw-header--top">
            Strategic Imperative 8<sup>TM</sup>
          </div>
          <div className="gw-header--bottom">
            A trademark of Frost & Sullivan
          </div>
        </div>
        <div className="gw-header-content">
          <div className="left">
            {" "}
            <p>
              To identify, categorize, and prioritize key trends and
              developments that have the potential to impact an industry or
              sector over a defined period. This tool aids in strategic planning
              and decision-making.
            </p>
            <p>
              The table serves as a comprehensive guide for businesses to
              navigate their external environment, taking into account the
              dynamic nature of trends, their potential impact, and the timing
              of such influences.
            </p>
          </div>
          <div className="right">
            <div className="r-header">
              <h1>Frost & Sullivan</h1>
              <p>CEO Impact based on Strategic Imperative 8</p>
            </div>
            <table className="table-container">
              <tr className="highlight-row">
                <th colSpan={2}>Basis of GPT output:</th>
              </tr>
              <tr>
                <td className="icon-text">
                  <img src={glo1} alt="icon1" />
                  <span>GPT's Information repository</span>
                </td>
              </tr>
              <tr>
                <td className="icon-text">
                  <img src={glo2} alt="icon2" />
                  <span>Quantitative analysis of qualitative information</span>
                </td>
              </tr>
              <tr>
                <td className="icon-text">
                  <img src={glo3} alt="icon3" />
                  <span>Prompts</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="gw-table">
          <div className="gwt-head">
            Strategic Imperative Impact Score (SI Score):
          </div>
          <div className="gwt-row">
            This reflects the overarching significance or potential impact of a
            strategic trend or theme in the industry.
            <br /> Given that it represents broader themes or macro trends, its
            calculation might take into account more generalized factors like
            overall industry trends, broader economic indicators, or more
            holistic expert opinions.
          </div>
        </div>
        <div className="gw-table">
          <div className="gwt-head">
            Impact Score (For Strategic Imperative):
          </div>
          <div className="gwt-row">
            This reflects the significance or potential impact of specific
            developments, events, or items that fall under a Strategic
            Imperative. Given its specificity, its calculation is more granular
            and may account for factors like the nature of the curve, historical
            impacts, and specific expert opinions about the event or development
            in question.
          </div>
          <div className="gwt-row">
            Both scores offer different perspectives, the SI Impact Score offers
            a bird's-eye view of macro-trends, while the specific Impact Score
            delves into the nuances of particular events or developments under
            those trends. The mathematical logic or formulation for the
            Strategic Imperative (SI) Impact Score and the Impact Score for
            Developments/Events/Items is presented differently. This is because
            they represent two distinct concepts.
          </div>
          <div className="gwt-row">
            <b>
              {" "}
              <u>Impact score vs. financial impact</u>:{" "}
            </b>
            <br />
            While the <b>impact score</b> assesses the magnitude of the
            Strategic Imperative from a broader lens -- taking into account
            historical, competitive, industry trends, etc.,{" "}
            <b>financial impact </b>
            refers to perceived monetary implications. The use of both kinds of
            impacts is to cater to a diverse set of clients depending on their
            priorities.
            <br />
            <br /> Moreover, financial impact should also be considered a subset
            of the overall Impact Score.
          </div>
        </div>
        <div className="gw-toogle-btn-wrapper">
          <button
            type="button"
            className={isImactScore ? "active" : ""}
            onClick={() => setIsImpactScore(true)}
          >
            Impact Score
          </button>
          <button
            type="button"
            className={!isImactScore ? "active" : ""}
            onClick={() => setIsImpactScore(false)}
          >
            Strategic Imperative Impact Score
          </button>
        </div>
        {isImactScore ? <ImpactScore /> : <SIScore />}
        <CommonComponentOfScores />
      </div>

      <div className="img-wrapper-table">
        {/* <div className="img-row">
          <div className="first-img">
            <img src={sheet1} alt="Image Description" />
          </div>
          <div className="img-col">
            <img src={sheet2} alt="Image Description" />
          </div>
        </div> */}
        {/* <div className="img-row">
          <div className="img-col img-col-full">
            <img src={sheet3} alt="Image Description" />
          </div>
        </div> */}
        {/* <div className="img-row">
          <div className="img-col img-col-full">
            <img src={sheet4} alt="Image Description" />
          </div>
        </div> */}
        {/* <div className="img-row">
          <div className="img-col img-col-full">
            <img src={sheet5} alt="Image Description" />
          </div>
        </div> */}
        {/* <div className="img-row">
          <div className="img-col img-col-full">
            <img src={sheet6} alt="Image Description" />
          </div>
        </div> */}
        {/* <div className="img-row">
          <div className="img-col img-col-full">
            <img src={sheet7} alt="Image Description" />
          </div>
        </div> */}
        {/* 
        <div className="img-row">
          <div className="img-col img-col-full">
            <img src={sheet8} alt="Image Description" />
          </div>
        </div> */}
        {/* <div className="img-row">
          <div className="img-col img-col-full">
            <img src={sheet9} alt="Image Description" />
          </div>
        </div> */}
        {/* 
        <div className="img-row">
          <div className="img-col img-col-full">
            <img src={sheet10} alt="Image Description" />
          </div>
        </div> */}

        {/* <div className="img-row">
          <div className="img-col img-col-full">
            <img src={sheet19} alt="Image Description" />
          </div>
        </div> */}
        {/* <div className="img-row">
          <div className="img-col img-col-full">
            <img src={sheet20} alt="Image Description" />
          </div>
        </div> */}
      </div>
    </StyledGlossary>
  );
};

export default Glossary;

const ImpactScore = () => {
  return (
    <>
      <div className="gw-header">
        <div className="gw-header--bottom gw-header--secondary">
          Impact Score (For Events/Developments/Items):
        </div>
      </div>
      <div className="gw-step-table">
        <div className="gws-top">
          Steps to calculate the Event Impact Score with example:
        </div>
        <div className="gws-two">
          <div className="gws-one">
            <u>
              The impact score (In) for each transformation is calculated using
              three factors:
            </u>
            <br />
            <ol>
              <li>Base Score (B) </li>
              <li> Duration Factor (Dn) </li>
              <li>Curve Factor (Cn) </li>
            </ol>
            <b> In = B x Dn x Cn</b>
          </div>
          <div className="gws-one gws-one--yellow">
            We consider 10 different events and calculate the Impact Score for
            each event. <br />
            <strong>Events:</strong>
            <ul>
              <li>Offshore Drilling</li>
              <li>Green Tech Transition</li>
              <li>Regulatory Changes</li>
              <li>Gas Flaring Reduction</li>
              <li>Pipeline Innovations</li>
              <li>Renewable Integration</li>
              <li>Geopolitical Risks</li>
              <li>Carbon Capture Tech</li>
              <li>Hydrogen Fuel Adoption</li>
              <li>Digital Transformation</li>
            </ul>
            <br />
            Using In, we can measure the Impact of these events.
          </div>
        </div>
      </div>
      <div className="gw-step ">
        <div className="left">1</div>
        <div className="right ">
          <div className="gw-step1">
            <table className="table1">
              <tbody>
                <tr>
                  <td>
                    {" "}
                    <strong>Base Score</strong>
                  </td>
                  <td>
                    {" "}
                    <p>
                      <strong>
                        <span>B</span> = w1×H + w2×E + w3×C + w4×P
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      The Base Impact (B) is a representation of the immediate,
                      inherent significance or importance of an event or
                      development, without considering its temporal evolution or
                      external influences.
                      <br />
                      The essence of any item's importance or effect is
                      encapsulated in its base score (B).
                      <br />
                      This score acknowledges that not all developments ,
                      events, or items have the same inherent significance. "B"
                      can be constant value assigned based on the strategic
                      imperative or event in question. Variables are defined as:
                    </p>

                    <ul>
                      <li>H = Impact based on Historical Data</li>
                      <li>
                        E = Impact determined from experts in the secondary
                        domain
                      </li>
                      <li>C = Impact from Comparative Analysis</li>
                      <li>P = Impact predicted from Predictive Models</li>
                    </ul>
                    <p>
                      w1, w2, w3, and w4 are the weights (Σw = 1) assigned to
                      each factor based on its relevance to a specific event.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>Table for Base Score</div>
            <table className="table2">
              <thead>
                <tr>
                  <th>Event</th>
                  <th>
                    <span>B</span>
                  </th>
                  <th>H</th>
                  <th>w1</th>
                  <th>E</th>
                  <th>w2</th>
                  <th>C</th>
                  <th>w3</th>
                  <th>P</th>
                  <th>w4</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Offshore Drilling</td>
                  <td>4</td>
                  <td>5</td>
                  <td>0.25</td>
                  <td>4</td>
                  <td>0.25</td>
                  <td>3</td>
                  <td>0.30</td>
                  <td>4</td>
                  <td>0.20</td>
                </tr>
                <tr>
                  <td>Green Tech Transition</td>
                  <td>4</td>
                  <td>4</td>
                  <td>0.30</td>
                  <td>5</td>
                  <td>0.20</td>
                  <td>4</td>
                  <td>0.25</td>
                  <td>3</td>
                  <td>0.25</td>
                </tr>
                <tr>
                  <td>Regulatory Changes</td>
                  <td>4</td>
                  <td>6</td>
                  <td>0.20</td>
                  <td>3</td>
                  <td>0.30</td>
                  <td>5</td>
                  <td>0.20</td>
                  <td>2</td>
                  <td>0.30</td>
                </tr>
                <tr>
                  <td>Gas Flaring Reduction</td>
                  <td>4</td>
                  <td>5</td>
                  <td>0.25</td>
                  <td>3</td>
                  <td>0.30</td>
                  <td>4</td>
                  <td>0.20</td>
                  <td>3</td>
                  <td>0.25</td>
                </tr>
                <tr>
                  <td>Pipeline Innovations</td>
                  <td>3</td>
                  <td>4</td>
                  <td>0.30</td>
                  <td>2</td>
                  <td>0.35</td>
                  <td>5</td>
                  <td>0.15</td>
                  <td>4</td>
                  <td>0.20</td>
                </tr>
                <tr>
                  <td>Renewable Integration</td>
                  <td>3</td>
                  <td>3</td>
                  <td>0.35</td>
                  <td>4</td>
                  <td>0.15</td>
                  <td>3</td>
                  <td>0.25</td>
                  <td>2</td>
                  <td>0.25</td>
                </tr>
                <tr>
                  <td>Geopolitical Risks</td>
                  <td>2</td>
                  <td>2</td>
                  <td>0.40</td>
                  <td>3</td>
                  <td>0.10</td>
                  <td>2</td>
                  <td>0.30</td>
                  <td>1</td>
                  <td>0.20</td>
                </tr>
                <tr>
                  <td>Carbon Capture Tech</td>
                  <td>1</td>
                  <td>1</td>
                  <td>0.45</td>
                  <td>2</td>
                  <td>0.05</td>
                  <td>1</td>
                  <td>0.35</td>
                  <td>3</td>
                  <td>0.15</td>
                </tr>
                <tr>
                  <td>Hydrogen Fuel Adoption</td>
                  <td>2</td>
                  <td>3</td>
                  <td>0.25</td>
                  <td>1</td>
                  <td>0.25</td>
                  <td>4</td>
                  <td>0.15</td>
                  <td>2</td>
                  <td>0.35</td>
                </tr>
                <tr>
                  <td>Digital Transformation</td>
                  <td>2</td>
                  <td>4</td>
                  <td>0.20</td>
                  <td>2</td>
                  <td>0.30</td>
                  <td>3</td>
                  <td>0.25</td>
                  <td>1</td>
                  <td>0.25</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="gw-step ">
        <div className="left">2</div>
        <div className="right ">
          <div className="gw-step1">
            <table className="table1">
              <tbody>
                <tr>
                  <td>
                    {" "}
                    <strong>Duration Factor</strong>
                  </td>
                  <td>
                    {" "}
                    <p>
                      For events with an amplified impact: <br />
                      <span> D(t)</span> = D₀ × (1 + r)ᵗ
                      <br />
                      For events with a decaying impact over time: <br />
                      <span> D(t)</span> = D₀ × e⁻ᵏᵗ
                      <br />
                      For events with a fluctuating impact (hype curve): <br />
                      <span> D(t)</span> = D₀ × [1 + a × sin(b x t + c)]
                      <br />
                    </p>
                  </td>
                  <td>
                    <p>
                      This factor depends on the year under consideration within
                      the total impact duration. It adjusts the score according
                      to the nature of the curve (exponential, linear, or hype).
                      Impact may not remain constant over time. For example,
                      certain technological disruptions might have immediate
                      impacts that fade over time. It adjusts the score
                      according to the nature of the curve (exponential, linear,
                      or hype).
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>Table for Duration Factor</div>
            <table className="table2 step2table">
              <thead>
                <tr>
                  <th>Event</th>
                  <th>
                    <span>D(t)</span>
                  </th>
                  <th>D0</th>
                  <th>r</th>
                  <th>t</th>
                  <th>
                    <span>D(t)</span>
                  </th>
                  <th>D0</th>
                  <th>e</th>
                  <th>-k</th>
                  <th>t</th>
                  <th>
                    <span>D(t)</span>
                  </th>
                  <th>D0</th>
                  <th>a</th>
                  <th>b</th>
                  <th>t</th>
                  <th>c</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Offshore Drilling</td>
                  <td>2</td>
                  <td>1.8</td>
                  <td>0.1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1.7</td>
                  <td>2.71828</td>
                  <td>0.2</td>
                  <td>2</td>
                  <td>1</td>
                  <td>0.9</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>3</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>Green Tech Transition</td>
                  <td>3</td>
                  <td>2.2</td>
                  <td>0.15</td>
                  <td>2</td>
                  <td>1</td>
                  <td>1.8</td>
                  <td>2.71828</td>
                  <td>0.3</td>
                  <td>3</td>
                  <td>1</td>
                  <td>1</td>
                  <td>0.15</td>
                  <td>0.2</td>
                  <td>4</td>
                  <td>0.1</td>
                </tr>
                <tr>
                  <td>Regulatory Changes</td>
                  <td>4</td>
                  <td>2.5</td>
                  <td>0.2</td>
                  <td>3</td>
                  <td>0</td>
                  <td>1.9</td>
                  <td>2.71828</td>
                  <td>0.4</td>
                  <td>4</td>
                  <td>1</td>
                  <td>1.2</td>
                  <td>0.2</td>
                  <td>0.3</td>
                  <td>5</td>
                  <td>0.2</td>
                </tr>
                <tr>
                  <td>Gas Flaring Reduction</td>
                  <td>3</td>
                  <td>2</td>
                  <td>0.2</td>
                  <td>2</td>
                  <td>1</td>
                  <td>1.8</td>
                  <td>2.71828</td>
                  <td>0.3</td>
                  <td>3</td>
                  <td>1</td>
                  <td>1.1</td>
                  <td>0.15</td>
                  <td>0.2</td>
                  <td>4</td>
                  <td>0.1</td>
                </tr>
                <tr>
                  <td>Pipeline Innovations</td>
                  <td>3</td>
                  <td>2.1</td>
                  <td>0.15</td>
                  <td>3</td>
                  <td>0</td>
                  <td>1.7</td>
                  <td>2.71828</td>
                  <td>0.4</td>
                  <td>4</td>
                  <td>1</td>
                  <td>1.3</td>
                  <td>0.1</td>
                  <td>0.15</td>
                  <td>3</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>Renewable Integration</td>
                  <td>2</td>
                  <td>1.9</td>
                  <td>0.1</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1.6</td>
                  <td>2.71828</td>
                  <td>0.5</td>
                  <td>5</td>
                  <td>1</td>
                  <td>0.9</td>
                  <td>0.2</td>
                  <td>0.1</td>
                  <td>5</td>
                  <td>0.1</td>
                </tr>
                <tr>
                  <td>Geopolitical Risks</td>
                  <td>2</td>
                  <td>1.7</td>
                  <td>0.15</td>
                  <td>2</td>
                  <td>0</td>
                  <td>1.5</td>
                  <td>2.71828</td>
                  <td>0.6</td>
                  <td>2</td>
                  <td>1</td>
                  <td>0.7</td>
                  <td>0.25</td>
                  <td>0.1</td>
                  <td>2</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>Carbon Capture Tech</td>
                  <td>2</td>
                  <td>1.5</td>
                  <td>0.2</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1.4</td>
                  <td>2.71828</td>
                  <td>0.7</td>
                  <td>3</td>
                  <td>1</td>
                  <td>0.85</td>
                  <td>0.15</td>
                  <td>0.05</td>
                  <td>1</td>
                  <td>0.05</td>
                </tr>
                <tr>
                  <td>Hydrogen Fuel Adoption</td>
                  <td>3</td>
                  <td>1.3</td>
                  <td>0.25</td>
                  <td>3</td>
                  <td>0</td>
                  <td>1.2</td>
                  <td>2.71828</td>
                  <td>0.8</td>
                  <td>2</td>
                  <td>1</td>
                  <td>0.8</td>
                  <td>0.2</td>
                  <td>0.15</td>
                  <td>2</td>
                  <td>0.05</td>
                </tr>
                <tr>
                  <td>Digital Transformation</td>
                  <td>1</td>
                  <td>1.2</td>
                  <td>0.1</td>
                  <td>2</td>
                  <td>0</td>
                  <td>1.1</td>
                  <td>2.71828</td>
                  <td>0.9</td>
                  <td>1</td>
                  <td>1</td>
                  <td>0.6</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>3</td>
                  <td>0.05</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="gw-step ">
        <div className="left">3</div>
        <div className="right ">
          <div className="gw-step1">
            <table className="table1">
              <tbody>
                <tr>
                  <td>
                    {" "}
                    <strong>Curve Factor</strong>
                  </td>
                  <td>
                    {" "}
                    <p>
                      <strong>
                        <span>Cn</span> = 1.35
                      </strong>
                      <br />
                      (represents a 35% increase each year)
                    </p>
                  </td>
                  <td>
                    <p>
                      The nature of impact—whether it's linear, exponential, or
                      follows a hype-gives a structured way to modify the score.
                      This adjusts the base score based on the nature of the
                      curve. <br />
                      Exponential Curve: Represents items that gain increasing
                      significance over time. For example, a new technological
                      innovation might have marginal impacts initially, but its
                      relevance might grow exponentially as adoption increases.
                      <br />
                      Linear Curve: Signifies a steady, unchanging importance
                      over time. Such behaviors might be seen in slowly evolving
                      industries or sectors.
                      <br /> Hype Curve: Captures items that quickly gain
                      importance, peak, and then diminish. This is observed in
                      many technological trends, which gain rapid adoption but
                      are soon replaced by better alternatives.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>Table for Base Score</div>
            <table className="table2">
              <thead>
                <tr>
                  <th>Event</th>
                  <th>
                    <span>Cn(code)</span>
                  </th>
                  <th>Types of Curve[likely to adapt to Frost terminology]</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Offshore Drilling</td>
                  <td>1</td>
                  <td rowSpan={2}>
                    <div className="type-of-curve">
                      <div className="left">
                        <b>Linear Curve:</b>
                        <p>Continous, progressive growth in impact</p>
                      </div>
                      <div className="right">
                        <img src={curve1} alt="Linear curve" />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Green Tech Transition</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Regulatory Changes</td>
                  <td>1</td>
                  <td rowSpan={2}>
                    <div className="type-of-curve">
                      <div className="left">
                        <b>Exponential Curve:</b>
                        <p>Rapid, multi-fold growth in impact</p>
                      </div>
                      <div className="right">
                        {" "}
                        <img src={curve2} alt="Exponential curve" />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Gas Flaring Reduction</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Pipeline Innovations</td>
                  <td>1</td>
                  <td rowSpan={2}>
                    <div className="type-of-curve">
                      <div className="left">
                        <b>Logistic Growth:</b>
                        <p>Initial growth and plateau of the impact</p>
                      </div>
                      <div className="right">
                        {" "}
                        <img src={curve3} alt="Logistic growth" />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Renewable Integration</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Geopolitical Risks</td>
                  <td>1</td>
                  <td rowSpan={2}>
                    <div className="type-of-curve">
                      <div className="left">
                        <b>Oscillatory (sinusoidal):</b>
                        <p>Immediate growth and decline of impact</p>
                      </div>
                      <div className="right">
                        {" "}
                        <img src={curve4} alt="Oscillatory" />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Carbon Capture Tech</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Hydrogen Fuel Adoption</td>
                  <td>1</td>
                  <td rowSpan={2}>
                    <div className="type-of-curve">
                      <div className="left">
                        <b>Polynomial:</b>
                        <p>
                          Slow initial rise, but rapid subsequent escalation
                        </p>
                      </div>
                      <div className="right">
                        {" "}
                        <img src={curve5} alt="Polynomial" />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Digital Transformation</td>
                  <td>1</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="gw-step ">
        <div className="left">4</div>
        <div className="right ">
          <div className="gw-step1">
            <table className="table1">
              <tbody>
                <tr>
                  <td>
                    {" "}
                    <strong>Impact Score</strong>
                  </td>
                  <td>
                    {" "}
                    <p>
                      <strong>
                        <span>In</span> = B x Dn x Cn
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      A rating (out of 10) that provides a measure of the
                      relative influence or importance of the specific
                      development/event/item on the industry or sector. <br />
                      Start of Impact: A projected range of years when the
                      effects of the development/event/item will start to
                      manifest.
                      <br /> Stop of Impact: A projected range of years when the
                      effects of the development/event/item will stop to
                      manifest.
                      <br /> Timing of Impact: The estimated time frame when the
                      development/event/item will begin to show noticeable
                      effects on the industry or sector.
                      <br /> 8.Duration of Impact (years): The number of years
                      over which the effects of the development/event/item will
                      be felt
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>Table for Impact Score</div>
            <table className="table2">
              <thead>
                <tr>
                  <th>Event</th>
                  <th>
                    <span>In</span>
                  </th>
                  <th>B</th>
                  <th>Dn</th>
                  <th>Cn</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Offshore Drilling</td>
                  <td>16</td>
                  <td>7.9</td>
                  <td>1.98</td>
                  <td>1.05</td>
                </tr>
                <tr>
                  <td>Green Tech Transition</td>
                  <td>22</td>
                  <td>7.9</td>
                  <td>2.9095</td>
                  <td>0.95</td>
                </tr>
                <tr>
                  <td>Regulatory Changes</td>
                  <td>32</td>
                  <td>7.4</td>
                  <td>4.32</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Gas Flaring Reduction</td>
                  <td>19</td>
                  <td>7.4</td>
                  <td>2.88</td>
                  <td>0.9</td>
                </tr>
                <tr>
                  <td>Pipeline Innovations</td>
                  <td>21</td>
                  <td>6.9</td>
                  <td>3.19384</td>
                  <td>0.95</td>
                </tr>
                <tr>
                  <td>Renewable Integration</td>
                  <td>12</td>
                  <td>5.8</td>
                  <td>2.09</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Geopolitical Risks</td>
                  <td>8</td>
                  <td>3.8</td>
                  <td>2.24825</td>
                  <td>0.9</td>
                </tr>
                <tr>
                  <td>Carbon Capture Tech</td>
                  <td>5</td>
                  <td>2.7</td>
                  <td>1.8</td>
                  <td>0.95</td>
                </tr>
                <tr>
                  <td>Hydrogen Fuel Adoption</td>
                  <td>10</td>
                  <td>4.6</td>
                  <td>2.53906</td>
                  <td>0.85</td>
                </tr>
                <tr>
                  <td>Digital Transformation</td>
                  <td>6</td>
                  <td>4.8</td>
                  <td>1.452</td>
                  <td>0.8</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="gw-step ">
        <div className="left">5</div>
        <div className="right ">
          <div className="gw-step1">
            <table className="table1">
              <tbody>
                <tr>
                  <td>
                    {" "}
                    <strong>Normalised Impact Score</strong>
                  </td>
                  <td>
                    {" "}
                    <p>
                      <strong>
                        <span>Nn</span>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      Normalizing scores within a specific range, like [1, 10],
                      serves several important purposes:
                      <br />
                      1. The range of 1-10 is intuitive and this facilitates
                      quick assessments and comparisons. <br />
                      2.By normalizing scores, we ensure that all events or
                      items are evaluated on a consistent scale. This is
                      essential for comparing impact across different strategic
                      imperatives, regions, or sectors. <br /> 3. It helps
                      maintain clarity, consistency, and utility across
                      different applications of the model <br />
                      4.Normalization ensures that the output score is confined
                      between a set scale (1-10 in this case) which makes the
                      results more interpretable and comparable across different
                      items and sectors.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>Table for Normalisation</div>
            <table className="table2 step5table">
              <thead>
                <tr>
                  <th>Event</th>
                  <th>In</th>
                  <th>In/Σln</th>
                  <th></th>
                  <th>Impact prioritization by curving to highest value</th>
                  <th>
                    Normalized Impact Score(
                    <span>Nn</span>)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Offshore Drilling</td>
                  <td>16</td>
                  <td>11%</td>
                  <td rowSpan={10}>0.47</td>
                  <td>51%</td>
                  <td>6</td>
                </tr>
                <tr>
                  <td>Green Tech Transition</td>
                  <td>22</td>
                  <td>15%</td>
                  <td>68%</td>
                  <td>7</td>
                </tr>
                <tr>
                  <td>Regulatory Changes</td>
                  <td>32</td>
                  <td>21%</td>
                  <td>100%</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>Gas Flaring Reduction</td>
                  <td>19</td>
                  <td>13%</td>
                  <td>60%</td>
                  <td>6</td>
                </tr>
                <tr>
                  <td>Pipeline Innovations</td>
                  <td>21</td>
                  <td>14%</td>
                  <td>65%</td>
                  <td>7</td>
                </tr>
                <tr>
                  <td>Renewable Integration</td>
                  <td>12</td>
                  <td>8%</td>
                  <td>38%</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>Geopolitical Risks</td>
                  <td>8</td>
                  <td>5%</td>
                  <td>24%</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>Carbon Capture Tech</td>
                  <td>5</td>
                  <td>3%</td>
                  <td>14%</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>Hydrogen Fuel Adoption</td>
                  <td>10</td>
                  <td>7%</td>
                  <td>31%</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>Digital Transformation</td>
                  <td>6</td>
                  <td>4%</td>
                  <td>17%</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>Σln</td>
                  <td>150</td>
                  <td colSpan={4}></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const SIScore = () => {
  return (
    <>
      <div className="gw-header">
        <div className="gw-header--bottom gw-header--secondary">
          Strategic Imperative Impact Score (SI Score)
        </div>
      </div>
      <div className="gw-step-table">
        <div className="gws-top">
          Steps to calculate the SI Impact Score with example:
        </div>
        <div className="gws-two">
          <div className="gws-one">
            <p>
              <b>The Strategic Imperative Impact Score</b> for the nth year.
              It's calculated by weighting and summing the values of various
              parameters (like market dynamics, stakeholder importance, etc.)
              for that year.
              <br />
              This column identifies major trends or forces that influence the
              industry or sector in question.
              <br />
              There are broad categories of factors that businesses need to
              consider when shaping their strategies.
              <br />
              Refers to the specific transformative factors or considerations
              that an organization needs to address.
            </p>
            <p>
              <u>
                SI Impact score (SI) for each 8 strategic imperatives is
                calculated using these 5 factors:
              </u>
              <ul>
                <li>Market dynamics</li>
                <li>Stakeholder importance</li>
                <li>Historical Impact</li>
                <li>Future Projection</li>
                <li>Volatility Factor</li>
              </ul>
            </p>
          </div>
          <div className="gws-one gws-one--yellow">
            The 8 different Strategic Imperatives are:
            <ul>
              <li>Innovative Business Models</li>
              <li>Compression of Value Chains</li>
              <li>Transformative Mega Trends</li>
              <li>Disruptive Technologies</li>
              <li>Internal Challenges</li>
              <li>Competitive Intensity</li>
              <li>Geopolitical Chaos</li>
              <li>Industry Convergence</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="gw-step ">
        <div className="left">1</div>
        <div className="right ">
          <div className="gw-step1">
            <table className="table1">
              <tbody>
                <tr>
                  <td>
                    {" "}
                    <strong>Calculation Step 1</strong>
                  </td>
                  <td>
                    {" "}
                    <p>
                      <strong>
                        M<sub>n</sub> = M<sub>base </sub> x (1+r<sub>m</sub>)
                        <sup>n</sup>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      This is about how the market for a specific strategic
                      imperative evolves over time. This could be influenced by
                      many external factors including technological
                      advancements, regulatory changes, and competition.
                    </p>
                    <p>Where,</p>
                    <ul>
                      <li>
                        M<sub>n</sub>=Market dynamics for the n<sup>th</sup>{" "}
                        year
                      </li>
                      <li>
                        M<sub>base</sub>=Initial market dynamics value
                      </li>
                      <li>
                        r<sub>m</sub>=CAGR for market dynamics
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>Table for Market Dynamics</div>
            <table className="table2">
              <thead>
                <tr>
                  <th>Strategic Imperative</th>
                  <th>
                    M<sub>n</sub>
                  </th>
                  <th>
                    M<sub>base</sub>
                  </th>
                  <th>
                    r<sub>m</sub>
                  </th>
                  <th>n</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Innovative Business Models</td>
                  <td>7.5</td>
                  <td>7</td>
                  <td>7%</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Compression of Value Chain</td>
                  <td>7</td>
                  <td>6.5</td>
                  <td>8%</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Transformative Mega Trend</td>
                  <td>6.8</td>
                  <td>6.5</td>
                  <td>5%</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Disruptive Technologies</td>
                  <td>7.5</td>
                  <td>7.2</td>
                  <td>4%</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Internal Challenges</td>
                  <td>6</td>
                  <td>5.8</td>
                  <td>3.5%</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Competitive Intensity</td>
                  <td>7</td>
                  <td>6.8</td>
                  <td>3%</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Geopolitical Chaos</td>
                  <td>6.2</td>
                  <td>6</td>
                  <td>3.3%</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Industry Convergence</td>
                  <td>7.1</td>
                  <td>7</td>
                  <td>1.4%</td>
                  <td>1</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="gw-step ">
        <div className="left">2</div>
        <div className="right ">
          <div className="gw-step1">
            <table className="table1">
              <tbody>
                <tr>
                  <td>
                    {" "}
                    <strong>Calculation Step 2</strong>
                  </td>
                  <td>
                    {" "}
                    <p>
                      <strong>
                        S<sub>n</sub> = S<sub>base </sub> + d<sub>s</sub> x n
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      Stakeholders' priorities might shift over time based on
                      changing circumstances.
                    </p>
                    <p>Where,</p>
                    <ul>
                      <li>
                        S<sub>n</sub>=Stakeholder importance for the n
                        <sup>th</sup> year
                      </li>
                      <li>
                        S<sub>base</sub>=Stakeholder importance for the base
                        year
                      </li>
                      <li>
                        d<sub>s</sub>=Yearly change (can be positive or
                        negative) in stakeholder importance
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>Table for Stakeholder Importance</div>
            <table className="table2">
              <thead>
                <tr>
                  <th>Strategic Imperative</th>
                  <th>Sn</th>
                  <th>Sbase</th>
                  <th>ds</th>
                  <th>n</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Innovative Business Models</td>
                  <td>8</td>
                  <td>7.5</td>
                  <td>2%</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td>Compression of Value Chain</td>
                  <td>7.8</td>
                  <td>7.2</td>
                  <td>3%</td>
                  <td>20</td>
                </tr>
                <tr>
                  <td>Transformative Mega Trend</td>
                  <td>7.6</td>
                  <td>7.3</td>
                  <td>4%</td>
                  <td>8</td>
                </tr>
                <tr>
                  <td>Disruptive Technologies</td>
                  <td>8.2</td>
                  <td>8</td>
                  <td>2.5%</td>
                  <td>8</td>
                </tr>
                <tr>
                  <td>Internal Challenges</td>
                  <td>7</td>
                  <td>6.9</td>
                  <td>1.5%</td>
                  <td>7</td>
                </tr>
                <tr>
                  <td>Competitive Intensity</td>
                  <td>7.2</td>
                  <td>7</td>
                  <td>3%</td>
                  <td>7</td>
                </tr>
                <tr>
                  <td>Geopolitical Chaos</td>
                  <td>7.1</td>
                  <td>6.9</td>
                  <td>2.9%</td>
                  <td>7</td>
                </tr>
                <tr>
                  <td>Industry Convergence</td>
                  <td>7.5</td>
                  <td>7.3</td>
                  <td>2.8%</td>
                  <td>7</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="gw-step ">
        <div className="left">3</div>
        <div className="right ">
          <div className="gw-step1">
            <table className="table1">
              <tbody>
                <tr>
                  <td>
                    {" "}
                    <strong>Calculation Step 3</strong>
                  </td>
                  <td>
                    {" "}
                    <p>
                      <strong>
                        H<sub>n</sub> = H<sub>base </sub> x e<sup>-k x n</sup>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      Historical impact could be based on past data and might
                      decrease in importance as we move further away from a
                      significant event or trend.
                    </p>
                    <p>Where,</p>
                    <ul>
                      <li>
                        H<sub>n</sub>=Historical impact for nth year
                      </li>
                      <li>
                        H<sub>base</sub>=Initial historical impact
                      </li>
                      <li>e=Base of the natural log</li>
                      <li>k=Decay factors</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>Table for Historical Impact</div>
            <table className="table2">
              <thead>
                <tr>
                  <th>Strategic Imperative</th>
                  <th>
                    H<sub>n</sub>
                  </th>
                  <th>
                    H<sub>base</sub>
                  </th>
                  <th>e</th>
                  <th>k</th>
                  <th>n</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Innovative Business Models</td>
                  <td>7.0</td>
                  <td>6.5</td>
                  <td>2.718</td>
                  <td>1%</td>
                  <td>7</td>
                </tr>
                <tr>
                  <td>Compression of Value Chain</td>
                  <td>6.5</td>
                  <td>6</td>
                  <td>2.718</td>
                  <td>2%</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>Transformative Mega Trend</td>
                  <td>6.3</td>
                  <td>6</td>
                  <td>2.718</td>
                  <td>1.50%</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>Disruptive Technologies</td>
                  <td>7.0</td>
                  <td>6.8</td>
                  <td>2.718</td>
                  <td>1%</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>Internal Challenges</td>
                  <td>6.5</td>
                  <td>6.3</td>
                  <td>2.718</td>
                  <td>2%</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>Competitive Intensity</td>
                  <td>6.8</td>
                  <td>6.6</td>
                  <td>2.718</td>
                  <td>2.50%</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Geopolitical Chaos</td>
                  <td>6.1</td>
                  <td>6</td>
                  <td>2.718</td>
                  <td>1.90%</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Industry Convergence</td>
                  <td>7.0</td>
                  <td>6.9</td>
                  <td>2.718</td>
                  <td>1.20%</td>
                  <td>1</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="gw-step ">
        <div className="left">4</div>
        <div className="right ">
          <div className="gw-step1">
            <table className="table1">
              <tbody>
                <tr>
                  <td>
                    {" "}
                    <strong>Calculation Step 4</strong>
                  </td>
                  <td>
                    {" "}
                    <p>
                      <strong>
                        F<sub>n</sub> = F<sub>base </sub> x (1+r<sub>f</sub>)
                        <sup>n</sup>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      This involves projecting the significance of an imperative
                      in the future, typically based on predictions and models.
                    </p>
                    <p>Where,</p>
                    <ul>
                      <li>
                        F<sub>n</sub>=Future projection for nth year
                      </li>
                      <li>
                        F<sub>base</sub>=Initial Future projection value
                      </li>
                      <li>
                        {" "}
                        r<sub>f</sub>=Growth rate for future projection
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>Table for Future Projection</div>
            <table className="table2">
              <thead>
                <tr>
                  <th>Strategic Imperative</th>
                  <th>
                    F<sub>n</sub>
                  </th>
                  <th>
                    F<sub>base</sub>
                  </th>
                  <th>
                    r<sub>f</sub>
                  </th>
                  <th>n</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Innovative Business Models</td>
                  <td>8.3</td>
                  <td>8</td>
                  <td>4%</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Compression of Value Chain</td>
                  <td>7.9</td>
                  <td>7.5</td>
                  <td>5%</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Transformative Mega Trend</td>
                  <td>8.1</td>
                  <td>7.9</td>
                  <td>3%</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Disruptive Technologies</td>
                  <td>7.5</td>
                  <td>7.3</td>
                  <td>3.50%</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Internal Challenges</td>
                  <td>6.8</td>
                  <td>6.7</td>
                  <td>2%</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Competitive Intensity</td>
                  <td>7.1</td>
                  <td>7</td>
                  <td>1.50%</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Geopolitical Chaos</td>
                  <td>6.5</td>
                  <td>6.3</td>
                  <td>3.30%</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Industry Convergence</td>
                  <td>7.3</td>
                  <td>7.1</td>
                  <td>2.80%</td>
                  <td>1</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="gw-step ">
        <div className="left">5</div>
        <div className="right ">
          <div className="gw-step1">
            <table className="table1">
              <tbody>
                <tr>
                  <td>
                    {" "}
                    <strong>Calculation Step 5</strong>
                  </td>
                  <td>
                    {" "}
                    <p>
                      <strong>
                        V<sub>n</sub> = V<sub>base </sub>+ d<sub>v</sub> x sin(n
                        x &omega;)
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      This factor captures the uncertainty and could be based on
                      variances in historical data or expert predictions about
                      future fluctuations.
                    </p>
                    <p>Where,</p>
                    <ul>
                      <li>
                        V<sub>n</sub>= Volatility factor for nth year
                      </li>
                      <li>
                        V<sub>base</sub>=Initial volatility value
                      </li>
                      <li>
                        {" "}
                        d<sub>v</sub>=Amplitude of volatility fluctuations
                      </li>
                      <li>
                        &omega;=Frequency of the fluctuations (How often do they
                        occur?)
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>Table for Volatility Factor</div>
            <table className="table2">
              <thead>
                <tr>
                  <th>Strategic Imperative</th>
                  <th>
                    V<sub>n</sub>
                  </th>
                  <th>
                    V<sub>base</sub>
                  </th>
                  <th>
                    d<sub>v</sub>
                  </th>
                  <th>n</th>
                  <th>&omega;</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Innovative Business Models</td>
                  <td>6.5</td>
                  <td>6.5</td>
                  <td>3%</td>
                  <td>9</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>Compression of Value Chain</td>
                  <td>6.3</td>
                  <td>6.3</td>
                  <td>2%</td>
                  <td>10</td>
                  <td>2.5</td>
                </tr>
                <tr>
                  <td>Transformative Mega Trend</td>
                  <td>6.3</td>
                  <td>6.3</td>
                  <td>1%</td>
                  <td>9</td>
                  <td>2.2</td>
                </tr>
                <tr>
                  <td>Disruptive Technologies</td>
                  <td>6.2</td>
                  <td>6.2</td>
                  <td>2%</td>
                  <td>3</td>
                  <td>1.8</td>
                </tr>
                <tr>
                  <td>Internal Challenges</td>
                  <td>5.9</td>
                  <td>5.9</td>
                  <td>2.50%</td>
                  <td>2</td>
                  <td>2.4</td>
                </tr>
                <tr>
                  <td>Competitive Intensity</td>
                  <td>5.7</td>
                  <td>5.7</td>
                  <td>2%</td>
                  <td>2</td>
                  <td>2.1</td>
                </tr>
                <tr>
                  <td>Geopolitical Chaos</td>
                  <td>6.1</td>
                  <td>6.1</td>
                  <td>2.10%</td>
                  <td>2</td>
                  <td>2.3</td>
                </tr>
                <tr>
                  <td>Industry Convergence</td>
                  <td>6.9</td>
                  <td>6.9</td>
                  <td>1.70%</td>
                  <td>3</td>
                  <td>1.9</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="gw-step ">
        <div className="left">6</div>
        <div className="right ">
          <div className="gw-step1">
            <table className="table1">
              <tbody>
                <tr>
                  <td>
                    {" "}
                    <strong>Calculation Step 6</strong>
                  </td>
                  <td>
                    {" "}
                    <p>
                      <strong>
                        SI<sub>score(n)</sub> = W<sub>M(n)</sub>xM<sub>n</sub> +
                        W<sub>S(n)</sub> x S<sub>n</sub> + W<sub>H(n) </sub> x H
                        <sub>n</sub> + W<sub>F(n)</sub> x F<sub>n</sub> + W
                        <sub>V(n)</sub> x V<sub>n</sub>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>Where,</p>
                    <ul>
                      <li>
                        SI<sub>score(n)</sub>= Impact Score for each of the 8
                        Strategic Imperatives
                      </li>
                      <li>
                        M<sub>n</sub>=Market dynamics
                      </li>
                      <li>
                        S<sub>n</sub>=Stakeholder importance
                      </li>
                      <li>
                        H<sub>n</sub>=Historical Impact
                      </li>
                      <li>
                        F<sub>n</sub>=Future Projection
                      </li>
                      <li>
                        V<sub>n</sub>=Volatility Factor
                      </li>
                      <li>
                        {" "}
                        W<sub>M(n)</sub> , W<sub>S(n)</sub> , W<sub>H(n) </sub>{" "}
                        , W<sub>F(n)</sub> , W<sub>V(n)</sub> are respective
                        weights for nth year that sum upto 1
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>Table for SI Score</div>
            <table className="table2">
              <thead>
                <tr>
                  <th>Strategic Imperative</th>
                  <th>
                    SI<sub>score(n)</sub>
                  </th>
                  <th>
                    M<sub>n</sub>
                  </th>
                  <th>
                    W<sub>M(n)</sub>
                  </th>
                  <th>
                    S<sub>n</sub>
                  </th>
                  <th>
                    W<sub>S(n)</sub>
                  </th>
                  <th>
                    H<sub>n</sub>
                  </th>
                  <th>
                    W<sub>H(n)</sub>
                  </th>
                  <th>
                    F<sub>n</sub>
                  </th>
                  <th>
                    W<sub>F(n)</sub>
                  </th>
                  <th>
                    V<sub>n</sub>
                  </th>
                  <th>
                    W<sub>V(n)</sub>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Geopolitical Chaos</td>
                  <td>7.6</td>
                  <td>7.5</td>
                  <td>0.18</td>
                  <td>8.0</td>
                  <td>0.32</td>
                  <td>7.0</td>
                  <td>0.22</td>
                  <td>8.3</td>
                  <td>0.16</td>
                  <td>6.5</td>
                  <td>0.12</td>
                </tr>
                <tr>
                  <td>Innovative Business Models</td>
                  <td>7.3</td>
                  <td>7.0</td>
                  <td>0.20</td>
                  <td>7.8</td>
                  <td>0.30</td>
                  <td>6.5</td>
                  <td>0.20</td>
                  <td>7.9</td>
                  <td>0.20</td>
                  <td>6.3</td>
                  <td>0.10</td>
                </tr>
                <tr>
                  <td>Compression of Value Chain</td>
                  <td>7.0</td>
                  <td>6.8</td>
                  <td>0.25</td>
                  <td>7.6</td>
                  <td>0.25</td>
                  <td>6.3</td>
                  <td>0.25</td>
                  <td>8.1</td>
                  <td>0.15</td>
                  <td>6.3</td>
                  <td>0.10</td>
                </tr>
                <tr>
                  <td>Transformative Mega Trend</td>
                  <td>7.4</td>
                  <td>7.5</td>
                  <td>0.15</td>
                  <td>8.2</td>
                  <td>0.30</td>
                  <td>7.0</td>
                  <td>0.30</td>
                  <td>7.5</td>
                  <td>0.15</td>
                  <td>6.2</td>
                  <td>0.10</td>
                </tr>
                <tr>
                  <td>Industry Convergence</td>
                  <td>6.5</td>
                  <td>6.0</td>
                  <td>0.20</td>
                  <td>7.0</td>
                  <td>0.28</td>
                  <td>6.5</td>
                  <td>0.25</td>
                  <td>6.8</td>
                  <td>0.15</td>
                  <td>5.9</td>
                  <td>0.12</td>
                </tr>
                <tr>
                  <td>Internal Challenges</td>
                  <td>5.9</td>
                  <td>7.0</td>
                  <td>0.15</td>
                  <td>7.2</td>
                  <td>0.25</td>
                  <td>6.8</td>
                  <td>0.30</td>
                  <td>7.1</td>
                  <td>0.10</td>
                  <td>5.7</td>
                  <td>0.05</td>
                </tr>
                <tr>
                  <td>Competitive Intensity</td>
                  <td>5.5</td>
                  <td>6.2</td>
                  <td>0.20</td>
                  <td>7.1</td>
                  <td>0.25</td>
                  <td>6.1</td>
                  <td>0.25</td>
                  <td>6.5</td>
                  <td>0.10</td>
                  <td>6.1</td>
                  <td>0.05</td>
                </tr>
                <tr>
                  <td>Disruptive Technologies</td>
                  <td>5.8</td>
                  <td>7.1</td>
                  <td>0.20</td>
                  <td>7.5</td>
                  <td>0.25</td>
                  <td>7.0</td>
                  <td>0.20</td>
                  <td>7.3</td>
                  <td>0.10</td>
                  <td>6.9</td>
                  <td>0.05</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* <div className="img-row">
        <div className="img-col img-col-full">
          <img src={sheet11} alt="Image Description" />
        </div>
      </div> */}
      {/* <div className="img-row">
        <div className="img-col img-col-full">
          <img src={sheet13} alt="Image Description" />
        </div>
      </div> */}
      {/* <div className="img-row">
        <div className="img-col img-col-full">
          <img src={sheet14} alt="Image Description" />
        </div>
      </div> */}

      {/* <div className="img-row">
        <div className="img-col img-col-full">
          <img src={sheet15} alt="Image Description" />
        </div>
      </div> */}
      {/* <div className="img-row">
        <div className="img-col img-col-full">
          <img src={sheet16} alt="Image Description" />
        </div>
      </div> */}
      {/* <div className="img-row">
        <div className="img-col img-col-full">
          <img src={sheet17} alt="Image Description" />
        </div>
      </div> */}

      {/* <div className="img-row">
        <div className="img-col img-col-full">
          <img src={sheet18} alt="Image Description" />
        </div>
      </div> */}
    </>
  );
};

const CommonComponentOfScores = () => {
  return (
    <>
      <div className="gw-comp">
        <div className="gw-left"></div>
        <div className="gw-right">
          <div className="gw-right-top">
            <div>
              Integration between Strategic Imperative Impact score and Event
              Impact Score:
            </div>
          </div>
          <div className="gw-right-content">
            <p>
              The connection between the Strategic Imperative Impact Score and
              the Item Impact Score can be represented as a summative
              relationship if we assume that the strategic imperative's impact
              in a given year n is the aggregation of the impacts of all the
              individual events associated with it.
              <br /> Let's define the following:
              <br />
              <br />
              <ul>
                <li>
                  Let k be the total numb of events under a specific strategic
                  imperative.
                </li>
                <li>
                  Let Ini be the impact score for the ith event in year n.
                </li>
              </ul>
              Now, considering the summative relationship, we can express the SI
              score(n) in terms of the individual Item Impact Scores Ini as:
              <br />
              <div className="formula">
                SI score (n) = Σ K In,&nbsp;&nbsp;&nbsp;&nbsp; k=1,...i
              </div>
              Where:
              <br />
              <ul>
                <li>
                  {" "}
                  SI score(n) is the Strategic Imperative Impact Score for the
                  nth year.
                </li>
                <li>
                  {" "}
                  The right side of the equation represents the summation of the
                  impact scores of all items/events under that strategic
                  imperative for year n.
                </li>
              </ul>
              <u> Understanding:</u> <br />
              <b>
                This formula essentially means that the total impact of a
                strategic imperative in a particular year is the sum of the
                impacts of all the events associated with it. This allows us to
                bridge the connection between the aggregate strategic imperative
                impact and the discrete impacts of individual events.
              </b>
            </p>
          </div>
        </div>
      </div>
      <div className="gw-comp">
        <div className="gw-left"></div>
        <div className="gw-right">
          <div className="gw-right-top">
            <div>
              Relationship between Strategic Imperative Impact Score SI score(n)
              and the Individual Item Impact Scores
            </div>
          </div>
          <div className="gw-right-content">
            <p>
              Given the nature of a strategic imperative, it's not just the sum
              of individual events under it, but rather a reflection of how
              those individual events collectively impact the industry.
              <br /> The weighted influence of each item can provide a more
              nuanced understanding.
              <br />
              GIven:
              <br />
              <ul>
                <li>k total events under a specific strategic imperative.</li>
                <li>Ini is the impact score for the ith event in year n.</li>
                <li>
                  wi is the weight or importance of the ith event in
                  contributing to the overall strategic imperative. The sum of
                  all weights will be equal to 1, i.e. <span>Σ wi= 1</span>.
                </li>
              </ul>
              <br />
              The relationship between the SI score(n) and In; can be
              represented as:
              <br />
              <div className="formula">
                SI score (n) = Σwi x In&nbsp;&nbsp;&nbsp;&nbsp; k=1,...i
              </div>
              Here's how to interpret the equation:
              <br />
              <ol>
                <li>
                  wi x Ini computes the weighted impact of the ith item for the
                  year n.
                </li>
                <li>
                  Σ wi x Ini is the summation across all events, giving a
                  collective score reflecting the impact of the strategic
                  imperative.
                </li>
              </ol>
              <u> Understanding:</u> <br />
              <b>
                This formula provides a more refined representation as it
                factors in the relative importance (weights) of individual
                events. This ensures that events which are more critical to the
                strategic imperative have a larger influence on the overall
                score.
              </b>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
