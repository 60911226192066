import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface VisualizationPoolingStatusState {
  isPooling: boolean;
  si8RequestId: string;
  data?: any;
  success?: boolean;
}

interface GlobalPoolingStatusType {
  visualization: VisualizationPoolingStatusState;
}

const initialState: GlobalPoolingStatusType = {
  visualization: {
    isPooling: false,
    si8RequestId: "",
    data: undefined,
    success: false,
  },
};

const getFromLocal = (key: string) => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

const setToLocal = (key: string, data: any) => {
  if (!data) return null;
  localStorage.setItem(key, JSON.stringify(data || ""));
};

const removeFromLocal = (key: string) => {
  localStorage.removeItem(key);
};

export const localStorageKeys = {
  VISUALIZATION_POOLING_STORAGE: "visualizationPoolingStorage",
};

// eslint-disable-next-line react-refresh/only-export-components
const GlobalPoolingStatusSlice = createSlice({
  name: "globalPoolingStatus",
  initialState,
  reducers: {
    //call this function to sync redux-state and local-storage at first load
    subscribeToLocalStorageForGlobalPooling: (state) => {
      const localVisualizationStorage = getFromLocal(
        localStorageKeys.VISUALIZATION_POOLING_STORAGE
      );
      if (localVisualizationStorage) {
        state.visualization = localVisualizationStorage;
      }
    },

    // set state for visualization pooling
    setVisualizationPoolingState: (
      state,
      action: PayloadAction<VisualizationPoolingStatusState>
    ) => {
      setToLocal(
        localStorageKeys.VISUALIZATION_POOLING_STORAGE,
        action.payload
      );
      state.visualization = action.payload;
    },

    //clear
    clearVisualizationPoolingState: (state) => {
      state.visualization.isPooling = false;
      state.visualization.si8RequestId = "";
      state.visualization.data = undefined;
      state.visualization.success = false;
      removeFromLocal(localStorageKeys.VISUALIZATION_POOLING_STORAGE);
    },

    setIsPooling: (state, action: PayloadAction<boolean>) => {
      state.visualization.isPooling = action.payload;
    },
    setSi8RequestId: (state, action: PayloadAction<string>) => {
      state.visualization.si8RequestId = action.payload;
    },
    setData: (state, action: PayloadAction<any[]>) => {
      //   state.data = action.payload;
    },
    clearPoolingData: (state) => {
      state.visualization.isPooling = false;
      state.visualization.si8RequestId = "";
    },
  },
});

export const {
  subscribeToLocalStorageForGlobalPooling,
  setVisualizationPoolingState,
  clearVisualizationPoolingState,
  setIsPooling,
  setSi8RequestId,
  setData,
  clearPoolingData,
} = GlobalPoolingStatusSlice.actions;

export default GlobalPoolingStatusSlice.reducer;
