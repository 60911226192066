import React from "react";
import { Space, Tooltip } from "antd";
import MaterialIcon from "../../../../components/materialIcon/materialIcon";
import CsTableForToolTip from "./CsTableForToolTip";
import { FSi8_Dtos_Si8Segments_Si8SegmentResultDto } from "../../../../services/frost-si8-services";

interface ActionButtonsProps {
  record: FSi8_Dtos_Si8Segments_Si8SegmentResultDto;
  editable: boolean;
  isNewRow: boolean;
  onSave: (id: string) => void;
  onCancel: () => void;
  onEdit: (record: FSi8_Dtos_Si8Segments_Si8SegmentResultDto) => void;
  onDelete: (id: string) => void;
  saveLoading: boolean;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  record,
  editable,
  isNewRow,
  onSave,
  onCancel,
  onEdit,
  onDelete,
  saveLoading,
}) => (
  <Space size={"small"}>
    {editable ? (
      <>
        <MaterialIcon
          placement={"left"}
          iconName="save"
          tooltipTitle="Save"
          showTooltip={true}
          $iconSize={20}
          color={"#172E55"}
          disabled={saveLoading}
          onClick={() =>
            saveLoading ? "" : onSave(record.subSegmentDataId ?? "")
          }
        />
        <MaterialIcon
          placement={"left"}
          iconName="cancel"
          tooltipTitle="Cancel"
          showTooltip={true}
          $iconSize={20}
          color="#FF4D4F"
          onClick={onCancel}
        />
      </>
    ) : (
      <>
        <MaterialIcon
          placement={"left"}
          iconName="edit"
          tooltipTitle="Edit"
          showTooltip={true}
          $iconSize={20}
          color={"#172E55"}
          onClick={() => onEdit(record)}
        />
        <MaterialIcon
          placement={"left"}
          iconName="delete"
          tooltipTitle="Delete"
          showTooltip={true}
          $iconSize={20}
          color="#F28E36"
          onClick={() => onDelete(record.subSegmentDataId ?? "")}
        />
      </>
    )}
    {(record?.lastModifierId ||
      record?.reasonToCreateOrUpdate ||
      record?.createdByFullName?.trim()) && (
      <Tooltip
        title={<CsTableForToolTip record={record} />}
        placement="bottom"
        trigger={["hover", "focus"]}
        overlayStyle={{ maxWidth: "400px", zIndex: 9999 }}
      >
        <div className="hover-component">
          <MaterialIcon
            placement="left"
            iconName="history"
            showTooltip
            $iconSize={20}
          />
        </div>
      </Tooltip>
    )}
  </Space>
);

export default ActionButtons;
