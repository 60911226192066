import { useQuery, type UseQueryResult } from "react-query";
import {
  Abp_PagedResultDto_1OfOfFSi8_Dtos_SI8DataRequests_CompletedDataRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
  Si8DataRequestService,
} from "../../services/frost-si8-services";

const useGetGenerateHistory = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  searchKeyword?: any,
  skipCount?: number,
  maxResultCount?: number
): UseQueryResult<
  | Abp_PagedResultDto_1OfOfFSi8_Dtos_SI8DataRequests_CompletedDataRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const generatedHistory = useQuery(
    ["getAllPagedAndSortedUsers", searchKeyword, skipCount, maxResultCount],
    async () => {
      return await handleRequest(
        Si8DataRequestService.getCoreApiApiAppSI8DataRequestCompletedDataRequest(
          searchKeyword,
          "",
          "",
          skipCount,
          maxResultCount
        )
      );
    }
  );

  return generatedHistory;
};

export default useGetGenerateHistory;
