import { Text, View } from "@react-pdf/renderer";

interface TableAndDataRowProps {
  left: string;
  right: string;
}

export default function TableAndDataRow({ left, right }: TableAndDataRowProps) {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid #81B3E4",
      }}
    >
      <View
        style={{
          padding: 10,
          width: 150,
          fontWeight: "bold",
          textAlign: "right",
          color: "white",
          backgroundColor: "#1582C5",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          fontSize: 12,
        }}
      >
        <Text>{left}</Text>
      </View>
      <View
        style={{
          flex: 1,
          padding: 10,
          borderLeft: "1px solid #81B3E4",
          fontSize: 9,
        }}
      >
        <Text>{right}</Text>
      </View>
    </View>
  );
}
