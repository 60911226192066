import { StyleSheet, View } from "@react-pdf/renderer";
import PdfPageLayout from "../Layouts/PdfPageLayout";
import { PdfPageProps } from "../types";
import { TOCRow } from "./TOC1";
import TopText from "../components/TopText";
import { textCapitalize } from "../../../utils/textCapitalize";

const styles = StyleSheet.create({
  tocWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    marginTop: 10,
  },
  tocRow: {
    displayL: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textDecoration: "none",
    gap: 10,
    color: "#000",
    fontSize: 11,
  },
  tocText: {
    maxWidth: "75%",
  },
  tocDotContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  tocDot: {
    width: "100%",
    height: 10,
    flex: 1,
    borderBottom: "1px dashed black",
  },
});

export default function TOC2({ size, data }: PdfPageProps) {
  return (
    <PdfPageLayout data={data} size={size}>
      <>
        <View style={styles.tocWrapper}>
          <TOCRow
            text="Frost & Sullivan Analytics Methodology & Next Steps"
            pageNo="18"
            pageId="18"
          />
          <TOCRow
            inner
            text="Frost & Sullivan Analytics Methodology"
            pageNo="19"
            pageId="19"
          />
          <TOCRow
            inner
            text="Next Steps: Benefits & Impacts of Growth Opportunities"
            pageNo="20"
            pageId="20"
          />

          <TOCRow
            inner
            text="Next Steps: The Transformational Growth Partnership"
            pageNo="20"
            pageId="20"
          />
          <TOCRow
            inner
            text="Next Steps: Competitive Benchmarking with the Frost Radar"
            pageNo="21"
            pageId="21"
          />
          <TOCRow
            inner
            text="Frost Radar Metrics: 2 Major Indices, 10 Analytical Ingredients, 1 Platform"
            pageNo="22"
            pageId="22"
          />
          <TOCRow
            inner
            text="Next Steps: Best Practices Implementation with the 10 Growth Processes"
            pageNo="23"
          />
          <TOCRow
            inner
            text="Next Steps: Companies to Action (C2A)"
            pageNo="24"
          />
          <TopText text="Exhibits" />
          <TOCRow
            text="Exhibit 1:The Strategic Imperative 8™: Factors Creating Pressure on Growth"
            pageNo="3"
            pageId="3"
          />
          <TOCRow
            text="Exhibit 2: The Strategic Imperative 8™ Defined"
            pageNo="4"
            pageId="4"
          />

          <TOCRow
            text={`Exhibit 3: Top Transformations Impacting Growth in  ${textCapitalize(
              data?.title.sector
            )}, ${data?.title.region}, ${data?.title.date}`}
            pageNo="5"
            pageId="5"
          />
          <TOCRow
            text={`Exhibit 4: Top Transformations Impacting Growth in  ${textCapitalize(
              data?.title.sector
            )}, ${data?.title.region}, ${data?.title.date}`}
            pageNo="6"
            pageId="6"
          />
        </View>
      </>
    </PdfPageLayout>
  );
}
