/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FResponseDto_1OfOfFSi8_Dtos_Si8Segments_SegmentResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/FResponseDto_1OfOfFSi8_Dtos_Si8Segments_SegmentResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { FResponseDto_1OfOfSystem_String } from '../models/FResponseDto_1OfOfSystem_String';
import type { FResponseDtoWithTotalCount_1OfOfFSi8_Dtos_Si8Segments_PagedSi8SegmentDataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/FResponseDtoWithTotalCount_1OfOfFSi8_Dtos_Si8Segments_PagedSi8SegmentDataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { FSi8_Dtos_Si8Segments_CreateSegmentInputDto } from '../models/FSi8_Dtos_Si8Segments_CreateSegmentInputDto';
import type { FSi8_Dtos_Si8Segments_UpdateSegmentInputDto } from '../models/FSi8_Dtos_Si8Segments_UpdateSegmentInputDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class Si8SegmentRequestService {

    /**
     * @param si8RequestId
     * @returns FResponseDtoWithTotalCount_1OfOfFSi8_Dtos_Si8Segments_PagedSi8SegmentDataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getCoreApiApiAppSi8SegmentRequestSegmentData(
        si8RequestId: string,
    ): CancelablePromise<FResponseDtoWithTotalCount_1OfOfFSi8_Dtos_Si8Segments_PagedSi8SegmentDataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/si8Segment-request/segment-data/{si8RequestId}',
            path: {
                'si8RequestId': si8RequestId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param si8RequestId
     * @returns FResponseDtoWithTotalCount_1OfOfFSi8_Dtos_Si8Segments_PagedSi8SegmentDataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static postCoreApiApiAppSi8SegmentRequestExpireSegmentData(
        si8RequestId: string,
    ): CancelablePromise<FResponseDtoWithTotalCount_1OfOfFSi8_Dtos_Si8Segments_PagedSi8SegmentDataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/si8Segment-request/expire-segment-data/{si8RequestId}',
            path: {
                'si8RequestId': si8RequestId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param segmentRequestId
     * @param requestBody
     * @returns FResponseDto_1OfOfFSi8_Dtos_Si8Segments_SegmentResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static postCoreApiApiAppSi8SegmentRequestSi8SegmentData(
        segmentRequestId: string,
        requestBody?: FSi8_Dtos_Si8Segments_CreateSegmentInputDto,
    ): CancelablePromise<FResponseDto_1OfOfFSi8_Dtos_Si8Segments_SegmentResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/si8Segment-request/si8Segment-data/{segmentRequestId}',
            path: {
                'segmentRequestId': segmentRequestId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param segmentDataId
     * @param requestBody
     * @returns FResponseDto_1OfOfFSi8_Dtos_Si8Segments_SegmentResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static putCoreApiApiAppSi8SegmentRequestSi8SegmentData(
        segmentDataId: string,
        requestBody?: FSi8_Dtos_Si8Segments_UpdateSegmentInputDto,
    ): CancelablePromise<FResponseDto_1OfOfFSi8_Dtos_Si8Segments_SegmentResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/core-api/api/app/si8Segment-request/si8Segment-data/{segmentDataId}',
            path: {
                'segmentDataId': segmentDataId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param segmentDataId
     * @returns FResponseDto_1OfOfSystem_String Success
     * @throws ApiError
     */
    public static deleteCoreApiApiAppSi8SegmentRequestSi8Segment(
        segmentDataId: string,
    ): CancelablePromise<FResponseDto_1OfOfSystem_String> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/core-api/api/app/si8Segment-request/si8Segment/{segmentDataId}',
            path: {
                'segmentDataId': segmentDataId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param si8RequestId
     * @returns FResponseDto_1OfOfSystem_String Success
     * @throws ApiError
     */
    public static postCoreApiApiAppSi8SegmentRequestCancelSegmentRequest(
        si8RequestId: string,
    ): CancelablePromise<FResponseDto_1OfOfSystem_String> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/si8Segment-request/cancel-segment-request/{si8RequestId}',
            path: {
                'si8RequestId': si8RequestId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
