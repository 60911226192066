import styled, { css } from "styled-components";
import { colors } from "../../constants/themeConstants";
import { colorAdjust } from "../../utils/colorAdjust";

interface ButtonType {
  variant?: "primary" | "secondary" | "danger" | string;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  size?: "sm" | "md" | "lg";
  type?: "button" | "submit" | "reset";
}

export const Btn = styled.button<ButtonType>`
  text-align: center;
  display: inline-block;
  vertical-align: top;
  font-weight: 700;
  border: none;
  text-decoration: none;
  font-family: "Nunito Sans";

  border-radius: 0;
  cursor: pointer;
  border-radius: 8px;
  line-height: 1.5;
  border: 1px solid transparent;
  transition: 0.3s ease-in-out all;
  .icon {
    font-size: 18px;
  }
  &:disabled {
    background-color: #eeeeee;
    /* border: 1px solid lightgray !important; */
  }

  //Button Size
  ${(props) => {
    if (props.size === "sm") {
      return css`
        padding: 0.313rem 0.875rem;
        font-size: 0.875rem;
      `;
    } else if (props.size === "md") {
      return css`
        padding: 0.463rem 1.25rem;
        font-size: 1rem;
      `;
    } else if (props.size === "lg") {
      return css`
        padding: 0.5rem 1.5rem;
        font-size: 1.125rem;
      `;
    }
  }}

  //Button Variant
  ${(props) => {
    if (props.variant === "primary") {
      return css`
        background: ${colors.primary};
        color: ${colors.white};
        &:not(:disabled):hover {
          background: ${colorAdjust(colors.primary, -20)};
          color: ${colors.white};
        }
      `;
    } else if (props.variant === "secondary") {
      return css`
        background: ${colors.secondary};
        color: ${colors.white};

        &:not(:disabled):hover {
          background: ${colorAdjust(colors.secondary, -20)};
          color: ${colors.white};
        }
      `;
    } else if (props.variant === "danger") {
      return css`
        background: ${colors.danger};
        color: ${colors.white};

        &:not(:disabled):hover {
          background: ${colorAdjust(colors.danger, -20)};
          color: ${colors.white};
        }
      `;
    } else if (props.variant === "secondary-outline") {
      return css`
        background: transparent;
        color: ${colors.secondary};
        border: 1px solid ${colors.secondary};

        &:not(:disabled):hover {
          background: #17426b;
          color: ${colors.white};
        }
      `;
    }
  }}
`;

export const PrimaryButtonStyle = styled.button`
  all: unset;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")} !important;
  background-color: #172d55;
  font-size: 14px !important;
  line-height: 18.4px !important;
  font-weight: 700;
  color: white;
  padding: 10px 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  white-space: nowrap;
  &:hover {
    background-color: #031941;
  }
`;
