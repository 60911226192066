import PptxGenJS from "pptxgenjs";
import { sideMargin, slideWidth, tertiaryColor } from "./SlideConstants";
import { getInchesFromPixels } from "./getInchesfromPixels";
export const SlideBlueBorderText = (
  slide: PptxGenJS.Slide,
  position: number,
  text: string
) => {
  slide.addText(text ?? "TEXT HERE", {
    x: sideMargin,
    y: position - 0.1,
    color: tertiaryColor,
    fontSize: 16,
    h: getInchesFromPixels(16),
    bold: false,
  });
  slide.addShape("rect", {
    x: sideMargin,
    y: position + 0.15,
    w: slideWidth - sideMargin * 2,
    h: 0.025,
    fill: { color: tertiaryColor },
  });
};
