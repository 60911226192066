/* eslint-disable react-refresh/only-export-components */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define keys for local storage to avoid hardcoding
const LOCAL_STORAGE_KEYS = {
  POOLING_STATUS: "poolingStatus",
  si8ID: "si8RequestID",
};

// Define possible pooling statuses
type PoolingStatus = "idle" | "pooling" | "completed";

// Define the state structure
interface TransformationPoolingType {
  poolingStatus: PoolingStatus; // Tracks the pooling state
  si8RequestID: string; // Tracks the request ID
}

// Helper to load initial state from local storage
const loadInitialState = (): TransformationPoolingType => ({
  poolingStatus: (localStorage.getItem(LOCAL_STORAGE_KEYS.POOLING_STATUS) ||
    "idle") as PoolingStatus,
  si8RequestID: localStorage.getItem(LOCAL_STORAGE_KEYS.si8ID) || "",
});

const initialState: TransformationPoolingType = loadInitialState();

const SI8PoolingStatusSlice = createSlice({
  name: "si8PoolingStatusSlice",
  initialState,
  reducers: {
    // Sets the pooling status and syncs it with local storage.

    setPoolingStatus(state, action: PayloadAction<PoolingStatus>) {
      state.poolingStatus = action.payload;
      localStorage.setItem(LOCAL_STORAGE_KEYS.POOLING_STATUS, action.payload);
    },

    // Sets the Growth Opportunities ID and syncs it with local storage.

    setsi8RequestID(state, action: PayloadAction<string>) {
      state.si8RequestID = action.payload;
      localStorage.setItem(LOCAL_STORAGE_KEYS.si8ID, action.payload);
    },

    // Clears all growth opportunities-related data from both state and local storage.

    removeSI8PoolingStatus(state) {
      state.poolingStatus = "idle";
      state.si8RequestID = "";
      localStorage.removeItem(LOCAL_STORAGE_KEYS.POOLING_STATUS);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.si8ID);
    },
  },
});

export const { setPoolingStatus, setsi8RequestID, removeSI8PoolingStatus } =
  SI8PoolingStatusSlice.actions;

export default SI8PoolingStatusSlice.reducer;
