import PptxGenJS from "pptxgenjs";
import { SlideHeader } from "../Components/SlideHeader";
import {
  headerHeight,
  heroSectionHeight,
  secondaryColor,
  sideMargin,
  tertiaryColor,
} from "../Components/SlideConstants";
import { SlideFooter } from "../Components/SlideFooter";
import { SlideSource } from "../Components/SlideSource";
import benefitsImage from "../../V1/image/benefitsImage.png";
import tgpImage from "../../V1/image/tgpImage.png";
import { getInchesFromPixels } from "../Components/getInchesfromPixels";

export const BenefitsAndImpactSlide = (
  pptx: PptxGenJS,
  pageNo?: number,
  data?: any
) => {
  const slide = pptx.addSlide();
  SlideHeader(slide, data);

  slide.addText("Next Steps: Benefits & Impacts of Growth Opportunities", {
    x: sideMargin,
    y: headerHeight + heroSectionHeight + 0.4,
    color: tertiaryColor,
    fontSize: 16,
    h: getInchesFromPixels(16),
    bold: false,
  });

  slide.addImage({
    path: benefitsImage,
    x: sideMargin,
    y: 2.8,
    w: 7.6,

    h: 3,
  });

  slide.addText("Next Steps: The Transformational Growth Partnership", {
    x: sideMargin,
    y: 6.2,
    color: tertiaryColor,
    fontSize: 16,
    h: getInchesFromPixels(16),
    bold: false,
  });

  slide.addImage({
    path: tgpImage,
    x: sideMargin,
    y: 6.7,
    w: 7.6,

    h: 3,
  });
  SlideSource(slide, 10.2);

  SlideFooter(slide, pageNo);
};
