import PptxGenJS from "pptxgenjs";
import { sideMargin, slideHeight, slideWidth } from "./SlideConstants";
import { getInchesFromPixels } from "./getInchesfromPixels";
export const SlideSource = (slide: PptxGenJS.Slide, position?: number) => {
  const yPosition = position ?? slideHeight - 1.2;
  const ySourcePosition = yPosition + 1.2 - 1.1;
  slide.addShape("line", {
    x: sideMargin,
    y: yPosition,
    w: slideWidth - sideMargin * 2,
    h: 0.0,
    line: { color: "000000", width: 1 },
  });
  slide.addText("Source: Frost & Sullivan", {
    x: sideMargin,
    y: ySourcePosition,
    w: slideWidth - sideMargin * 2,
    fontSize: 10,
    h: getInchesFromPixels(12),
    color: "000000",
    align: "right",
  });
};
