import styled from "styled-components";
import { colors } from "../../constants/themeConstants";

export const InputContainer = styled.div`
  position: relative;
  .form-control {
    background: #fff;
    border: 1px solid #dfe3e8;
    border-radius: 6px;
    display: block;
    font-family: 'Nunito Sans';
    height: 40px;
    padding: 9px 55px 9px 20px;
    width: 100%;
  }
  .reset {
    font-size: 18px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: ${colors.danger};
  }
`;
