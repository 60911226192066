import React from "react";
import { Form, Input } from "antd";
import { FSi8_Dtos_Si8Segments_Si8SegmentResultDto } from "../../../../services/frost-si8-services";

interface EditableCellProps {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: string;
  record: FSi8_Dtos_Si8Segments_Si8SegmentResultDto;
  index: number;
  children: React.ReactNode;
}

const SubSegmentEditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const getValidationRules = (dataIndex: string) => {
    const defaultRules = [
      {
        required: true,
        message: `Required!`,
      },
    ];

    switch (dataIndex) {
      case "immediacy":
      case "magnitude":
        return [
          ...defaultRules,
          {
            validator: (rule: any, value: any) => {
              if (value) {
                if (isNaN(value)) {
                  return Promise.reject("Enter a valid number");
                }
                if (value < 0 || value > 20) {
                  return Promise.reject("Value must be between 0 to 20");
                }
              }
              return Promise.resolve();
            },
          },
        ];

      default:
        return [...defaultRules];
    }
  };

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={getValidationRules(dataIndex)}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default SubSegmentEditableCell;
