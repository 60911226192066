import PptxGenJS from "pptxgenjs";
import { SlideHeader } from "../Components/SlideHeader";
import {
  headerHeight,
  heroSectionHeight,
  secondaryColor,
} from "../Components/SlideConstants";
import { SlideFooter } from "../Components/SlideFooter";
import { splitDataIntoSections } from "../../../pages/home/visualization/VisualizationDetailCard";
import { getInchesFromPixels } from "../Components/getInchesfromPixels";

export const TransformationSlide = (
  pptx: PptxGenJS,
  data?: any,
  pageData?: any,
  pn?: number
) => {
  const contentData = splitDataIntoSections(pageData?.topRankSi8Data);
  const companiesToActionData = splitDataIntoSections(
    pageData?.companiesToAction
  );
  const growthOpportunityiesData = splitDataIntoSections(
    pageData?.growthOpportunityies
  );

  const slide = pptx.addSlide();
  SlideHeader(slide, data);

  slide.addText(
    `Transformation ${Number(pn) - 6}: ${pageData?.strategicImperative}`,
    {
      x: 0.4,
      y: headerHeight + heroSectionHeight + 0.4,
      color: secondaryColor,
      fontSize: 16,
      h: getInchesFromPixels(16),
      bold: false,
      w: "90%",
    }
  );
  const columnWidths = [0.2, 7.4];

  const formattedRow = contentData?.map((item: any, index: number) => {
    return [
      { text: "•", options: { align: "left" } },
      {
        text: [
          { text: `${item?.title ?? ""} `, options: { bold: true } },
          {
            text: item?.content,
            options: { bold: false },
          },
        ],
        options: { align: "left" },
      },
    ];
  });

  const rows = [...formattedRow];

  slide.addTable(rows, {
    x: 0.4, // x position
    y: headerHeight + heroSectionHeight + 0.85, // y position
    w: "100%", // width
    h: "10%", // height
    border: { pt: 0, color: "ffffff" }, // Border for the table
    fontSize: 10, // Font size for text in the table
    // fill: ["#FFFFFF"], // Background color for cells
    align: "left", // Center align text in cells
    colW: columnWidths,
  });

  const secondTable = [
    [
      {
        text: "Growth Opportunities",
        options: {
          bold: true,
          fontSize: 13,
          color: "0F4C8A",
          align: "center" as any,
        },
      },
      {
        text: "Companies to Action",
        options: {
          bold: true,
          fontSize: 13,
          color: "0F4C8A",
          align: "center" as any,
        },
      },
    ],
    ...Array.from(
      {
        length: Math.max(
          growthOpportunityiesData.length,
          companiesToActionData.length
        ),
      },
      (_, index) => [
        {
          text: [
            {
              text: `${growthOpportunityiesData[index]?.title ? "• " : ""} ${
                growthOpportunityiesData[index]?.title ?? ""
              } `,
              options: { bold: true },
            },
            {
              text: ` ${growthOpportunityiesData[index]?.content ?? ""}`,
              options: { bold: false },
            },
          ],
          options: { align: "left" as any, fontSize: 10 },
        },
        {
          text: [
            {
              text: `${companiesToActionData[index]?.title ? "• " : ""} ${
                companiesToActionData[index]?.title ?? ""
              } `,
              options: { bold: true },
            },
            {
              text: ` ${companiesToActionData[index]?.content ?? ""}`,
              options: { bold: false },
            },
          ],
          options: { align: "left", fontSize: 10 },
        },
      ]
    ),
  ];

  slide.addTable(secondTable, {
    x: 0.4,
    y: "66%",
    w: "100%",
    colW: [3.7, 3.7],
    border: { pt: 0, color: "FFFFFF" },
  });
  SlideFooter(slide, pn);
};
