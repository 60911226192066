import { FC } from "react";
import moment from "moment";
import styled from "styled-components";
import { isEmpty } from "lodash";
import { FSi8_Dtos_Si8Segments_Si8SegmentResultDto } from "../../../../services/frost-si8-services";

interface CustomTooltipTableProps {
  record: FSi8_Dtos_Si8Segments_Si8SegmentResultDto;
}

const CsTableForToolTip: FC<CustomTooltipTableProps> = ({ record }) => {
  return (
    <Table>
      <tbody>
        {!isEmpty(record?.lastModifiedByFullName?.trim()) && (
          <TableRow>
            <TableCell>Modified By</TableCell>
            <TableCell>{record.lastModifiedByFullName}</TableCell>
          </TableRow>
        )}
        {/* created by */}
        {!isEmpty(record?.createdByFullName?.trim()) && (
          <TableRow>
            <TableCell>Created By</TableCell>
            <TableCell>{record.createdByFullName}</TableCell>
          </TableRow>
        )}
        {record?.prevImmediacy !== undefined &&
          record?.prevImmediacy !== null && (
            <TableRow>
              <TableCell>Previous Immediacy</TableCell>
              <TableCell>{record.prevImmediacy * 2}</TableCell>
            </TableRow>
          )}
        {record?.prevMagnitude !== undefined &&
          record?.prevMagnitude !== null && (
            <TableRow>
              <TableCell>Previous Magnitude</TableCell>
              <TableCell>{record.prevMagnitude * 2}</TableCell>
            </TableRow>
          )}
        {record.lastModificationTime && (
          <TableRow>
            <TableCell>Modified Date</TableCell>
            <TableCell>
              {moment(record.lastModificationTime).format("YYYY-MM-DD")}
            </TableCell>
          </TableRow>
        )}
        {record.creationTime && record?.createdByFullName?.trim() && (
          <TableRow>
            <TableCell>Created Date</TableCell>
            <TableCell>
              {moment(record.creationTime).format("YYYY-MM-DD")}
            </TableCell>
          </TableRow>
        )}
        {record.reasonToCreateOrUpdate && (
          <TableRow>
            <TableCell>Reason for update</TableCell>
            <TableCell>{record.reasonToCreateOrUpdate}</TableCell>
          </TableRow>
        )}
      </tbody>
    </Table>
  );
};

export default CsTableForToolTip;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.tr``;

const TableCell = styled.td`
  padding: 4px;
  border-bottom: 1px solid #ddd;
  font-size: 12px;
`;
