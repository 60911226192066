import React, { useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Editor } from "@tinymce/tinymce-react";
import parse from "html-react-parser";
import moment from "moment";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { colors } from "../../constants/themeConstants";
import { setUpdateFlag } from "../../store/slices/subSegmentSlice";
import { RootState } from "../../store/store";
import { validation } from "../../utils/validation/validations";
import Button from "../button/button";
import MaterialIcon from "../materialIcon/materialIcon";
import chatIcon from "../../assets/images/ic_chat_widget.svg";

import {
  ErrorMessage,
  PopupBox,
  PreviewBox,
  PreviewBox1,
} from "./table.styled";
interface TableRowProps {
  item?: any;
  setRowData?: any;
  updateHandler?: any;
  deleteHandler?: any;
  handleRowSubmit?: any;
  data?: any;
  setData?: any;
  setAddNew?: (value: boolean) => void;
  edited?: any;
  filterData?: any;
  isAnyRowEditing: boolean;
  onEditModeChange: (isEditing: boolean) => void;
}
interface validationObject {
  strategicImperative?: string;
  event?: string;
  impactScore?: string;
  impactStartYear?: string;
  impactDuration?: string;
  durationCurve?: string;
  potentialImpactOnRevenue?: string;
  siDetail?: string;
  edited?: string;
}

const TableRow = ({
  item,
  setRowData,
  updateHandler,
  deleteHandler,
  handleRowSubmit,
  data,
  setData,
  setAddNew,
  edited,
  filterData,
  isAnyRowEditing,
  onEditModeChange,
}: TableRowProps) => {
  const [edit, setEdit] = useState(false);
  const [update, setUpdate] = useState(item);
  const [content, setContent] = useState<any>("");
  const [afterUpdateSaved, setAfterUpdateSet] = useState(item);
  const [isToastOpen, setIsToastOpen] = useState(false);

  const [formData, setFormData] = useState(
    item
      ? item
      : {
          id: "",
          strategicImperative: "",
          event: "",
          impactScore: "",
          impactStartYear: "",
          impactDuration: "",
          durationCurve: "",
          potentialImpactOnRevenue: "",
          siDetail: "",
        }
  );

  const [changed, setChanged] = useState(false);
  const [errors, setErrors] = useState<validationObject>({});

  const generating = useSelector(
    (state: RootState) => state?.subSegment?.generating
  );
  const dispatch = useDispatch();

  const changeHandler = (key, value) => {
    setChanged(true);
    setFormData({ ...formData, [key]: value });
    const updatedUpdate = {
      ...update,
      impactScore: update.impactScore.toString(),
      impactStartYear: update.impactStartYear.toString(),
      impactDuration: update.impactDuration.toString(),
    };
    updatedUpdate[key] = value;
    setUpdate(updatedUpdate);
    setRowData(updatedUpdate);
  };

  const modifyData = () => {
    const replacedData = formData.siDetail
      .replaceAll("<p><strong>", "**")
      .replaceAll("</strong></p>", "**")
      .replaceAll("<ul>", "")
      .replaceAll("</ul>", "")
      .replaceAll("<li>", "--")
      .replaceAll("</li>", "")
      .replaceAll(
        `<span id="_mce_caret" data-mce-bogus="1" data-mce-type="format-caret">`,
        ""
      )
      .replaceAll('<br data-mce-bogus="1">', "")
      .replaceAll(
        '<li style="font-weight: bold;" data-mce-style="font-weight: bold;"><strong>',
        "--"
      )
      .replaceAll("</strong>", "")
      .replaceAll("<strong>", "")
      .replaceAll("<br>", "")
      .replaceAll("<p>", "\n")
      .replaceAll("</p>", "\n")
      .replaceAll("&nbsp;", " ");
    return {
      ...update,
      siDetail: replacedData,
      impactScore: update.impactScore.toString(),
      impactStartYear: update.impactStartYear.toString(),
      impactDuration: update.impactDuration.toString(),
    };
  };

  const updateItem = () => {
    const validationErrors = validation(formData, setErrors);
    if (Object.keys(validationErrors).length === 0) {
      setEdit(!edit);
      if (edit) {
        const a = modifyData();
        setAfterUpdateSet(update);
        setContent(a.siDetail);
        updateHandler(item.id, a);
      }
      setRowData(undefined);
      dispatch(setUpdateFlag(true));
    }
  };

  const parseSiDetail = (siDetail) => {
    if (siDetail.trim().startsWith("<") && siDetail.trim().endsWith(">")) {
      setContent(siDetail);
      return siDetail;
    }

    const sections = siDetail
      ?.split("--")
      .map((section) => section.trim())
      .filter((section) => section !== "");

    const parsedData = sections.map((section, index) => {
      if (index === 0 && !section.startsWith("**") && !section.endsWith("**")) {
        return `<p>${section}</p>`;
      } else if (section.startsWith("**") && section.endsWith("**")) {
        return `<p><strong>${section.slice(2, -2)}</strong></p>`;
      } else {
        if (section?.includes("**")) {
          const title = section
            .split(/\*\*(.*?)\*\*/)
            .filter((blank) => blank.trim() !== "");
          const titleWrap = title
            .slice(1)
            .map((title) => `<p><strong>${title}</strong></p>`);
          return `${titleWrap}`;
        } else {
          return `<li>${section}</li>`;
        }
      }
    });

    const dataWithList = parsedData.filter((item) => {
      return item.includes("<li>");
    });
    const dataWithoutList = parsedData.filter((item) => !item.includes("<li>"));
    const wrapDataWithUl = `<ul>${dataWithList
      ?.map((item) => item)
      .join("")}</ul>`;
    const finalDescription = [...dataWithoutList, wrapDataWithUl].join("");
    setContent(finalDescription);
    return finalDescription;
  };
  useEffect(() => {
    parseSiDetail(formData.siDetail);
  }, [content]);

  useEffect(() => {
    if (changed) validation(formData, setErrors);
  }, [formData]);
  const [latest, setLatest] = useState(item?.new);

  const addItem = () => {
    const validationErrors = validation(formData, setErrors);
    if (Object.keys(validationErrors).length === 0) {
      const a = modifyData();
      handleRowSubmit(a);
      setLatest(false);
      dispatch(setUpdateFlag(true));

      // Make a copy of the array to avoid mutating state directly
      const updatedData = [...data];

      // Access the last object in the array (data.length - 1) and update its values
      const lastObject = updatedData[data.length - 1];
      lastObject.strategicImperative = update?.strategicImperative;
      lastObject.event = update?.event;
      lastObject.impactScore = update?.impactScore;
      lastObject.impactStartYear = update?.impactStartYear;
      lastObject.impactDuration = update?.impactDuration;
      lastObject.durationCurve = update?.durationCurve;
      lastObject.potentialImpactOnRevenue = update?.potentialImpactOnRevenue;
      lastObject.siDetail = update?.siDetail;
      lastObject.companiesToAction = "";
      lastObject.growthOpportunities = "";
      setData(updatedData);
    }
  };

  const handleCancel = () => {
    const cancelData = [...data];
    cancelData.pop();

    setData(cancelData);
    setAddNew && setAddNew(false);
  };
  const handleEditCancel = () => {
    setEdit(false);
    setFormData(afterUpdateSaved);
    setUpdate(afterUpdateSaved);
  };

  const delPopup = (id) => (
    <PopupBox>
      <h2>Delete</h2>
      <p>Are you sure you want to delete this item?</p>
      <div className="popup-btns">
        <Button
          variant="secondary-outline"
          size="md"
          title="Confirm"
          type="button"
          onClick={() => {
            deleteHandler(id);
            toast.dismiss();
            setIsToastOpen(false);
          }}
        />
        <Button
          variant="primary"
          size="md"
          title="Cancel"
          type="button"
          onClick={() => {
            toast.dismiss();
            setIsToastOpen(false);
          }}
        />
      </div>
    </PopupBox>
  );
  const delHandler = (id) => {
    if (isToastOpen) return;

    setIsToastOpen(true);
    const notify = () =>
      toast(delPopup(id), {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        hideProgressBar: true,
        pauseOnHover: false,
        draggable: false,
        theme: "light",
        onClose: () => setIsToastOpen(false),
      });
    notify();
  };
  const truncate = (input) =>
    input.length > 25 ? `${input.substring(0, 25)}...` : input;

  const popoverContent = (
    <Popover id="popover-trigger-hover-focus" style={{ zIndex: 9999 }}>
      <PreviewBox1>
        <div className="box-preview1">{formData.sourceName}</div>
      </PreviewBox1>
    </Popover>
  );

  const handleChange = (content) => {
    changeHandler("siDetail", content);
  };

  useEffect(() => {
    const script = document.createElement("script");
    const sendPromptHandler = () => {
      const chatFrame = document?.getElementById("chat-widget-iframe");
      const chatClosed = chatFrame?.classList?.contains("ac-widget-side");
      if (chatClosed)
        chatFrame?.classList?.remove("ac-widget-side"),
          chatFrame?.classList?.add("ac-widget-web");

      document?.querySelector("iframe")?.contentWindow?.postMessage(
        {
          action: "resetConversation",
        },
        "*"
      );

      const parts: string[] = [];

      if (filterData?.practiceAreaName) {
        parts.push(`Practice Area: ${filterData?.practiceAreaName}`);
      }
      if (filterData?.customTransformationalJourney) {
        parts.push(
          `Transformational Journey: ${filterData?.customTransformationalJourney}`
        );
      }
      if (filterData?.description) {
        parts.push(`Description: ${filterData?.description}`);
      }
      if (filterData?.regionName) {
        parts.push(`Region: ${filterData?.regionName}`);
      }
      if (item?.strategicImperative) {
        parts.push(`Strategic Imperative: ${item?.strategicImperative}`);
      }
      if (item?.event) {
        parts.push(`Event: ${item?.event}`);
      }

      parts.push(`Generate response as per above details`);

      const text = parts.join("\n");

      document?.querySelector("iframe")?.contentWindow?.postMessage(
        {
          action: "conversation",
          payload: {
            text: text,
          },
        },
        "*"
      );
    };

    document
      ?.getElementById(`btn-send-prompt ${item.id}`)
      ?.addEventListener("click", sendPromptHandler);

    document.head.appendChild(script);

    return () => {
      document
        ?.getElementById(`btn-send-prompt ${item.id}`)
        ?.removeEventListener("click", sendPromptHandler);
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (onEditModeChange) {
      onEditModeChange(edit);
    }
  }, [edit]);

  return (
    <>
      <tr
        key={item.id}
        data-id={item.id}
        className={edit || latest ? "edit" : ""}
      >
        <td>
          <TextareaAutosize
            name="strategicImperative"
            disabled={edit || latest ? false : true}
            onChange={(e) => {
              changeHandler("strategicImperative", e.target.value);
              validation(formData, setErrors);
            }}
            value={formData?.strategicImperative}
          />
          {errors?.strategicImperative && (
            <ErrorMessage className="text-danger">
              {errors?.strategicImperative}
            </ErrorMessage>
          )}
        </td>
        <td style={{ position: "relative" }}>
          <TextareaAutosize
            name="event"
            disabled={edit || latest ? false : true}
            value={formData?.event}
            onChange={(e) => {
              changeHandler("event", e.target.value);
              validation(formData, setErrors);
            }}
          />
          {errors?.event && (
            <ErrorMessage className="text-danger">{errors?.event}</ErrorMessage>
          )}
          {/* <button
            disabled={edit}
            id={`btn-send-prompt ${item.id}`}
            className="chat-icon"
          >
            <MaterialIcon
              showTooltip={false}
              className="btn-hover"
              iconName="chat_bubble"
              disabled={edit}
              onClick={() => {}}
            /> 
            </button>
            */}
          {!latest && (
            <button
              id={`btn-send-prompt ${item.id}`}
              disabled={isAnyRowEditing || edit}
              className="chat-icon"
              onClick={() => {}}
            >
              <img
                src={chatIcon}
                alt="chat"
                style={{ width: "24px", height: "24px" }}
              />
              <span
                style={{
                  color: "#FFFFFF",
                  fontSize: "12px",
                  marginLeft: "12px",
                }}
              >
                AI Growth Coach
              </span>
            </button>
          )}
        </td>
        <td>
          {edit || latest ? (
            <>
              <Editor
                apiKey="3qyej0twovgwvklxo176yhnorvfpy2f965i3ac4d56gm4fpr"
                init={{
                  menubar: false,
                  height: 200,
                  plugins: "lists",
                  statusbar: false,
                  force_br_newlines: false,
                  toolbar: "undo redo |  bold | bullist | code",
                  content_style:
                    "body {   font-family: 'Nunito Sans'; font-size: 14px; }",
                }}
                tinymceScriptSrc={"/tinymce/tinymce.min.js"}
                disabled={edit || latest ? false : true}
                initialValue={content}
                onChange={(e: any) => handleChange(e.level.content)}
              />
              {errors?.siDetail && (
                <ErrorMessage>{errors?.siDetail}</ErrorMessage>
              )}
            </>
          ) : (
            <div
              style={{
                overflowY: "auto",
                wordBreak: "break-word",
                maxHeight: "200px",
              }}
            >
              {parse(content)}
            </div>
          )}
        </td>
        <td className="text-center">
          <TextareaAutosize
            name="impactScore"
            disabled={edit || latest ? false : true}
            value={formData?.impactScore}
            onChange={(e) => {
              changeHandler("impactScore", e.target.value);
              validation(formData, setErrors);
            }}
          />
          {errors?.impactScore && (
            <ErrorMessage>{errors?.impactScore}</ErrorMessage>
          )}
        </td>
        <td className="text-center">
          <TextareaAutosize
            name="impactStartYear"
            disabled={edit || latest ? false : true}
            value={formData?.impactStartYear}
            onChange={(e) => {
              changeHandler("impactStartYear", e.target.value);
              validation(formData, setErrors);
            }}
          />
          {errors?.impactStartYear && (
            <ErrorMessage>{errors?.impactStartYear}</ErrorMessage>
          )}
        </td>
        <td className="text-center">
          <TextareaAutosize
            name="impactDuration"
            disabled={edit || latest ? false : true}
            value={formData?.impactDuration}
            onChange={(e) => {
              changeHandler("impactDuration", e.target.value);
              validation(formData, setErrors);
            }}
          />
          {errors?.impactDuration && (
            <ErrorMessage>{errors?.impactDuration}</ErrorMessage>
          )}
        </td>
        <td>
          <TextareaAutosize
            name="durationCurve"
            disabled={edit || latest ? false : true}
            value={formData?.durationCurve}
            onChange={(e) => {
              changeHandler("durationCurve", e.target.value);
              validation(formData, setErrors);
            }}
          />
          {errors?.durationCurve && (
            <ErrorMessage>{errors?.durationCurve}</ErrorMessage>
          )}
        </td>
        <td className="text-center">
          <TextareaAutosize
            name="potentialImpactOnRevenue"
            disabled={edit || latest ? false : true}
            value={formData?.potentialImpactOnRevenue}
            onChange={(e) => {
              changeHandler("potentialImpactOnRevenue", e.target.value);
              validation(formData, setErrors);
            }}
          />
          {errors?.potentialImpactOnRevenue && (
            <ErrorMessage>{errors?.potentialImpactOnRevenue}</ErrorMessage>
          )}
        </td>

        <td>
          <OverlayTrigger
            trigger={["click"]}
            rootClose
            placement="top"
            overlay={popoverContent}
          >
            <div>
              <TextareaAutosize
                disabled={true}
                value={truncate(formData.sourceName)}
              />
              {formData.sourceName.length > 25 && (
                <a style={{ cursor: "pointer" }}>See more</a>
              )}
            </div>
          </OverlayTrigger>
        </td>

        <td>
          <div className="table-btns">
            <React.Fragment>
              {latest === true ? (
                <>
                  <MaterialIcon
                    placement={"left"}
                    iconName="save"
                    tooltipTitle="Save"
                    showTooltip={true}
                    $iconSize={25}
                    color={colors.gray}
                    disabled={generating ? true : false}
                    onClick={() => (generating ? "" : addItem())}
                  />

                  <MaterialIcon
                    placement={"left"}
                    iconName="cancel"
                    tooltipTitle="cancel"
                    showTooltip={true}
                    $iconSize={25}
                    color={colors.gray}
                    disabled={generating ? true : false}
                    onClick={() => (generating ? "" : handleCancel())}
                  />
                </>
              ) : (
                <>
                  {!edit === true ? (
                    <MaterialIcon
                      placement={"left"}
                      iconName="edit"
                      tooltipTitle="Edit"
                      showTooltip={true}
                      $iconSize={25}
                      color={colors.gray}
                      disabled={generating || isAnyRowEditing ? true : false}
                      onClick={() =>
                        generating || isAnyRowEditing ? "" : setEdit(true)
                      }
                    />
                  ) : (
                    <MaterialIcon
                      placement={"left"}
                      iconName="save"
                      tooltipTitle="Save"
                      showTooltip={true}
                      $iconSize={25}
                      color={colors.gray}
                      disabled={generating ? true : false}
                      onClick={() => (generating ? "" : updateItem())}
                    />
                  )}

                  <MaterialIcon
                    placement={"left"}
                    iconName="delete"
                    tooltipTitle="Delete"
                    showTooltip={true}
                    $iconSize={25}
                    color={colors.gray}
                    disabled={generating || isAnyRowEditing ? true : false}
                    onClick={() =>
                      generating || isAnyRowEditing ? "" : delHandler(item.id)
                    }
                  />
                  {edit === true && (
                    <MaterialIcon
                      placement={"left"}
                      iconName="cancel"
                      tooltipTitle="Cancel"
                      showTooltip={true}
                      $iconSize={25}
                      color={colors.gray}
                      disabled={generating ? true : false}
                      onClick={() => {
                        generating ? "" : handleEditCancel();
                      }}
                    />
                  )}

                  {item?.isEdited === true && (
                    <>
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="bottom"
                        overlay={
                          <Popover
                            id="popover-trigger-hover-focus"
                            style={{ zIndex: 9999, left: "10px" }}
                          >
                            <PreviewBox>
                              <div className="box-preview">
                                <p>{`Modified By: ${item?.lastModifiedByFullName} `}</p>
                                <p>{`Modified Date: ${moment(
                                  item?.lastModificationTime
                                ).format("YYYY-MM-DD")} `}</p>
                              </div>
                            </PreviewBox>
                          </Popover>
                        }
                      >
                        <div className="hover-component">
                          <MaterialIcon
                            placement={"left"}
                            iconName="visibility"
                            tooltipTitle="View"
                            showTooltip={true}
                            $iconSize={25}
                            color={colors.gray}
                            disabled={generating ? true : false}
                          />
                        </div>
                      </OverlayTrigger>
                    </>
                  )}
                </>
              )}
            </React.Fragment>
          </div>
        </td>
      </tr>
    </>
  );
};

export default TableRow;
