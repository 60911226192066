/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FResponseDto_1OfOfAbp_PagedResultDto_1OfOfFSi8_Dtos_TopRankRequests_Si8TopRankDataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_Volo_Abp_Ddd_Application_Contracts } from '../models/FResponseDto_1OfOfAbp_PagedResultDto_1OfOfFSi8_Dtos_TopRankRequests_Si8TopRankDataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_Volo_Abp_Ddd_Application_Contracts';
import type { FResponseDto_1OfOfFSi8_Dtos_TopRankRequests_Si8TopRankRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/FResponseDto_1OfOfFSi8_Dtos_TopRankRequests_Si8TopRankRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class Si8TopRankRequestService {

    /**
     * @param si8DataRequestId
     * @returns FResponseDto_1OfOfFSi8_Dtos_TopRankRequests_Si8TopRankRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static postCoreApiApiAppSi8TopRankRequestTopRankRequest(
        si8DataRequestId: string,
    ): CancelablePromise<FResponseDto_1OfOfFSi8_Dtos_TopRankRequests_Si8TopRankRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/si8Top-rank-request/top-rank-request/{si8DataRequestId}',
            path: {
                'si8DataRequestId': si8DataRequestId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param si8TopRankRequestId
     * @returns FResponseDto_1OfOfFSi8_Dtos_TopRankRequests_Si8TopRankRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getCoreApiApiAppSi8TopRankRequestSi8TopRankDataStatus(
        si8TopRankRequestId: string,
    ): CancelablePromise<FResponseDto_1OfOfFSi8_Dtos_TopRankRequests_Si8TopRankRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/si8Top-rank-request/si8Top-rank-data-status/{si8TopRankRequestId}',
            path: {
                'si8TopRankRequestId': si8TopRankRequestId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param si8TopRankRequestId
     * @param searchKeyword
     * @param sortOrder
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @returns FResponseDto_1OfOfAbp_PagedResultDto_1OfOfFSi8_Dtos_TopRankRequests_Si8TopRankDataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_Volo_Abp_Ddd_Application_Contracts Success
     * @throws ApiError
     */
    public static getCoreApiApiAppSi8TopRankRequestSi8TopRankData(
        si8TopRankRequestId: string,
        searchKeyword?: string,
        sortOrder?: string,
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
    ): CancelablePromise<FResponseDto_1OfOfAbp_PagedResultDto_1OfOfFSi8_Dtos_TopRankRequests_Si8TopRankDataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_Volo_Abp_Ddd_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/si8Top-rank-request/si8Top-rank-data/{si8TopRankRequestId}',
            path: {
                'si8TopRankRequestId': si8TopRankRequestId,
            },
            query: {
                'SearchKeyword': searchKeyword,
                'SortOrder': sortOrder,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
