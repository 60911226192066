/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FSi8_Dtos_ExcelProcessing_DownloadFileDto } from '../models/FSi8_Dtos_ExcelProcessing_DownloadFileDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExcelProcessingService {

    /**
     * @param id
     * @returns FSi8_Dtos_ExcelProcessing_DownloadFileDto Success
     * @throws ApiError
     */
    public static getCoreApiApiAppExcelProcessingExportSi8Data(
        id: string,
    ): CancelablePromise<FSi8_Dtos_ExcelProcessing_DownloadFileDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/excel-processing/{id}/export-si8Data',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
