import PptxGenJS from "pptxgenjs";
import { SlideHeader } from "../Components/SlideHeader";
import {
  headerHeight,
  heroSectionHeight,
  primaryColor,
  secondaryColor,
  sideMargin,
  slideWidth,
} from "../Components/SlideConstants";
import { SlideFooter } from "../Components/SlideFooter";
import { SlideSource } from "../Components/SlideSource";
import diagramImage from "../../V1/image/diagram.png";
import { getInchesFromPixels } from "../Components/getInchesfromPixels";
import { textCapitalize } from "../../../utils/textCapitalize";

export const DiagramSlide = (pptx: PptxGenJS, pageNo?: number, data?: any) => {
  const slide = pptx.addSlide();
  SlideHeader(slide, data);

  slide.addShape(pptx.ShapeType.rect, {
    x: sideMargin,
    y: headerHeight + heroSectionHeight + 0.3,
    w: slideWidth - sideMargin * 2,
    h: 0.32,
    fill: { color: secondaryColor },
  });

  slide.addText(`Transformation in ${textCapitalize(data?.title?.sector)}`, {
    x: sideMargin + 0.1,
    y: headerHeight + heroSectionHeight + 0.4,
    color: "ffffff",
    fontSize: 12,
    h: getInchesFromPixels(12),
    bold: true,
  });
  slide.addText("Why Is It Increasingly Difficult to Grow?", {
    x: sideMargin,
    y: headerHeight + heroSectionHeight + 0.8,
    color: secondaryColor,
    fontSize: 12,
    h: getInchesFromPixels(12),
    bold: false,
  });
  slide.addText(
    "Exhibit 1:The Strategic Imperative 8™: Factors Creating Pressure on Growth",
    {
      x: sideMargin,
      y: headerHeight + heroSectionHeight + 1.1,
      color: primaryColor,
      fontSize: 10,
      h: getInchesFromPixels(12),
      bold: false,
    }
  );

  slide.addImage({
    path: diagramImage,
    x: sideMargin + 0.8,
    y: 3 + heroSectionHeight,
    w: 6,

    h: 4.8,
  });
  SlideSource(slide, 8.5 + heroSectionHeight);

  SlideFooter(slide, pageNo);
};
