import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

interface TitleState {
  titleName: string;
  date: string;
  market: string;
  sector: string;
  region: string;
}

interface PdfExportState {
  title: TitleState;
  analystName: string;
  chartImage: string | any;
  strategicImperativeTable: any;
  transformationData: any;
}

const initialState: PdfExportState = {
  title: {
    titleName: "",
    date: moment().format("YYYY"),
    market: "",
    sector: "",
    region: "",
  },
  analystName: "",
  chartImage: null,
  strategicImperativeTable: null,
  transformationData: null,
};

// eslint-disable-next-line react-refresh/only-export-components
const PdfExportSlice = createSlice({
  name: "pdfExport",
  initialState,
  reducers: {
    setPdfAnalystName: (state, action) => {
      state.analystName = action.payload;
    },
    setPDFTitle: (state, action: PayloadAction<TitleState>) => {
      state.title = { ...state.title, ...action.payload };
    },
    setPdfChartImage: (state, action: PayloadAction<any>) => {
      state.chartImage = action.payload;
    },
    setPdfStrategicImperativeTable: (state, action) => {
      state.strategicImperativeTable = action.payload;
    },
    setPdfTransformationData: (state, action) => {
      state.transformationData = action.payload;
    },
  },
});

export const {
  setPdfAnalystName,
  setPDFTitle,
  setPdfChartImage,
  setPdfStrategicImperativeTable,
  setPdfTransformationData,
} = PdfExportSlice.actions;
export default PdfExportSlice.reducer;
