import { Text, View } from "@react-pdf/renderer";
// import React from "react";

// import logo from "../../assets/pdf/logo.png";
import { PdfPageProps } from "../types";
import PdfPageLayout from "../Layouts/PdfPageLayout";
import B from "../components/B";
import { splitDataIntoSections } from "../../../pages/home/visualization/VisualizationDetailCard";

export default function TransformationPage(props: PdfPageProps) {
  const { size, pageId, pageData, data } = props;
  const contentData = splitDataIntoSections(pageData?.topRankSi8Data);
  const companiesToActionData = splitDataIntoSections(
    pageData?.companiesToAction
  );
  const growthOpportunityiesData = splitDataIntoSections(
    pageData?.growthOpportunityies
  );

  return (
    <PdfPageLayout data={data} size={size} pageId={pageId}>
      <View style={{ width: "100%", flexDirection: "column" }}>
        <View style={{ height: "auto", backgroundColor: "white" }}>
          <Text style={{ fontSize: 14, marginBottom: 10 }}>
            <Text style={{ fontSize: 14, color: "#1E548C" }}>
              Transformation {Number(pageId) - 6}:
            </Text>{" "}
            <Text
              style={{ fontSize: 14, color: "#1E548C", padding: 0, margin: 0 }}
            >
              {pageData?.strategicImperative}
            </Text>
          </Text>
        </View>

        <View
          style={{
            backgroundColor: "#fff",
            marginTop: 10,
            padding: 5,
            paddingLeft: 10,
            paddingRight: 16,
          }}
        >
          {contentData?.slice(0, 6).map((item: any) => {
            return (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingRight: 20,
                  marginBottom: 4,
                }}
              >
                <Text style={{ fontSize: 9, marginRight: 20 }}>•</Text>
                <Text style={{ fontSize: 9 }}>
                  <B
                    style={{
                      fontSize: 9,
                      color: "#000",
                      fontWeight: "bold",
                      marginRight: 5,
                    }}
                  >
                    {`${item?.title ?? ""} `}
                  </B>
                  <Text style={{ fontSize: 9, color: "#222" }}>
                    {item?.content}
                  </Text>
                </Text>
              </View>
            );
          })}
          {/* {[...Array(8)]?.map((item: any) => {
            return (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingRight: 20,
                  marginBottom: 4,
                }}
              >
                <Text style={{ fontSize: 9, marginRight: 20 }}>•</Text>
                <Text style={{ fontSize: 9 }}>
                  <B
                    style={{
                      fontSize: 9,
                      color: "#000",
                      fontWeight: "bold",
                      marginRight: 5,
                    }}
                  >
                    Enhanced Connectivity :{" "}
                  </B>
                  <Text style={{ fontSize: 9, color: "#222" }}>
                    The deployment of 5G technology will provide ultra-reliable
                    low-latency communication, which is crucial for IoT devices
                    to function effectively. This enhanced connectivity will
                    enable real-time data exchange and processing, allowing
                    industries to optimize operations and improve
                    decision-making processes significantly
                  </Text>
                </Text>
              </View>
            );
          })} */}
        </View>
        <View
          style={{
            backgroundColor: "#fff",
            marginTop: 10,
            padding: 5,
            paddingLeft: 10,
            paddingRight: 16,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 8,
            paddingBottom: 0,
          }}
        >
          <View style={{ maxWidth: "48%" }}>
            <Text
              style={{
                fontSize: 12,
                color: "#1E548C",
                fontWeight: "bold",
                marginBottom: 2,
                textAlign: "center",
                width: "100%",
              }}
            >
              Growth Opportunities
            </Text>{" "}
            {growthOpportunityiesData?.slice(0, 3).map((item: any) => {
              return (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingRight: 20,
                    marginBottom: 2,
                  }}
                >
                  <Text style={{ fontSize: 9, marginRight: 20 }}>•</Text>
                  <Text style={{ fontSize: 9 }}>
                    <B
                      style={{
                        fontSize: 9,
                        color: "#000",
                        fontWeight: "bold",
                        marginRight: 5,
                      }}
                    >
                      {`${item?.title ?? ""} `}
                    </B>
                    <Text style={{ fontSize: 9, color: "#222" }}>
                      {item?.content}
                    </Text>
                  </Text>
                </View>
              );
            })}
          </View>
          <View style={{ maxWidth: "48%" }}>
            <Text
              style={{
                width: "100%",
                fontSize: 12,
                color: "#1E548C",
                fontWeight: "bold",
                marginBottom: 4,
                textAlign: "center",
              }}
            >
              Companies To Action
            </Text>{" "}
            {companiesToActionData?.slice(0, 3).map((item: any) => {
              return (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingRight: 20,
                    marginBottom: 4,
                  }}
                >
                  <Text style={{ fontSize: 9, marginRight: 20 }}>•</Text>
                  <Text style={{ fontSize: 9 }}>
                    <B
                      style={{
                        fontSize: 9,
                        color: "#000",
                        fontWeight: "bold",
                        marginRight: 5,
                      }}
                    >
                      {`${item?.title ?? ""} `}
                    </B>
                    <Text style={{ fontSize: 9, color: "#222" }}>
                      {item?.content}
                    </Text>
                  </Text>
                </View>
              );
            })}
          </View>
        </View>
      </View>
    </PdfPageLayout>
  );
}
