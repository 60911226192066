import { Chart as ChartJS } from "chart.js";
import moment from "moment";

export const barCurvePlot = {
  id: "barCurvePlot",
  afterDatasetsDraw: (chart) => {
    const ctx = chart?.ctx;
    const chartArea = chart.chartArea;

    chart?.data?.datasets?.forEach((dataset, datasetIndex) => {
      const meta = chart?.getDatasetMeta(datasetIndex);
      meta?.data?.forEach((bar, index) => {
        const a = bar?.$context?.raw[2]?.durationCurve;
        const x = bar.x;
        const y = bar.y;
        const width = bar.width;
        const height = bar.height;

        ctx.save();

        // Set up clipping region to only draw inside the chart area
        ctx.beginPath();
        ctx.rect(
          chartArea.left,
          chartArea.top,
          chartArea.right - chartArea.left,
          chartArea.bottom - chartArea.top + 100
        );
        ctx.clip();

        let startX,
          startY,
          endX,
          endY,
          controlPoint1X,
          controlPoint1Y,
          controlPoint2X,
          controlPoint2Y;

        if (a === "Linear") {
          startX = x - width;
          startY = y + height / 2;
          endX = x;
          endY = y - height / 2;

          ctx.beginPath();
          ctx.moveTo(startX, startY);
          ctx.lineTo(endX, endY);
          ctx.lineWidth = 2;
          ctx.strokeStyle = "#111c7b";
          ctx.stroke();
        } else if (a === "Exponential") {
          startX = x - width;
          startY = y + height / 8; // Move up by 12 pixels
          endX = x;
          endY = y - height / 2; // Move up by 12 pixels

          controlPoint1X = startX + width / 6; // Adjust as needed
          controlPoint1Y = startY + height - 5; // Adjust as needed
          controlPoint2X = endX; // Adjust as needed
          controlPoint2Y = endY; // Adjust as needed

          ctx.beginPath();
          ctx.moveTo(startX, startY);
          ctx.bezierCurveTo(
            controlPoint1X,
            controlPoint1Y,
            controlPoint2X,
            controlPoint2Y,
            endX,
            endY
          );
          ctx.lineWidth = 3;
          ctx.strokeStyle = "#111c7b";
          ctx.stroke();
        } else if (a === "Logistic") {
          startX = x - width;
          startY = y + height / 2; // Move up by 12 pixels
          endX = x;
          endY = y - height / 2; // Move up by 12 pixels

          controlPoint1X = (startX + endX) / 2 + 30; // X-coordinate halfway between start and end
          controlPoint1Y = startY + 5; // Y-coordinate stays the same
          controlPoint2X = controlPoint1X - 10; // X-coordinate stays the same
          controlPoint2Y = endY; // Y-coordinate is the same as the endY

          ctx.beginPath();
          ctx.moveTo(startX, startY);
          ctx.bezierCurveTo(
            controlPoint1X,
            controlPoint1Y,
            controlPoint2X,
            controlPoint2Y,
            endX,
            endY
          );
          ctx.lineWidth = 2;
          ctx.strokeStyle = "#111c7b";
          ctx.stroke();
        } else if (a === "Polynomial") {
          startX = x - width;
          startY = y + height / 8; // Move up by 12 pixels
          endX = x;
          endY = y - height / 2; // Move up by 12 pixels

          controlPoint1X = startX + width / 6 + 10; // Adjust as needed
          controlPoint1Y = startY + height - 15; // Adjust as needed
          controlPoint2X = endX; // Adjust as needed
          controlPoint2Y = endY + 8; // Adjust as needed

          ctx.beginPath();
          ctx.moveTo(startX, startY);
          ctx.bezierCurveTo(
            controlPoint1X,
            controlPoint1Y,
            controlPoint2X,
            controlPoint2Y,
            endX,
            endY
          );
          ctx.lineWidth = 2;
          ctx.strokeStyle = "#111c7b";
          ctx.stroke();
        } else if (a === "Oscillatory") {
          startX = x - width;
          startY = y + height / 2;
          endX = x;
          endY = y + 5;

          controlPoint1X = startX + width - 40;
          controlPoint1Y = startY - height - 25;
          controlPoint2X = endX - width + 130;
          controlPoint2Y = endY - height + 35;

          ctx.beginPath();
          ctx.moveTo(startX, startY);
          ctx.bezierCurveTo(
            controlPoint1X,
            controlPoint1Y,
            controlPoint2X,
            controlPoint2Y,
            endX,
            endY
          );
          ctx.lineWidth = 3;
          ctx.strokeStyle = "#111c7b";
          ctx.stroke();
        }

        ctx.restore();
      });
    });
  },
};

// Define other plugins
export const customArrowPlugin = {
  id: "customArrowPlugin",
  afterDraw: function (chart) {
    const ctx = chart.ctx;
    const chartArea = chart.chartArea;

    chart?.data?.datasets?.forEach(function (dataset, datasetIndex) {
      const meta = chart?.getDatasetMeta(datasetIndex);
      if (!meta.hidden) {
        meta?.data?.forEach(function (element, index) {
          const before20IsTrue = element?.$context?.raw.some((item, index) => {
            if (typeof item == "object") {
              const startYear = item?.impactStartYear;
              const now = new Date().getFullYear();
              return startYear < now;
            }
          });
          const after20IsTrue = element?.$context?.raw.some((item, index) => {
            if (typeof item == "object") {
              const endYear = (
                Number(item?.impactStartYear) + Number(item?.impactDuration)
              ).toString();
              const afterTwentyYear = moment().add(20, "years").format("YYYY");
              return endYear > afterTwentyYear;
            }
          });

          const arrowFontSize = Math.max(chart.width / 50, 10); // Adjust arrow size based on chart width
          ctx.font = `${arrowFontSize}px Arial`;

          if (after20IsTrue && before20IsTrue) {
            const beforeXPosition = chartArea.left + 15;
            const beforeYPosition = chartArea.bottom + arrowFontSize * 1.5;
            ctx.fillStyle = "black";
            ctx.fillText("\u2194", beforeXPosition, beforeYPosition);

            const afterXPosition = chartArea.right - 25;
            const afterYPosition = chartArea.bottom + arrowFontSize * 1.5;
            ctx.fillStyle = "black";
            ctx.fillText("\u2194", afterXPosition, afterYPosition);
          } else if (before20IsTrue) {
            const xPosition = chartArea.left + 15;
            const yPosition = chartArea.bottom + arrowFontSize * 1.5;
            ctx.fillStyle = "black";
            ctx.fillText("\u2194", xPosition, yPosition);
          } else if (after20IsTrue) {
            const xPosition = chartArea.right - 25;
            const yPosition = chartArea.bottom + arrowFontSize * 1.5;
            ctx.fillStyle = "black";
            ctx.fillText("\u2194", xPosition, yPosition);
          }
        });
      }
    });
  },
};

export const chartSize = {
  id: "chartSize",
  afterRender: function (chart) {
    if (!chart.chartSizeUpdated) {
      chart.chartSizeUpdated = true;

      const currentWidth = chart.chartArea.right - chart.chartArea.left;
      const currentHeight = chart.chartArea.bottom - chart.chartArea.top;

      const newWidth = currentWidth + 1;
      const newHeight = currentHeight + 1;

      chart.chartArea.right = chart.chartArea.left + newWidth;
      chart.chartArea.bottom = chart.chartArea.top + newHeight;

      chart.update();
    }
  },
};

export const customLabelPlugin = {
  id: "customLabelPlugin",
  afterDatasetsDraw: function (chart) {
    const ctx = chart.ctx;
    const chartArea = chart.chartArea;

    chart.data.datasets.forEach(function (dataset, datasetIndex) {
      const meta = chart.getDatasetMeta(datasetIndex);
      if (!meta.hidden) {
        meta.data.forEach(function (element, index) {
          const centerX = element.getCenterPoint().x;
          const centerY = element.getCenterPoint().y;

          const number = index + 1;
          const radius = Math.max(chart.width / 100, 10); // Adjust radius based on chart width
          const startAngle = 0;
          const endAngle = Math.PI * 2;
          const counterClockwise = false;

          ctx.beginPath();
          ctx.arc(
            centerX,
            centerY,
            radius,
            startAngle,
            endAngle,
            counterClockwise
          );
          ctx.fillStyle = "orange";
          ctx.fill();

          ctx.font = `${radius}px Arial`;
          ctx.fillStyle = "white";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillText(number, centerX, centerY);
        });
      }
    });
  },
};

export const barClip = {
  id: "barClip",
  beforeDatasetsDraw: function (chart) {
    const ctx = chart?.ctx;
    const chartArea = chart.chartArea;
    const datasets = chart.config.data.datasets;
    const dataLength = datasets[0].data.length;

    ctx.save();

    const barWidth = (chartArea.right - chartArea.left) / dataLength;
    const barGap = barWidth * 0.1; // Assuming a gap of 10% of the bar width
    const clipWidth = chartArea.right - chartArea.left - barWidth * 2;
    const clipHeight = chartArea.bottom - chartArea.top;
    const clipX = chartArea.left + barWidth;
    const clipY = chartArea.top;

    ctx.beginPath();
    ctx.rect(clipX, clipY, clipWidth, clipHeight);
    ctx.clip();
  },
  afterDatasetsDraw: function (chart) {
    const ctx = chart?.ctx;
    ctx.restore();
  },
};
