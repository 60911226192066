import PdfPageLayout from "../Layouts/PdfPageLayout";
import { PdfPageProps } from "../types";
import TopText from "../components/TopText";
import SourceComp from "../components/SourceComp";
import { Text, View } from "@react-pdf/renderer";
import { textCapitalize } from "../../../utils/textCapitalize";

export default function StrategicImperativeTable({
  size,
  pageId,
  data,
}: PdfPageProps) {
  const padding = 10;
  return (
    <PdfPageLayout data={data} size={size} pageId={pageId}>
      <>
        <TopText
          text={`Exhibit 3a: Top Transformations Impacting Growth in ${textCapitalize(
            data?.title.sector
          )}, ${data?.title.region}, ${data?.title.date}`}
        />
        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#1582C5",
            }}
          >
            <Text
              style={{
                fontSize: 9,
                color: "white",
                padding: padding,
                borderRight: "1px solid white",
                width: 45,
                textAlign: "center",
              }}
            >
              Rank
            </Text>
            <Text
              style={{
                fontSize: 9,
                color: "white",
                padding: padding,
                borderRight: "1px solid white",
                width: 115,
                // backgroundColor: "green",
              }}
            >
              Strategic Imperative Category
            </Text>
            <Text
              style={{
                fontSize: 9,
                color: "white",
                padding: padding,
                borderRight: "1px solid white",
                width: 175,
              }}
            >
              Strategic Imperative
            </Text>
            <Text
              style={{
                fontSize: 9,
                color: "white",
                padding: padding,
                borderRight: "1px solid white",
                width: 70,
                textAlign: "center",
              }}
            >
              Impact Score(%)
            </Text>
            <Text
              style={{
                fontSize: 9,
                color: "white",
                padding: padding,
                borderRight: "1px solid white",
                width: 80,
                textAlign: "center",
              }}
            >
              Timeline{" "}
            </Text>
            <Text
              style={{
                fontSize: 9,
                color: "white",
                borderRight: "1px solid white",
                padding: padding,
                width: 100,
                textAlign: "center",
              }}
            >
              Duration Curve
            </Text>
          </View>
          {data?.strategicImperativeTable?.map((item, index: number) => (
            <TopTableRow isOdd={index % 2 === 0} rowData={item} index={index} />
          ))}
        </View>
        <SourceComp />
      </>
    </PdfPageLayout>
  );
}

interface TableRowProps {
  isOdd: boolean;
  rowData?: any;
  index: number;
}

const TopTableRow = (props: TableRowProps) => {
  const { isOdd, rowData, index } = props;
  const fontSize = 9;
  const padding = 6;
  return (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: isOdd ? "#CCD8EA" : "#E7EDF5",
          borderBottom: "2px solid white",
        }}
      >
        <Text
          style={{
            fontSize,
            padding: padding,
            borderRight: "1px solid white",
            width: 45,
            textAlign: "center",
          }}
        >
          {index + 1}
        </Text>
        <Text
          style={{
            fontSize,
            padding: padding,
            borderRight: "1px solid white",
            width: 115,
          }}
        >
          {rowData?.strategicImperative}
        </Text>
        <Text
          style={{
            fontSize,
            padding: padding,
            borderRight: "1px solid white",
            width: 175,
          }}
        >
          {rowData?.event}
        </Text>
        <Text
          style={{
            fontSize,
            padding: padding,
            borderRight: "1px solid white",
            width: 70,
            textAlign: "center",
          }}
        >
          {rowData?.impactScore}
        </Text>
        <Text
          style={{
            fontSize,
            padding: padding,
            borderRight: "1px solid white",
            width: 80,
            textAlign: "center",
          }}
        >
          {rowData?.impactStartYear}-
          {rowData?.impactStartYear + rowData?.impactDuration}
        </Text>
        <Text
          style={{
            fontSize,
            padding: padding,
            borderRight: "1px solid white",
            width: 100,
            textAlign: "center",
          }}
        >
          {rowData?.durationCurve}
        </Text>
      </View>
    </>
  );
};
