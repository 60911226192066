import PptxGenJS from "pptxgenjs";
import { SlideHeader } from "../Components/SlideHeader";
import {
  containerWidth,
  headerHeight,
  heroSectionHeight,
  secondaryColor,
  sideMargin,
} from "../Components/SlideConstants";
import { SlideFooter } from "../Components/SlideFooter";

import { SlideBlueBorderText } from "../Components/SlideBlueBorderText";
import { getInchesFromPixels } from "../Components/getInchesfromPixels";

export const AxisSlide = (pptx: PptxGenJS, pageNo?: number, data?: any) => {
  const slide = pptx.addSlide();
  SlideHeader(slide, data);

  slide.addText(
    "Frost Radar Metrics: 2 Major Indices, 10 Analytical Ingredients, 1 Platform",
    {
      x: sideMargin,
      y: headerHeight + heroSectionHeight + 0.4,
      w: containerWidth,
      color: secondaryColor,
      fontSize: 16,
      h: getInchesFromPixels(16),
      bold: false,
    }
  );

  SlideBlueBorderText(
    slide,
    headerHeight + heroSectionHeight + 1.2,
    "Vertical Axis - The Growth Index"
  );
  slide.addText(
    "Growth index is a measure of a company’s growth performance and track record, along with its ability to develop and execute a fully aligned growth strategy and vision; a robust growth pipeline system; and effective market, competitor, and end-user focused sales and marketing strategies.",
    {
      x: sideMargin,
      y: headerHeight + heroSectionHeight + 1.15,
      w: containerWidth,
      h: "10%",
      fontSize: 12,
    }
  );

  const verticalAxisData = [
    {
      title: "GI1: Market Share (previous 3 years)",
      content:
        " Market share relative to its competitors in a given market space for the previous three years.",
    },
    {
      title: "GI2: Revenue Growth (previous 3 years)",
      content:
        " Revenue growth rate for the previous three years in the market/industry/category that forms the context for the given Frost Radar.",
    },
    {
      title: "GI3: Growth Pipeline",
      content:
        " Evaluation of the strength and leverage of the company’s growth pipeline system to capture and prioritize growth opportunities.",
    },
    {
      title: "GI4: Vision and Strategy",
      content:
        " Assessment of how well a company’s growth strategy is aligned with its vision.",
    },
    {
      title: "GI5: Sales and Marketing",
      content:
        " Measure of the effectiveness of a company’s sales and marketing efforts in driving demand and achieving growth objectives.",
    },
  ];

  const verticalAxisRow = [
    ...verticalAxisData.map((item) => {
      return [
        {
          text: [
            {
              text: `• ${item.title} : `,
              options: {
                bold: true,
              },
            },
            {
              text: item.content,
            },
          ],
        },
      ];
    }),
  ];

  slide.addTable(verticalAxisRow, {
    x: sideMargin + 0.25,
    y: headerHeight + heroSectionHeight + 2.2,
    h: "10%",
  });

  SlideBlueBorderText(
    slide,
    headerHeight + heroSectionHeight + 5.3,
    "Horizontal Axis - The Innovation Index"
  );
  slide.addText(
    "Innovation index measures a company’s ability to develop products/ services/ solutions that are developed with a clear understanding of disruptive Mega Trends and evolving customer needs.",
    {
      x: sideMargin,
      y: headerHeight + heroSectionHeight + 5.25,
      w: containerWidth,
      h: "10%",
      fontSize: 12,
    }
  );

  const horizontalAxisData = [
    {
      title: "II1: Innovation Scalability",
      content:
        " Determines whether the organization’s innovation(s) is/are globally scalable and applicable in multiple markets and verticals.",
    },
    {
      title: "II2: Research and Development",
      content:
        " Measures the efficacy of a company’s R&D strategy based on its investment and contribution to the innovation pipeline.",
    },
    {
      title: "II3: Product Portfolio",
      content:
        " Evaluates the contribution of new products to the company’s annual revenues.",
    },
    {
      title: "II4: Megatrends Leverage",
      content:
        " Assesses how a company leverages evolving long-term opportunities and new business models.",
    },
    {
      title: "II5: Customer Alignment",
      content:
        " Evaluates the applicability of a company’s products to current and potential customers over a 7-year horizon.",
    },
  ];

  const horizontalAxisRow = [
    ...horizontalAxisData.map((item) => {
      return [
        {
          text: [
            {
              text: `• ${item.title} : `,
              options: {
                bold: true,
              },
            },
            {
              text: item.content,
            },
          ],
        },
      ];
    }),
  ];

  slide.addTable(horizontalAxisRow, {
    x: sideMargin + 0.25,
    y: headerHeight + heroSectionHeight + 6.2,
    h: "10%",
  });

  SlideFooter(slide, pageNo);
};
