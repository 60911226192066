import { Image, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { PdfPageProps } from "../types";

import logo from "../../assets/pdf/logo.png";
import coverImage from "../image/coverImage.png";
import homeImage from "../../assets/pdf/coverContent.png";
import StripSection from "../components/StripSection";
import B from "../components/B";
import { title } from "process";
import { textCapitalize } from "../../../utils/textCapitalize";

const styles = StyleSheet.create({
  rightContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 10,
    marginTop: 12,
  },
  column: {
    width: "48%",
    marginBottom: 12,
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#00B0F0",
    marginBottom: 5,
  },
  content: {
    fontSize: 10,
  },
});

export default function CoverPage(props: PdfPageProps) {
  const { size, data } = props;
  return (
    <Page
      size={size}
      style={{
        backgroundColor: "#172C55",
        padding: 20,
        color: "#ffffff",
      }}
    >
      <View style={{ height: "100%", width: "100%", flexDirection: "column" }}>
        <View
          style={{
            alignItems: "center",
          }}
        >
          <Image src={logo} style={{ width: 350, marginBottom: 30 }} />
          <Text style={{ fontSize: "24px" }}>
            TRANSFORMATIONAL GROWTH JOURNEY
          </Text>
          <Text style={{ fontSize: 14 }}>
            “Powered by the Growth Pipeline Engine”
          </Text>
          <Image
            src={homeImage}
            style={{ width: "80%", alignSelf: "center", marginTop: 30 }}
          />
        </View>
        <View style={{ marginTop: 30, display: "flex", flexDirection: "row" }}>
          <View style={{ width: "33%", paddingRight: 10 }}>
            <Text style={{ fontSize: 20, fontWeight: "bold", marginTop: 6 }}>
              {`Top 10 Strategic Imperatives in ${textCapitalize(
                data?.title.sector
              )}, ${data?.title.region}, ${data?.title.date}`}
            </Text>
            <Text style={{ fontSize: 10, fontWeight: "bold", marginTop: 10 }}>
              Date: {new Date().toLocaleDateString()}
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text
              style={{
                borderWidth: 0.6,
                borderColor: "white",
                height: "230px",
              }}
            ></Text>
          </View>
          <View style={{ width: "67%", paddingLeft: 12 }}>
            <Text
              style={{
                fontSize: 16,
                fontWeight: "bold",
                marginTop: 6,
                textDecoration: "underline",
              }}
            >
              Our Focus Today
            </Text>

            <View style={styles.rightContainer}>
              <View style={styles.column}>
                <Text style={styles.title}>STRATEGIC IMPERATIVE</Text>
                <Text style={styles.content}>Why Now? Why This? Why You?</Text>
              </View>

              <View style={styles.column}>
                <Text style={styles.title}>BENEFITS & IMPACTS</Text>
                <Text style={styles.content}>
                  Survive & Thrive: Transformation
                </Text>
              </View>

              <View style={styles.column}>
                <Text style={styles.title}>OUR SOLUTION</Text>
                <Text style={styles.content}>
                  The Top Transformations Impacting Future Growth Potential
                </Text>
              </View>

              <View style={styles.column}>
                <Text style={styles.title}>NEXT STEP</Text>
                <Text style={styles.content}>Growth Pipeline Dialog</Text>
              </View>

              <View style={styles.column}>
                <Text style={styles.title}>Participants</Text>
                <Text style={styles.content}>&lt;Client Name&gt;</Text>
              </View>

              <View style={styles.column}>
                <Text style={styles.title}>Presenters</Text>
                <Text style={styles.content}>&lt;Frost Team&gt;</Text>
              </View>
            </View>
          </View>
        </View>
        <Text
          style={{
            fontSize: 18,
            marginTop: 30,
            textAlign: "center",
            color: "#F26722",
          }}
        >
          How is your organization maximizing its future growth potential?
        </Text>
      </View>
    </Page>
  );
}
