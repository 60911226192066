import React, { useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import useFrostSi8Api from "../../hooks/useFrostSi8Api";
import { TransformationPageContextType } from "../../layout/TransformationPagesLayout";
import SubSegmentComp from "../home/subSegment/subSegmentComp";

export default function TransformationSubSegment() {
  const { si8DataRequestId } = useParams();
  const { handleRequest } = useFrostSi8Api();
  const { id, updateId } = useOutletContext<TransformationPageContextType>();
  useEffect(() => {
    updateId(si8DataRequestId as string);
  }, [si8DataRequestId, updateId]);

  return (
    <div>
      <SubSegmentComp
      // toggle={toggle}
      // tableGenerated={tableGenerated}
      // data={si8DataResponse?.data}
      />
    </div>
  );
}
