export const textCapitalize = (text: string | null | undefined) => {
  if (!text) return "";
  return text
    .trim()
    .toLowerCase()
    .split(/\s+/)
    .map((word: string) => {
      return word[0].toUpperCase() + word.slice(1);
    })
    .join(" ");
};
