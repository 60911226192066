import TableAndDataLayout from "../Layouts/TableAndDataLayout";
import TableAndDataRow from "../components/TableAndDataRow";
import { PdfPageProps } from "../types";
interface dataType {
  title: string;
  content: string;
}

export default function DefinedPage({ size, data, pageId }: PdfPageProps) {
  const dataList = [
    {
      title: "Innovative Business Models",
      content:
        "A new revenue model that defines how a company creates and capitalizes economic value, typically impacting its value proposition, product offering, operational strategies, and brand positioning",
    },
    {
      title: "Customer Value Chain Compression",
      content:
        "Customer value chain compression because of advanced technologies, internet platforms, and other direct-to-consumer models that enables reduction in friction and the number of steps in customer journeys",
    },
    {
      title: "Transformative Mega Trends",
      content:
        "Global forces that define the future world with their far-reaching impact on business, societies, economies, cultures, and personal lives",
    },
    {
      title: "Internal Challenges",
      content:
        "The internal organizational behaviors that prevent a company from making required changes",
    },
    {
      title: "Competitive Intensity",
      content:
        "A new wave of competition from start-ups and digital business models that challenge the standing conventions of the past, compelling established industries to re-think their competitive stance",
    },
    {
      title: "Geopolitical Chaos",
      content:
        "Chaos and disorder arising from political discord, natural calamities, pandemics, and social unrest that impact global trade, collaboration, and business security",
    },
    {
      title: "Disruptive Technologies",
      content:
        "New, disruptive technologies that are displacing the old, and significantly altering the way consumers, industries, or businesses operate",
    },
    {
      title: "Industry Convergence",
      content:
        "Collaboration between previously disparate industries to deliver on whitespace cross-industry growth opportunities",
    },
  ];

  return (
    <TableAndDataLayout
      title={" Exhibit 2: The Strategic Imperative 8™ Defined"}
      size={size}
      pageId={pageId}
      data={data}
    >
      <>
        {dataList.map((item: dataType) => (
          <TableAndDataRow left={item.title} right={item.content} />
        ))}
      </>
    </TableAndDataLayout>
  );
}
