import {
  useMutation,
  useQueryClient,
  type UseMutationResult,
} from "react-query";
import { toast } from "react-toastify";
import { DocumentProcessingService } from "../../services/frost-si8-services"; // Assuming service for PDF processing
import { downloadFileFromBase64 } from "../../services/downloadFileFromBase64";

interface PdfToDocxParams {
  fileName: string;
  pdfBase64: string;
}

const usePostPdftoDocx = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseMutationResult<string | undefined, unknown, any, unknown> => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ fileName, pdfBase64 }: PdfToDocxParams) => {
      const requestBody = { content: pdfBase64, fileName };
      return await handleRequest(
        DocumentProcessingService.postCoreApiApiAppDocumentProcessingConvertPdfIntoDocument(
          requestBody
        )
      );
    },
    {
      onSuccess: async (response: any) => {
        if (response) {
          downloadFileFromBase64({
            fileName: response?.data?.fileName,
            fileContent: response?.data?.content,
            fileType: "docx",
          });
        }
      },
      onError: (err: any) => {
        toast.error("An error occurred while Downloading Docx.");
        console.error("Error:", err);
      },
    }
  );
};

export default usePostPdftoDocx;
