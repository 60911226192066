import React, { useContext, useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import useFrostSi8Api from "../../hooks/useFrostSi8Api";
import useGetTopTenDataForVisualization from "../../hooks/get/useGetTopTenDataForVisualization";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import {
  FResponseDtoWithTotalCount_1OfOfFSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
  FSi8_Dtos_SI8DataRequests_SI8DataResponseDto,
  Si8DataRequestService,
} from "../../services/frost-si8-services";
import useGetSi8PagedAndSortedData from "../../hooks/get/useGetSi8PagedAndSortedData";
import HeaderBlock from "../../components/homeHeader/headerBlock";
import { TransformationPageContextType } from "../../layout/TransformationPagesLayout";
import Table from "../../components/table/table";
import { PaginationContainer } from "../../components/customPagination/pagination.styled";
import MaterialIcon from "../../components/materialIcon/materialIcon";
import { toast } from "react-toastify";
import { useGetExcelExport, usePostAddEditSi8Data } from "../../hooks";
import usePutStrategicImperativeRow from "../../hooks/put/usePutStrategicImperativeRow";
import ContainerHeader from "../../components/homeHeader/containerHeader";
import usePostStrategicImperativeAddRow from "../../hooks/post/usePostStrategicImperativeAddRow";
import { useSelector } from "react-redux";
import { selectAuthRoutesList } from "../configuration/routeSlice";
import { FilterContext } from "../../context/FilterProvider";
import { scrollToBottom } from "../../utils/scrollToBottom";
import { validation } from "../../utils/validation/validations";
import { Console } from "console";
import { Loader } from "../../components/loader/Loader";
import styled from "styled-components";

export default function TransformationTable() {
  const context: any = useContext(FilterContext);
  const { setIsExpire, isExpire, handleExpireData } = context;
  const { si8DataRequestId } = useParams();
  const { handleRequest } = useFrostSi8Api();
  const { id, updateId } = useOutletContext<TransformationPageContextType>();
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [errors, setErrors] = useState<any>({});
  const [sorting, setSorting] = useState<string>("");
  const [order, setOrder] = useState<string>("");
  const [page, setPage] = useState(1); // Current page
  const [resultsPerPage, setResultsPerPage] = useState(10); // Number of results per page
  // const [edited, setEdited] = useState<any>(); // Number of results per page
  // const [isExpire, setIsExpire] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any>();

  const [addNew, setAddNew] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [tableData, setTableData] = useState<
    FSi8_Dtos_SI8DataRequests_SI8DataResponseDto[]
  >([]);

  const queryClient = useQueryClient();

  const { data, totalCount, isLoading, refetch } = useGetSi8PagedAndSortedData(
    handleRequest,
    si8DataRequestId as string,
    searchText,
    order,
    sorting,
    page && resultsPerPage && (page - 1) * resultsPerPage,
    resultsPerPage
  );
  const { mutate: mutateUpdate } = usePutStrategicImperativeRow(handleRequest);
  const { mutate: mutateAdd } = usePostStrategicImperativeAddRow(handleRequest);

  const userAuthList = useSelector(selectAuthRoutesList);

  useEffect(() => {
    if (si8DataRequestId) {
      updateId(si8DataRequestId as string);
    }
  }, [si8DataRequestId, updateId]);

  // table data are not used directly here to implement in-table add new row operation.
  // using state to hold data from api
  useEffect(() => {
    if (data?.si8DataResults) setTableData(data?.si8DataResults);
  }, [data?.si8DataResults]);

  // fetch table data when changes in page number , search text , order, sorting
  useEffect(() => {
    refetch();
    setAddNew(false); //to reset Add to Existing Table Button text in TOP
  }, [page, searchText, order, sorting, refetch]);

  // row add holder
  const rowTemplate: FSi8_Dtos_SI8DataRequests_SI8DataResponseDto = {
    id: "",
    strategicImperative: "",
    event: "",
    impactScore: 0,
    impactStartYear: 0,
    impactDuration: 0,
    durationCurve: "",
    potentialImpactOnRevenue: "",
    siDetail: "",
    sourceName: "Analyst Entry",
  };
  const resetSearch = () => {
    setPage(1);
  };

  const addRow = () => {
    const newRow = { ...rowTemplate, new: true, si8DataRequestId: "" };
    const newData = [...tableData, newRow];
    setTableData(newData);
    setAddNew(true);
    scrollToBottom();
  };

  // export table
  const handleDownloadFile = (
    fileName: string,
    fileContent?: string | null
  ): void => {
    const aElement = document.createElement("a");
    const downloadLink =
      `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,` +
      fileContent;
    aElement.setAttribute("download", fileName);
    aElement.setAttribute("href", downloadLink);
    aElement.click();
  };

  const { mutate: excelExport } = useGetExcelExport(
    handleRequest,
    handleDownloadFile
  );

  //expire and regenerate
  // TODO

  // add row
  const handleRowAdd = async (item: any) => {
    //save to existing table btn logic
    const validationErrors = validation(rowData, setErrors);
    if (Object.keys(validationErrors).length === 0 && rowData !== undefined) {
      // console.log("ADDED FROM TOP Button");
    } else {
      return toast.error("Please Fill All required fields");
    }

    // normal api call to add
    await mutateAdd(
      { ...item, si8DataRequestId: id },
      {
        onSuccess: async (res) => {
          //delete the dummy row and replace it with response row
          const oldTableRow = tableData;
          oldTableRow.pop();
          oldTableRow.push(res as FSi8_Dtos_SI8DataRequests_SI8DataResponseDto);
          setTableData(oldTableRow);
          setAddNew(false);
          await queryClient.invalidateQueries("topTenData");
        },
      }
    );
  };

  // update row
  const handleRowUpdate = async (rowId: string, item: any) => {
    // console.log("Row update clicked", rowId, item);
    await mutateUpdate(
      { ...item, id: rowId, si8DataRequestId: id },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries("topTenData");
        },
      }
    );
  };

  // delete row
  const handleRowDelete = async (rowId: string) => {
    // console.log("Row delete clicked", rowId);
    handleRequest(
      Si8DataRequestService?.deleteCoreApiApiAppSI8DataRequestSI8Data(rowId)
    )
      .then(async (res: any) => {
        if (res != null) {
          await queryClient.invalidateQueries("topTenData");
          const oldTableRow = tableData;
          oldTableRow.pop();
          setTableData(oldTableRow);
          refetch();
          toast.success("Successfully Deleted the item");
        }
      })
      .catch(() => {});
  };

  // handle serch
  const handleSearch = async (text: any) => {
    setPage(1);
    setSearchText(text?.trim());
  };

  // handle expire and regenerate data
  // const handleExpireAndRegenerateData = () => {
  //   console.log("Regenerate data");
  // };
  return (
    <div>
      {/* TransformationTable for {si8DataRequestId} {id}{" "}
      <div>{JSON.stringify(data?.si8DataResults?.length)}</div> */}
      {isLoading ? (
        <TransformationTableTopLoading />
      ) : (
        <ContainerHeader
          addNew={addNew}
          addRow={addRow}
          handleRowSubmit={handleRowAdd}
          setSearchKeyword={handleSearch}
          resetSearch={resetSearch}
          si8DataRequestId={id}
          excelExport={excelExport}
          userAuthList={userAuthList}
          setIsExpire={setIsExpire}
          isExpire={isExpire}
          handleExpireData={handleExpireData}
          headerData={data}
        />
      )}

      {isLoading ? (
        <>
          <TransformationTableLoading />
        </>
      ) : (
        <Table
          data={tableData}
          setData={setTableData}
          setRowData={setRowData}
          updateHandler={handleRowUpdate}
          deleteHandler={handleRowDelete}
          handleRowSubmit={handleRowAdd}
          setOrder={setOrder}
          order={order}
          sorting={sorting}
          setSorting={setSorting}
          addNew={addNew}
          setAddNew={setAddNew}
        />
      )}
      <PaginationContainer>
        {page !== 1 && (
          <button
            className="btn-prev"
            onClick={async () => {
              setPage(page - 1);
            }}
            disabled={page === 1}
          >
            <MaterialIcon
              showTooltip={false}
              className="btn-hover"
              iconName="chevron_left"
              onClick={() => {}}
              color={"#bdc1ca"}
            />
          </button>
        )}
        <div className="pagging">
          <span className="curr-item">
            {Math.ceil(totalCount / resultsPerPage) ? page : 0}
          </span>
          of
          <span className="total-item">
            {totalCount && totalCount === 0
              ? 1
              : Math.ceil(totalCount / resultsPerPage)}
          </span>
        </div>
        {Math.ceil(totalCount / resultsPerPage) !== page &&
          Math.ceil(totalCount / resultsPerPage) !== 0 && (
            <button
              className="btn-next"
              onClick={async () => {
                setPage(page + 1);
              }}
            >
              <MaterialIcon
                showTooltip={false}
                className="btn-hover"
                iconName="chevron_right"
                onClick={() => {}}
                color={"#bdc1ca"}
              />
            </button>
          )}
      </PaginationContainer>
    </div>
  );
}

const TransformationTableLoading = () => {
  return (
    <TransformationTableLoadingSkeleton className="">
      <div className="head skeleton"></div>
      {[...Array(4)].map((_, index: number) => {
        return <div className="body skeleton" key={index}></div>;
      })}
    </TransformationTableLoadingSkeleton>
  );
};

const TransformationTableTopLoading = () => {
  return (
    <TransformationTableLoadingSkeleton style={{ marginBottom: "20px" }}>
      <div className="top ">
        <div className="top-title">
          <div className="title skeleton"></div>
          <div className="title-btns">
            <div className="skeleton"></div>
            <div className="skeleton"></div>
            <div className="skeleton"></div>
          </div>
        </div>
        <div className="top-bottom">
          <div className="left">
            <div className="skeleton"></div>
            <div className="skeleton"></div>
          </div>
          <div className="right">
            <div className="r-btn skeleton"></div>
          </div>
        </div>
        <div className="generated-on-container">
          <div className="skeleton"></div>
        </div>
      </div>
    </TransformationTableLoadingSkeleton>
  );
};

const TransformationTableLoadingSkeleton = styled.div`
  background-color: #f3f4f6;
  /* padding: 12px 8px; */
  display: block;
  /* height: 40vh; */
  .head {
    background-color: #172d55;
    height: 67px;
    width: 100%;
    /* background-color: #ffffff; */
  }
  .body {
    height: 140px;
    width: 100%;
  }
  .top {
    /* height: 120px; */
    width: 100%;
    margin-bottom: 10px;
    background-color: #f3f4f6;
    padding: 16px;

    .top-title {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 10px;
      .title {
        height: 30px;
        width: 400px;
        margin-bottom: 10px;
      }
      .title-btns {
        display: flex;
        align-items: center;
        gap: 10px;
        div {
          height: 40px;
          width: 150px;
          border-radius: 8px;
          &:first-child {
            width: 270px;
          }
        }
      }
    }
    .top-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* background-color: red; */
      .left {
        display: flex;
        flex-direction: column;
        gap: 10px;
        > div {
          height: 16px;
          width: 240px;
        }
      }
      .right {
        /* margin-top: -30px; */
        .r-btn {
          height: 40px;
          width: 260px;
          border-radius: 8px;
        }
      }
    }
    .generated-on-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 12px;
      > div {
        height: 20px;
        width: 200px;
      }
    }
  }
`;
