interface DownloadFileFromBase64Type {
  fileName: string;
  fileContent?: string | null;
  fileType: "pdf" | "docx" | "ppt" | "excel";
}

export const downloadFileFromBase64 = ({
  fileName,
  fileContent,
  fileType,
}: DownloadFileFromBase64Type): void => {
  if (!fileContent) {
    console.error("No file content provided.");
    return;
  }

  const mimeTypes: { [key in typeof fileType]: string } = {
    pdf: "application/pdf",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ppt: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    excel: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };

  const mimeType = mimeTypes[fileType];
  const sanitizedFileContent = fileContent.replace(/\s/g, "");

  const downloadLink = `data:${mimeType};base64,${sanitizedFileContent}`;
  const aElement = document.createElement("a");

  aElement.setAttribute("download", fileName);
  aElement.setAttribute("href", downloadLink);
  document.body.appendChild(aElement); // Append to body for Firefox
  aElement.click();
  document.body.removeChild(aElement); // Clean up
};
