import styled from "styled-components";

interface Loader {
  color?: string;
  cover?: boolean;
}

export const CustomLoader = styled.span`
  &.loader {
    margin: 16px 0;
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #fafafa;
    background: radial-gradient(farthest-side, #ffa516 94%, #0000) top/8px 8px
        no-repeat,
      conic-gradient(#0000 30%, #ffa516);
    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(100% - 8px),
      #000 0
    );
    animation: l13 0.8s infinite linear;
  }
  @keyframes l13 {
    100% {
      transform: rotate(1turn);
    }
  }
`;

export const LoaderWrapper = styled.span`
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export const Loader = ({ color, cover = false }: Loader) => {
  return cover ? (
    <LoaderWrapper>
      <CustomLoader className="loader" color={color}></CustomLoader>
    </LoaderWrapper>
  ) : (
    <CustomLoader className="loader" color={color}></CustomLoader>
  );
};

export default Loader;
