import React, { useState } from "react";

const useDataTableSort = () => {
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [sortingColumn, setSortingColumn] = useState<string>("");

  const handleSort = (item: any, sortOrder: string) => {
    sortOrder === "asc" ? setSortOrder("desc") : setSortOrder("asc");
    setSortingColumn(item?.accessor);
  };

  return {
    handleSort,
    sortOrder,
    sortingColumn,
  };
};

export default useDataTableSort;
