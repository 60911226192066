import moment from "moment";
import Button from "../../components/button/button";
import DebounceSearch from "../../components/debounceSearch";
import Icon from "../../components/icons";
import { AiGenerateFilterType } from "../../types/common";
import HeaderBlock from "./headerBlock";
import {
  HeaderBoxWrapper,
  InfoMessageContainer,
} from "../../pages/home/home.styled";
import { setNewGenerated } from "../../store/slices/subSegmentSlice";
import { useDispatch, useSelector } from "react-redux";
import { scrollToBottom } from "../../utils/scrollToBottom";
import usePostCancelAndRegenerate from "../../hooks/post/usePostCancelAndRegenerate";
import useFrostSi8Api from "../../hooks/useFrostSi8Api";
import { useParams } from "react-router-dom";
import { RootState } from "../../store/store";
interface ContainerHeaderProps {
  postPayload?: any;
  excelExport?: any;
  addRow?: () => void;
  handleRowSubmit?: any;
  addNew?: boolean;
  rowData?: any;
  handleExpireData?: any;
  userAuthList?: any;
  setSearchKeyword?: (keyword: string) => void;
  setExportBtn?: (value: boolean) => void;
  setIsExpire?: (value: boolean) => void;
  isExpire?: boolean;
  resetSearch?: () => void;
  handleExport?: () => void;
  csvLinkRef?: any;
  exportData?: any;
  filterData?: AiGenerateFilterType;
  si8Response?: any;
  setPage?: any;
  si8DataRequestId?: string;
  headerData?: any;
}
const ContainerHeader = ({
  excelExport,
  addRow,
  addNew,
  handleRowSubmit,
  handleExpireData,
  userAuthList,
  setSearchKeyword,
  setIsExpire,
  isExpire,
  resetSearch,
  filterData,
  si8Response,
  setPage,
  si8DataRequestId,
  headerData,
}: ContainerHeaderProps) => {
  const dispatch = useDispatch();
  const si8PoolingStatus = useSelector(
    (state: RootState) => state.si8PoolingStatusSlice.poolingStatus
  );
  const { si8DataRequestId: si8RequestID } = useParams();
  const { handleRequest } = useFrostSi8Api();
  const { mutate, isLoading } = usePostCancelAndRegenerate(handleRequest);

  return (
    <>
      <HeaderBoxWrapper>
        {/* <InfoMessageContainer className="info-message">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="1024"
            height="1024"
            viewBox="0 0 1024 1024"
          >
            <title></title>
            <g id="icomoon-ignore"></g>
            <path
              fill="#ffffff"
              d="M512 96c-229.76 0-416 186.24-416 416s186.24 416 416 416 416-186.24 416-416-186.24-416-416-416zM577.248 665.056c-31.392 47.136-63.328 83.456-117.056 83.456-36.672-5.984-51.744-32.256-43.808-59.040l69.12-228.928c1.696-5.6-1.12-11.584-6.24-13.408-5.088-1.792-15.072 4.832-23.712 14.304l-41.792 50.272c-1.12-8.448-0.128-22.4-0.128-28.032 31.392-47.136 82.976-84.32 117.952-84.32 33.248 3.392 48.992 29.984 43.2 59.2l-69.6 230.048c-0.928 5.184 1.824 10.464 6.528 12.128 5.12 1.792 15.872-4.832 24.544-14.304l41.76-50.24c1.12 8.448-0.768 23.232-0.768 28.864zM567.936 366.048c-26.432 0-47.872-19.264-47.872-47.616s21.44-47.584 47.872-47.584 47.872 19.264 47.872 47.584c0 28.384-21.44 47.616-47.872 47.616z"
            ></path>
          </svg>
          All data has been loaded
        </InfoMessageContainer> */}
        <div className="box-header">
          <HeaderBlock
            filterData={filterData}
            headerData={headerData?.si8DataResults?.[0]}
            title="Transformation Model | Strategic Imperative 8"
            display={true}
          />

          <div className="box-wrap">
            <form className="search-holder">
              <DebounceSearch
                placeholder="Search"
                valueSetter={setSearchKeyword}
                setPage={setPage}
                resetSearch={() => resetSearch && resetSearch()}
              />
            </form>
            {addNew === false ? (
              <button className="btn export-btn" onClick={addRow}>
                Add to Existing Table
              </button>
            ) : (
              <button
                className="btn export-btn"
                onClick={() => {
                  handleRowSubmit();
                  scrollToBottom();
                }}
              >
                Save to Existing Table
              </button>
            )}

            <div>
              <button
                className="btn export-btn"
                onClick={() => {
                  // setExportBtn && setExportBtn(true);
                  // handleExport && handleExport();

                  // excelExport(si8Response?.data?.si8DataRequestId);
                  excelExport(si8DataRequestId);
                }}
              >
                Export Table <Icon size="20" icon="new-tab" />
              </button>
            </div>
            <div className="row justify-content-between mb-3">
              <div className="d-flex justify-content-end  text-end expire-container ">
                <div className="completion-date ">
                  <span className="completion-date-title">Generated on:</span>{" "}
                  {headerData?.requestCompletedDateTime !== null
                    ? moment(headerData?.requestCompletedDateTime).format(
                        "YYYY-MM-DD"
                      )
                    : "N/A"}
                </div>
                {userAuthList?.auth?.grantedPolicies?.["SI8Data.Expire"] ===
                  true && (
                  <>
                    <Button
                      variant="primary"
                      type="button"
                      title="Expire & Re-generate"
                      onClick={() => {
                        // dispatch(setNewGenerated(false));
                        // setIsExpire && setIsExpire(true);
                        // handleExpireData();
                        mutate(si8RequestID);
                        // setPage(1);
                      }}
                      disabled={
                        si8PoolingStatus === "pooling" || isLoading
                          ? true
                          : false
                      }
                      icon="restore"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </HeaderBoxWrapper>
    </>
  );
};

export default ContainerHeader;
