import { useState, FC } from "react";
import { Table, Form, Button as AntDButton, Modal, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ActionButtons from "./ActionButtons";
import { isEmpty } from "lodash";

import useFrostSi8Api from "../../../../hooks/useFrostSi8Api";
import usePutSubSegementData from "../../../../hooks/put/usePutSubSegementData";
import usePostSubSegmentData from "../../../../hooks/post/usePostSubSegmentData";
import useDeleteSubsegment from "../../../../hooks/delete/useDeleteSubSegment";
import SubSegmentEditableCell from "./ SubSegmentEditableCell";
import { PopupBox } from "../../../../components/table/table.styled";
import Button from "../../../../components/button/button";
import { toast } from "react-toastify";
import CustomModal from "../../../../components/modal";
import { FSi8_Dtos_Si8Segments_Si8SegmentResultDto } from "../../../../services/frost-si8-services";

interface SubSegmentProps {
  subSegmentData: FSi8_Dtos_Si8Segments_Si8SegmentResultDto[];
  setSubSegmentData: (
    data: FSi8_Dtos_Si8Segments_Si8SegmentResultDto[]
  ) => void;
  segmentRequestId: string;
}

const SubSegmentTable: FC<SubSegmentProps> = ({
  subSegmentData,
  setSubSegmentData,
  segmentRequestId,
}) => {
  const [dataSource, setDataSource] = useState(subSegmentData ?? []);
  const [editingKey, setEditingKey] = useState<string>("");
  const [isNewRow, setIsNewRow] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [saveRecordId, setSaveRecordId] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [isToastOpen, setIsToastOpen] = useState(false);

  const { handleRequest } = useFrostSi8Api();
  const { mutate: saveSubSegmentRow, isLoading: saveLoading } =
    usePutSubSegementData(handleRequest);
  const { mutate: addNewRow, isLoading } = usePostSubSegmentData(handleRequest);
  const { mutate: DeleteSubSegmentRow } = useDeleteSubsegment(handleRequest);

  const isEditing = (record: FSi8_Dtos_Si8Segments_Si8SegmentResultDto) =>
    record.subSegmentDataId === editingKey;

  const edit = (record: FSi8_Dtos_Si8Segments_Si8SegmentResultDto) => {
    if (isNewRow) {
      const updatedData = dataSource.filter(
        (item) => item.subSegmentDataId !== editingKey
      );
      setDataSource(updatedData);
      setSubSegmentData(updatedData);
    }
    const manupulatedData = {
      ...record,
      immediacy: record.immediacy != null ? record.immediacy * 2 : null,
      magnitude: record.magnitude != null ? record.magnitude * 2 : null,
    };
    form.setFieldsValue(manupulatedData);
    setEditingKey(record.subSegmentDataId ?? "");
    setIsNewRow(false);
  };

  const save = async (subSegmentDataId: string) => {
    try {
      const row = await form.validateFields();
      const currentRow = dataSource.find(
        (item) => item.subSegmentDataId === subSegmentDataId
      );
      const payload = {
        ...row,
        subSegment: isEmpty(row.subSegment)
          ? currentRow?.subSegment
          : row.subSegment,
        reasonToCreateOrUpdate: reason,
        immediacy: row.immediacy / 2,
        magnitude: row.magnitude / 2,
      };

      if (subSegmentData.some((item) => item.subSegmentDataId === editingKey)) {
        // Existing row - update
        saveSubSegmentRow([subSegmentDataId, payload], {
          onSuccess: (resp) => {
            if (resp.success) {
              const updatedData = resp.data;
              const newData = dataSource.map((item) =>
                item.subSegmentDataId === subSegmentDataId
                  ? { ...item, ...updatedData }
                  : item
              );
              setDataSource(newData);
              setSubSegmentData(newData);
              setEditingKey("");
              setReason("");
              setIsModalVisible(false);
            }
          },
          onError: (error) => {
            console.error("Failed to save row:", error);
          },
        });
      } else {
        addNewRow(
          {
            segmentRequestId: segmentRequestId,
            requestBody: payload,
          },
          {
            onSuccess: (resp) => {
              if (resp.success) {
                const newData = dataSource.map((item) =>
                  item.subSegmentDataId === subSegmentDataId
                    ? { ...item, ...resp.data }
                    : item
                );
                setDataSource(newData);
                setSubSegmentData(newData);
                setEditingKey("");
                setIsNewRow(false);
                setReason("");
                setIsModalVisible(false);
              }
            },
            onError: (error) => {
              console.error("Failed to add row:", error);
            },
          }
        );
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleAdd = () => {
    const newKey = `${Date.now()}-${dataSource.length + 1}`;
    const newRow: FSi8_Dtos_Si8Segments_Si8SegmentResultDto = {
      subSegmentDataId: newKey,
      subSegment: "",
      immediacy: null,
      magnitude: null,
    };
    setIsNewRow(true);
    setDataSource([...dataSource, newRow]);
    setEditingKey(newKey);
    form.resetFields();
  };

  const handleCancel = () => {
    if (isNewRow) {
      const updatedData = dataSource.filter(
        (item) => item.subSegmentDataId !== editingKey
      );
      setDataSource(updatedData);
      setSubSegmentData(updatedData);
      setIsNewRow(false);
    }
    setEditingKey("");
    form.resetFields();
  };

  const handleDelete = (id: string) => {
    DeleteSubSegmentRow(id, {
      onSuccess: (resp) => {
        if (resp?.success) {
          const updatedData = dataSource.filter(
            (item) => item.subSegmentDataId !== id
          );
          setDataSource(updatedData);
          setSubSegmentData(updatedData);
        }
      },
      // onError: (error) => {
      //   console.error("Failed to delete row:", error);
      // },
    });
  };

  const handleSaveClick = async (id: string) => {
    try {
      // Validate the fields before opening the modal
      await form.validateFields();

      setSaveRecordId(id);
      setIsModalVisible(true);
    } catch (errInfo) {
      console.log("Validation Failed:", errInfo);
    }
  };

  const handleModalOk = () => {
    save(saveRecordId);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setReason("");
  };

  const columns = [
    {
      title: "SNo",
      dataIndex: "key",
      key: "key",
      width: "8%",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Sub-Segment",
      dataIndex: "subSegment",
      key: "subSegment",
      editable: isNewRow ? true : false,
      render: (text: string) => text.replace(/\(.*?\)/g, "").trim(),
      // sorter: true,
    },
    {
      title: "Immediacy",
      dataIndex: "immediacy",
      key: "immediacy",
      editable: true,
      render: (text) => Number(text) * 2,
    },
    {
      title: "Magnitude of Impact",
      dataIndex: "magnitude",
      key: "magnitude",
      editable: true,
      render: (text) => Number(text) * 2,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "15%",
      render: (_: any, record: FSi8_Dtos_Si8Segments_Si8SegmentResultDto) => (
        <ActionButtons
          record={record}
          editable={isEditing(record)}
          isNewRow={isNewRow}
          onSave={handleSaveClick}
          onCancel={handleCancel}
          onEdit={edit}
          onDelete={delHandler}
          saveLoading={saveLoading}
        />
      ),
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) return col;
    return {
      ...col,
      onCell: (record: FSi8_Dtos_Si8Segments_Si8SegmentResultDto) => ({
        record,
        inputType: col.dataIndex === "key" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const delPopup = (id) => (
    <PopupBox>
      <h2>Delete</h2>
      <p>Are you sure you want to delete this item?</p>
      <div className="popup-btns">
        <Button
          variant="secondary-outline"
          size="md"
          title="Confirm"
          type="button"
          onClick={() => {
            handleDelete(id);
            toast.dismiss();
            setIsToastOpen(false);
          }}
        />
        <Button
          variant="primary"
          size="md"
          title="Cancel"
          type="button"
          onClick={() => {
            toast.dismiss();
            setIsToastOpen(false);
          }}
        />
      </div>
    </PopupBox>
  );
  const delHandler = (id) => {
    if (isToastOpen) return;

    setIsToastOpen(true);
    const notify = () =>
      toast(delPopup(id), {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        hideProgressBar: true,
        pauseOnHover: false,
        draggable: false,
        theme: "light",
        onClose: () => setIsToastOpen(false),
      });
    notify();
  };

  return (
    <>
      <Form form={form} component={false}>
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "36px" }}
        >
          <AntDButton
            onClick={handleAdd}
            type="primary"
            style={{ marginBottom: 16 }}
            icon={<PlusOutlined />}
            disabled={editingKey !== ""}
          >
            Add
          </AntDButton>
        </div>
        <Table
          components={{
            body: {
              cell: SubSegmentEditableCell,
            },
          }}
          bordered
          dataSource={dataSource}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
        />
      </Form>
      <CustomModal
        show={isModalVisible}
        onHide={handleModalCancel}
        title={`Reason `}
        onOk={handleModalOk}
        // onCancel={handleModalCancel}
        size="md"
        footerButton={
          <>
            <Button
              variant="secondary-outline"
              size="sm"
              title="Cancel"
              onClick={handleModalCancel}
              disabled={isLoading || saveLoading}
            />
            <Button
              variant="primary"
              size="sm"
              title={isNewRow ? "Add" : "Update"}
              onClick={handleModalOk}
              disabled={isLoading || saveLoading || reason.length > 250}
            />
          </>
        }
      >
        <Input.TextArea
          rows={4}
          count={{
            show: true,
            max: 250,
          }}
          placeholder={`Please enter a reason`}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        <div style={{ marginTop: "4px" }}>
          <em style={{ fontSize: "10px" }}>
            Note: This Field is not mandatory.
          </em>
        </div>
      </CustomModal>
    </>
  );
};

export default SubSegmentTable;
