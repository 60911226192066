import { Image, View, Text } from "@react-pdf/renderer";
import stripImage from "../image/strip.png";
interface StripProps {
  main?: boolean;
  secondary?: string;
  data?: any;
}

export default function StripSection({ main, secondary, data }: StripProps) {
  return (
    <View
      style={{
        width: "100vw",
        height: 200,
        alignItems: "center",
        position: "relative",
      }}
    >
      <Image src={stripImage} style={{ height: "100%", width: "100%" }} />
      <View
        style={{
          position: "absolute",
          top: 50,
          left: 0,
          height: 140,
          width: 460,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: 20,
          gap: 40,
          color: "white",
        }}
      >
        {main && (
          <View style={{ fontSize: 20, textAlign: "left" }}>
            <Text>Top 10 Strategic Imperatives in</Text>{" "}
            <Text>
              {data?.title.sector}, {data?.title.region}, {data?.title.date}
            </Text>
          </View>
        )}
        <Text style={!main ? { fontSize: 16 } : {}}>{secondary}</Text>
      </View>
    </View>
  );
}
