/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Abp_PagedResultDto_1OfOfFSi8_Dtos_SI8DataRequests_CompletedDataRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from "../models/Abp_PagedResultDto_1OfOfFSi8_Dtos_SI8DataRequests_CompletedDataRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null";
import type { FResponseDto_1OfOfFSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from "../models/FResponseDto_1OfOfFSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null";
import type { FResponseDto_1OfOfFSi8_Dtos_SI8DataRequests_SI8DataRequestWithEditedResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from "../models/FResponseDto_1OfOfFSi8_Dtos_SI8DataRequests_SI8DataRequestWithEditedResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null";
import type { FResponseDtoWithTotalCount_1OfOfFSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from "../models/FResponseDtoWithTotalCount_1OfOfFSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null";
import type { FSi8_Dtos_ResponseDtos_DeleteResponseDto } from "../models/FSi8_Dtos_ResponseDtos_DeleteResponseDto";
import type { FSi8_Dtos_ResponseDtos_UtcTestDto } from "../models/FSi8_Dtos_ResponseDtos_UtcTestDto";
import type { FSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto } from "../models/FSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto";
import type { FSi8_Dtos_SI8DataRequests_SI8DataInputDto } from "../models/FSi8_Dtos_SI8DataRequests_SI8DataInputDto";
import type { FSi8_Dtos_SI8DataRequests_SI8DataResponseDto } from "../models/FSi8_Dtos_SI8DataRequests_SI8DataResponseDto";
import type { FSi8_Dtos_SI8DataRequests_SI8DataUpdateDto } from "../models/FSi8_Dtos_SI8DataRequests_SI8DataUpdateDto";
import type { FSi8_Dtos_SI8DataRequests_Si8RequestDto } from "../models/FSi8_Dtos_SI8DataRequests_Si8RequestDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import { FSi8_Dtos_SI8DataRequests_PagedAndSortedSI8DataRequestDto } from "../models/FSi8_Dtos_SI8DataRequests_PagedAndSortedSI8DataRequestDto";

export class Si8DataRequestService {
  /**
   * @param requestBody
   * @returns FSi8_Dtos_SI8DataRequests_SI8DataResponseDto Success
   * @throws ApiError
   */
  public static postCoreApiApiAppSI8DataRequestSI8Data(
    requestBody?: FSi8_Dtos_SI8DataRequests_SI8DataInputDto
  ): CancelablePromise<FSi8_Dtos_SI8DataRequests_SI8DataResponseDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/core-api/api/app/s-i8Data-request/s-i8Data",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns FResponseDtoWithTotalCount_1OfOfFSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
   * @throws ApiError
   */
  public static postCoreApiApiAppSI8DataRequestPagedAndSortedSI8DataSearchRequest(
    requestBody?: FSi8_Dtos_SI8DataRequests_PagedAndSortedSI8DataRequestDto
  ): CancelablePromise<FResponseDtoWithTotalCount_1OfOfFSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/core-api/api/app/s-i8Data-request/paged-and-sorted-sI8Data-search-request",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns FResponseDto_1OfOfFSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
   * @throws ApiError
   */
  public static postCoreApiApiAppSI8DataRequestSi8DataSearchRequest(
    requestBody?: FSi8_Dtos_SI8DataRequests_Si8RequestDto
  ): CancelablePromise<FResponseDto_1OfOfFSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/core-api/api/app/s-i8Data-request/si8Data-search-request",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param requestId
   * @returns FResponseDto_1OfOfFSi8_Dtos_SI8DataRequests_SI8DataRequestWithEditedResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
   * @throws ApiError
   */
  public static getCoreApiApiAppSI8DataRequestSI8DataRequestStatus(
    requestId: string
  ): CancelablePromise<FResponseDto_1OfOfFSi8_Dtos_SI8DataRequests_SI8DataRequestWithEditedResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/core-api/api/app/s-i8Data-request/s-i8Data-request-status/{requestId}",
      path: {
        requestId: requestId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param requestId
   * @param searchKeyword
   * @param sortOrder
   * @param sorting
   * @param skipCount
   * @param maxResultCount
   * @returns FResponseDtoWithTotalCount_1OfOfFSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
   * @throws ApiError
   */
  public static getCoreApiApiAppSI8DataRequestPagedAndSortedSi8Data(
    requestId: string,
    searchKeyword?: string,
    sortOrder?: string,
    sorting?: string,
    skipCount?: number,
    maxResultCount?: number
  ): CancelablePromise<FResponseDtoWithTotalCount_1OfOfFSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/core-api/api/app/s-i8Data-request/paged-and-sorted-si8Data/{requestId}",
      path: {
        requestId: requestId,
      },
      query: {
        SearchKeyword: searchKeyword,
        SortOrder: sortOrder,
        Sorting: sorting,
        SkipCount: skipCount,
        MaxResultCount: maxResultCount,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns FSi8_Dtos_SI8DataRequests_SI8DataResponseDto Success
   * @throws ApiError
   */
  public static putCoreApiApiAppSI8DataRequestSI8Data(
    id: string,
    requestBody?: FSi8_Dtos_SI8DataRequests_SI8DataUpdateDto
  ): CancelablePromise<FSi8_Dtos_SI8DataRequests_SI8DataResponseDto> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/core-api/api/app/s-i8Data-request/{id}/s-i8Data",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param id
   * @returns FSi8_Dtos_ResponseDtos_DeleteResponseDto Success
   * @throws ApiError
   */
  public static deleteCoreApiApiAppSI8DataRequestSI8Data(
    id: string
  ): CancelablePromise<FSi8_Dtos_ResponseDtos_DeleteResponseDto> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/core-api/api/app/s-i8Data-request/{id}/s-i8Data",
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param id
   * @returns FSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto Success
   * @throws ApiError
   */
  public static postCoreApiApiAppSI8DataRequestExpireSI8Data(
    id: string
  ): CancelablePromise<FSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/core-api/api/app/s-i8Data-request/{id}/expire-sI8Data",
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param id
   * @returns FResponseDto_1OfOfFSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
   * @throws ApiError
   */
  public static postCoreApiApiAppSI8DataRequestCancelSI8DataRequest(
    id: string
  ): CancelablePromise<FResponseDto_1OfOfFSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/core-api/api/app/s-i8Data-request/{id}/cancel-sI8Data-request",
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param searchKeyword
   * @param sortOrder
   * @param sorting
   * @param skipCount
   * @param maxResultCount
   * @returns Abp_PagedResultDto_1OfOfFSi8_Dtos_SI8DataRequests_CompletedDataRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
   * @throws ApiError
   */
  public static getCoreApiApiAppSI8DataRequestCompletedDataRequest(
    searchKeyword?: string,
    sortOrder?: string,
    sorting?: string,
    skipCount?: number,
    maxResultCount?: number
  ): CancelablePromise<Abp_PagedResultDto_1OfOfFSi8_Dtos_SI8DataRequests_CompletedDataRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/core-api/api/app/s-i8Data-request/completed-data-request",
      query: {
        SearchKeyword: searchKeyword,
        SortOrder: sortOrder,
        Sorting: sorting,
        SkipCount: skipCount,
        MaxResultCount: maxResultCount,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param si8RequestId
   * @returns FResponseDtoWithTotalCount_1OfOfFSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
   * @throws ApiError
   */
  public static getCoreApiApiAppSI8DataRequestSi8CompletedDataById(
    si8RequestId: string
  ): CancelablePromise<FResponseDtoWithTotalCount_1OfOfFSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/core-api/api/app/s-i8Data-request/si8Completed-data-by-id/{si8RequestId}",
      path: {
        si8RequestId: si8RequestId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns FSi8_Dtos_ResponseDtos_UtcTestDto Success
   * @throws ApiError
   */
  public static getCoreApiApiAppSI8DataRequestUtcTime(): CancelablePromise<FSi8_Dtos_ResponseDtos_UtcTestDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/core-api/api/app/s-i8Data-request/utc-time",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }
}
