import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { Dropdown, DropdownButton } from "react-bootstrap";
import HeaderBlock from "../../../components/homeHeader/headerBlock";
import NoDataFound from "../../../components/no-data-found";
import {
  FSi8_Dtos_SI8DataRequests_SI8DataResponseDto,
  Si8TopRankRequestService,
} from "../../../services/frost-si8-services";
import { AiGenerateFilterType } from "../../../types/common";
import { exportAsImage, getGradient } from "../../../utils";
import {
  barClip,
  barCurvePlot,
  chartSize,
  customArrowPlugin,
  customLabelPlugin,
} from "../../../utils/chartUtils/index";
import BarChart from "./bar-chart";
import { VisualChartWrapper, VisualWrapper } from "./visual.styled";
import VisualTable from "./visualTable";
import VisualizationDetail from "./VisualizationDetail";
import { useDispatch, useSelector } from "react-redux";
import useFrostSi8Api from "../../../hooks/useFrostSi8Api";
import { RootState } from "../../../store/store";
import { removeTopTenViewDetailsStatus } from "../../../store/slices/TopTenViewDetailsSlice";
import { toast } from "react-toastify";
import usePostTopTenDetails from "../../../hooks/post/usePostTopTenDetails";
import { setPdfStrategicImperativeTable } from "../../../store/slices/PdfExportSlice";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import V1 from "../../../pdf/V1/V1";
import VisualizationPdfExport from "./VisualizationPdfExport";
import PPT from "../../../pdf/PPT/PPT";
import styled from "styled-components";
import { setVisualizationPoolingState } from "../../../store/slices/GlobalPoolingStatusSlice";
import usePostPdftoDocx from "../../../hooks/post/usePostPdftoDocx";

// import gradient from "chartjs-plugin-gradient";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.register(ChartDataLabels);
ChartJS.register(barCurvePlot);
ChartJS.register(customArrowPlugin);
ChartJS.register(chartSize);
ChartJS.register(customLabelPlugin);
ChartJS.register(barClip);
// ChartJS.register(gradient);

interface VisualizeType {
  tableData: Array<FSi8_Dtos_SI8DataRequests_SI8DataResponseDto>;
  filterData?: AiGenerateFilterType;
  setActive?: any;
  isActive?: boolean;
  lag?: number;
  toggle?: number;
  si8DataRequestId?: string;
}

const VisualizationChart = ({
  tableData,
  filterData,
  setActive,
  isActive,
  toggle,
  si8DataRequestId,
}: VisualizeType) => {
  const [chartData, setChartData] = useState<any>({ labels: [], datasets: [] });
  const [exporting, setExporting] = useState(false);
  const pdfExportSlice = useSelector(
    (state: RootState) => state.pdfExportSlice
  );

  const chartDivRef = useRef<any>(null);

  const [labelArray, visualizationRanking, checkTableData] = useMemo(() => {
    if (!tableData) return [[], []];

    const cloneTableData = structuredClone(tableData);
    const checkTableData =
      cloneTableData?.length === 1 &&
      cloneTableData[0].strategicImperative === "";

    const visualizationRanking = cloneTableData
      ?.sort((a, b) => Number(b?.impactScore) - Number(a?.impactScore))
      ?.slice(0, 10);

    // Convert the array elements to numbers
    const impactScores = visualizationRanking.map((rank) =>
      String(rank.impactScore)
    );
    // Convert the array elements to numbers
    const numbers = impactScores.map((value) => parseInt(value, 10));

    // Find the lowest value
    const lowestValue = Math.min(...numbers);

    // Round up to the nearest tens value
    const roundedUpValue = Math.floor(lowestValue / 10) * 10;

    const totalGridCount = visualizationRanking.length + 2;

    const labelArray = Array.from({ length: totalGridCount }, (_, index) => {
      return index === 0
        ? "100%"
        : totalGridCount === 3
        ? ""
        : totalGridCount - 2 === index
        ? `${roundedUpValue}%`
        : totalGridCount - 1 === index
        ? "\u2195  "
        : "";
    });

    return [labelArray, visualizationRanking, checkTableData];
  }, [tableData]);

  const options = {
    align: "right",
    indexAxis: "y",
    responsive: true,
    // Dont use animation:false here as it removes the smooth transitions too
    animation: {
      duration: 0,
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          color: "#000",
          display: true,
          text: "Ranking Based on Impact Score",
        },
        border: {
          display: true,
        },
        ticks: {
          color: "#000",
        },
        grid: {
          display: true,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },

      x: {
        offset: true,
        title: {
          color: "#000",
          display: true,
          text: "Timeline",
        },
        ticks: {
          color: "#000",

          stepSize: 5,
          title: {
            display: true,
            color: "#000",
            text: "Duration",
          },
          border: {
            display: true,
          },
          grid: {
            display: true,
            drawOnChartArea: false,
            drawTicks: true,
          },
          callback: function (value, index, values) {
            // Display "2024 (Now)" only for the first tick on the x-axis
            switch (index) {
              // case 0:
              //   return ``;
              case 0:
                return `Now`;
              case 1:
                return `5Y`;
              case 2:
                return `10Y`;
              case 3:
                return `15Y`;
              case 4:
                return `20Y`;
              // case 5:
              //   return `20Y`;

              default:
                return "";
            }
          },
        },
        min: Number(moment().format("YYYY")),
        max: Number(moment().add(20, "years").format("YYYY")),
      },
    },
    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        responsive: true,
        enabled: true,
        titleMarginBottom: 3,
        // bodyPadding: 0, // Adjust the horizontal padding
        displayColors: false,

        callbacks: {
          title: (tooltipModel) => {
            return [
              `Rank ${tooltipModel[0]?.dataIndex + 1}`,
              `Category: ${tooltipModel[0]?.raw[2]?.strategicImperative}`,
              `Strategic Imperative: ${tooltipModel[0]?.raw[2]?.event}`,
            ];
          },
          label: (tooltipItem) => {
            // Use HTML content for the tooltip label
            // return `<div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Sales: ${tooltipItem.yLabel}</div>`;
            const data = tooltipItem.raw[2];
            const lines = [
              `Financial Impact score : ${data?.potentialImpactOnRevenue}`,
              `Impact Score: ${data?.impactScore}`,
              `Impact Curve: ${data?.durationCurve}`,
              `Duration: ${data?.impactStartYear} - ${moment(
                data?.impactStartYear.toString()
              )
                .add(data?.impactDuration, "years")
                .format("YY")}`,
            ];

            return lines;
          },
        },
      },
    },
  };

  useEffect(() => {
    if (visualizationRanking) {
      setChartData({
        labels: [...labelArray, "0%"],

        datasets: [
          {
            label: "Data Set",
            data: visualizationRanking.map((item) => [
              Number(item?.impactStartYear),
              Number(item?.impactStartYear) + Number(item?.impactDuration),
              item,
            ]),
            datalabels: {
              display: false,
            },
            borderColor: "rgba(25, 90, 13, 0.5)",
            backgroundColor: (context) => {
              const chart = context.chart;
              const a = context.element;
              const { ctx, chartArea } = chart;
              if (!chartArea) {
                return;
              }

              const gradientArray = getGradient(
                a,
                ctx,
                chartArea,
                visualizationRanking
              );

              return gradientArray;
            },
          },
        ],
      });
    }
  }, [visualizationRanking, labelArray]);

  // Top 10 data on view Details`

  useEffect(() => {
    if (toggle === 5) {
      setTopTenData([]);
      setShowDetails(false);
    }
  }, [toggle]);

  const [topTenData, setTopTenData] = useState<any>([]);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { handleRequest } = useFrostSi8Api();
  const isTopTenViewDetailsPooling = useSelector(
    (state: RootState) =>
      state.topTenViewDetailsSlice.isTopTenViewDetailsPooling
  );
  const si8TopRankRequestId = useSelector(
    (state: RootState) => state.topTenViewDetailsSlice.si8TopRankRequestId
  );
  const { mutate, isLoading } = usePostTopTenDetails(handleRequest);

  const callTopTenViewDetails = () => {
    setTopTenData([]);
    setShowDetails(true);
    mutate(si8DataRequestId);
  };

  const getData = () => {
    handleRequest(
      Si8TopRankRequestService.getCoreApiApiAppSi8TopRankRequestSi8TopRankData(
        si8TopRankRequestId
      )
    )
      .then((response) => {
        if (response?.success) {
          setTopTenData(response?.data ?? []);
        } else {
          setShowDetails(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching progress:", error);
      });
  };

  useEffect(() => {
    let intervalId: any | null = null;
    let isFetching = false;

    if (isTopTenViewDetailsPooling) {
      const fetchData = () => {
        if (isFetching) return;
        isFetching = true;
        handleRequest(
          Si8TopRankRequestService.getCoreApiApiAppSi8TopRankRequestSi8TopRankDataStatus(
            si8TopRankRequestId
          )
        )
          .then((response) => {
            if (response?.data === null) {
              dispatch(removeTopTenViewDetailsStatus());
              toast.error(response?.message);
            }
            if (response && response?.data) {
              if (response?.data?.si8DataTopRankProcessStageId === 2) {
                clearInterval(intervalId);
                dispatch(removeTopTenViewDetailsStatus());
                getData();
              } else if (
                response?.data?.si8DataTopRankProcessStageId === 3 ||
                response?.data?.si8DataTopRankProcessStageId === 4
              ) {
                clearInterval(intervalId);
                dispatch(removeTopTenViewDetailsStatus());
                toast.error("Data Generation Failed");
              }
            } else {
              clearInterval(intervalId);
              dispatch(removeTopTenViewDetailsStatus());
            }
          })
          .catch((error) => {
            console.error("Error fetching progress:", error);
          })
          .finally(() => {
            isFetching = false;
          });
      };

      intervalId = setInterval(fetchData, 5000);
    }

    return () => {
      clearInterval(intervalId!);
      intervalId = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTopTenViewDetailsPooling, handleRequest, dispatch]);

  useEffect(() => {
    dispatch(setPdfStrategicImperativeTable(visualizationRanking));
  }, [visualizationRanking, dispatch]);

  return (
    <div>
      {/* <TransformationVisLoading /> */}
      {!checkTableData &&
        (visualizationRanking?.length > 0 && chartData !== undefined ? (
          <VisualWrapper>
            <div className="header-block">
              <HeaderBlock
                filterData={filterData}
                title="The Top 10 Transformation"
                display={false}
                headerData={tableData?.[0]}
              />
              <div className="exportButton">
                <DropdownButton id="dropdown-basic-button" title="Export Chart">
                  <Dropdown.Item
                    className="btn export-btn "
                    onClick={() => {
                      setActive(true);
                      setExporting(true);
                      setTimeout(() => {
                        exportAsImage(
                          chartDivRef.current,
                          "Visualization",
                          16 / 9,
                          setExporting
                        );
                      }, 500);
                    }}
                  >
                    Export As 16:9
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="btn export-btn "
                    onClick={() => {
                      setActive(true);
                      setExporting(true);
                      setTimeout(() => {
                        exportAsImage(
                          chartDivRef.current,
                          "Visualization",
                          4 / 3,
                          setExporting
                        );
                      }, 500);
                    }}
                  >
                    Export As 4:3
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>

            <VisualChartWrapper>
              <div ref={chartDivRef} className="div-image">
                <div className="chart-container">
                  <div className="chartStyle">
                    <BarChart data={chartData} options={options} />
                  </div>
                  <div className="chartTableStyle">
                    <VisualTable
                      data={visualizationRanking}
                      callTopTenViewDetails={callTopTenViewDetails}
                      topTenData={topTenData}
                      isTopTenViewDetailsPooling={isTopTenViewDetailsPooling}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
                <div className="legend-container">
                  <h4>
                    <u>Legend</u> <span> :</span>
                  </h4>
                  <p>
                    Ranking : <span className="circle"></span>
                  </p>
                  <div className="impact-container">
                    <p className="legend">Impact :</p>
                    <div className="rectangle">
                      <div className="line"></div>
                      <div className="impact-text moderate">Moderate</div>
                      <div className="impact-text high">High</div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <PPT /> */}

              {showDetails && (
                <>
                  <VisualizationPdfExport
                    data={topTenData}
                    isLoading={isLoading}
                    isTopTenViewDetailsPooling={isTopTenViewDetailsPooling}
                  />
                  <VisualizationDetail
                    data={topTenData}
                    isLoading={isLoading}
                    isTopTenViewDetailsPooling={isTopTenViewDetailsPooling}
                  />
                </>
              )}
            </VisualChartWrapper>
            {exporting && (
              <div
                style={{
                  position: "fixed",
                  bottom: 50,
                  right: 20,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  padding: 10,
                  borderRadius: 5,
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  zIndex: 9999,
                }}
              >
                Exporting...
              </div>
            )}
          </VisualWrapper>
        ) : (
          <>
            {/* <NoDataFound message={"Records unavailable for visualization"} /> */}
            <TransformationVisLoading />
          </>
        ))}
    </div>
  );
};
export default React.memo(VisualizationChart);

const TransformationVisLoading = () => {
  return (
    <TransformationVisLoadingSkeleton className="">
      <div className="top ">
        <div className="title skeleton"></div>
        <div className="top-bottom">
          <div className="left">
            <div className="skeleton"></div>
            <div className="skeleton"></div>
          </div>
          <div className="right">
            <div className="r-btn skeleton"></div>
          </div>
        </div>
      </div>
      <div className="middle">
        {" "}
        <div className="left skeleton"></div>
        <div className="right ">
          <div className="right-top">
            <div className="rt-text skeleton"></div>
            <div className="rt-btn skeleton"></div>
          </div>
          <div className="right-bottom skeleton"></div>
        </div>
      </div>
    </TransformationVisLoadingSkeleton>
  );
};

const TransformationVisLoadingSkeleton = styled.div`
  display: block;
  /* height: 40vh; */
  .top {
    /* height: 120px; */
    width: 100%;
    margin-bottom: 10px;
    background-color: #f3f4f6;
    padding: 20px;
    .title {
      height: 30px;
      width: 400px;
      margin-bottom: 10px;
    }
    .top-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* background-color: red; */
      .left {
        display: flex;
        flex-direction: column;
        gap: 10px;
        > div {
          height: 16px;
          width: 240px;
        }
      }
      .right {
        margin-top: -30px;
        .r-btn {
          height: 40px;
          width: 140px;
          border-radius: 8px;
        }
      }
    }
  }
  .middle {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    background-color: #f3f4f6;
    padding: 20px;
    border-radius: 16px;
    > div {
      width: 100%;
      height: 650px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .right-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .rt-text {
          height: 20px;
          width: 50%;
        }
        .rt-btn {
          width: 150px;
          height: 35px;
          border-radius: 8px;
        }
      }
      .right-bottom {
        flex: 1;
        height: 100%;
        width: 100%;
      }
    }
  }
`;
