import { Si8DataRequestService } from "../../services/frost-si8-services";
import { useQuery, useQueryClient } from "react-query";

const useGetSi8PagedAndSortedData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: string,
  searchKeyword?: string,
  sortOrder?: string,
  sorting?: string,
  skipCount?: number,
  maxResultCount?: number
) => {
  const queryClient = useQueryClient();
  const { data, error, isLoading, refetch } = useQuery(
    ["si8-paged-and-sorted-data", id],
    async () => {
      return await handleRequest(
        Si8DataRequestService.getCoreApiApiAppSI8DataRequestPagedAndSortedSi8Data(
          id,
          searchKeyword || undefined,
          sortOrder || undefined,
          sorting || undefined,
          skipCount || undefined,
          maxResultCount || undefined
        )
      );
    },
    {
      enabled: !!id,
    }
  );
  // console.log(data);

  return {
    data: data?.data ?? null,
    totalCount: data?.totalCount || 0,
    error,
    isLoading,
    refetch,
  };
};

export default useGetSi8PagedAndSortedData;
