import React from "react";
import { PdfPageProps } from "../types";
import PdfPageLayout from "../Layouts/PdfPageLayout";
import BlueBgTitle from "../components/BlueBgTitle";
import { Link, Text } from "@react-pdf/renderer";

export default function LegalDisclaimerPage({
  size,
  pageId,
  data,
}: PdfPageProps) {
  return (
    <PdfPageLayout data={data} size={size} pageId={pageId}>
      <>
        <BlueBgTitle text="Legal Disclaimer" />
        <Text style={{ margin: "10px 0" }}>
          Frost & Sullivan is not responsible for any incorrect information
          supplied to us by manufacturers or users. Quantitative market
          information is based primarily on interviews and therefore is subject
          to fluctuation. Frost & Sullivan research services are limited
          publications containing valuable market information provided to a
          select group of customers. Our customers acknowledge, when ordering or
          downloading, that Frost & Sullivan research services are for
          customers' internal use and not for general publication or disclosure
          to third parties. No part of this research service may be given, lent,
          resold or disclosed to noncustomers without written permission.
          Furthermore, no part may be reproduced, stored in a retrieval system,
          or transmitted in any form or by any means, electronic, mechanical,
          photocopying, recording or otherwise, without the permission of the
          publisher.
        </Text>
        <Text>
          For information regarding permission, write to:{" "}
          <Link src="mailto:permission@frost.com" style={{ color: "#1582C5" }}>
            permission@frost.com
          </Link>
        </Text>
      </>
    </PdfPageLayout>
  );
}
