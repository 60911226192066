import styled from "styled-components";
import { colors } from "../../../constants/themeConstants";
import { Colors } from "chart.js";
import { colorAdjust } from "../../../utils/colorAdjust";

export const VisualWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .header-block {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
  }

  .exportButton {
    display: flex;
    align-items: end;
    justify-content: end;
    background-color: #fff;
    /* > .btn-primary,
    #dropdown-basic-button {
      background-color: #172d55;
    } */
    #dropdown-basic-button {
      background-color: #172d55;
    }
    .export-btn {
      background: white !important;
      border-radius: 0px;
      /* color: black; */
      color: #172d55 !important;
      padding: 8px;
      &:hover {
        background: #24457e !important;
        color: #fff !important;
      }
    }
  }
`;
export const VisualTableContainer = styled.div`
  .text-top {
    margin-bottom: 12px;
    span {
      font-size: 20px;
      font-weight: 900;
      color: #0e4c8b /* #b105b1 */;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
    flex-wrap: wrap;
  }
  .text-bottom {
    margin-top: 16px;
    font-size: 18px;
    font-weight: 900;
    color: #0e4c8b /* #b105b1 */;
  }
  .second-icon-color {
    color: grey /* #c44dff */;
  }
  .third-icon-color {
    color: gray /* #e6b3ff */;
  }
`;
export const VisualChartWrapper = styled.div`
  margin-top: 10px;
  padding: 20px;
  background-color: rgb(217, 217, 217);
  border-radius: 10px;
  zoom: 0.8;
  color: black;

  .circle {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: orange;
    border-radius: 50%;
    margin: 30px 0px 0x 10px; /* Adjust as needed */
  }

  .legend-container {
    position: relative;
    bottom: 0.5rem;
    padding-top: 10px; /* Add padding to create space at the top */
    /* background-color: red; */
  }
  .details_card_container {
    background-color: #f3f4f6;
    padding: 36px 16px;
    /* margin-bottom: 30vh; */
    border-radius: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    &:empty {
      padding: 0;
    }

    @media screen and (max-width: 1100px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 650px) {
      grid-template-columns: 1fr;
    }
    max-height: calc(100vh - 70px);
    overflow: auto;
    gap: 13px;
    .details_card--skeleton {
      padding: 12px 8px;
      height: 400px;
    }
    .details_card {
      background-color: #fff;
      padding: 12px 8px;
      /* padding: 14px 17px; */
      .details_container {
        max-height: 500px;
        overflow: auto;
        margin-bottom: 10px;
        /* background-color: red; */
      }

      //no need because we are writing inline css . below code is just for reference
      .dc-title {
        font-family: Nunito Sans;
        /* font-size: 11px; */
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: #172d55;
        margin-bottom: 3px;
      }
      .dc-content {
        ul {
          padding: 0 0 0 20px;
          li {
            font-family: Nunito Sans;
            /* font-size: 9px; */
            font-weight: 400;
            line-height: 24px;
            text-align: left;
          }
        }
      }
      .dc-bottom {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        svg {
          height: 20px;
          width: 20px;
        }
        /* background-color: green; */
      }
      .copy-btn {
        all: unset;
        cursor: pointer !important;
        padding: 5px 9px;
        color: #de7a22;
        background-color: #fcf2e9;
        display: flex;
        align-items: center;
        justify-content: center;
        /* font-size: 9px; */
        line-height: 19px;
        font-weight: 700;
        gap: 7px;
        border-radius: 5px;
      }
    }
  }

  .impact-container {
    position: sticky;
    top: 0;
    width: 50%;
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  .rectangle {
    width: 90%;
    height: 35px;
    background: linear-gradient(
      to right,
      rgba(219, 220, 222, 1),
      rgba(233, 254, 255, 1),
      rgba(155, 217, 254, 1),
      rgba(109, 198, 254, 1),
      rgba(72, 183, 255, 1),
      rgba(33, 169, 255, 1),
      rgba(17, 119, 255, 1)
    );
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-top: -20px;
  }

  .line {
    width: 100%; /* Make the line width 100% of the rectangle */
    max-width: calc(
      100% - 5px
    ); /* Ensure the line does not exceed the rectangle */
    height: 4px;
    background-color: #111c7b;
    position: absolute;
    top: 50%;
    left: 5px; /* Position the line inside the rectangle */
    right: 5px; /* Ensure the line does not exceed the rectangle */
    /* transform: translateY(-50%) rotate(178deg); */
    transform: rotate(-1.9deg) translateY(10px);
    transform-origin: left bottom;
  }

  .impact-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 25px; /* Adjust font size as needed */
    font-weight: bold;
  }

  .moderate {
    left: 5px;
    /* font-weight: bolder; */
  }

  .high {
    right: 10px;
    color: white;
    /* font-weight: "bolder"; */
  }

  .legend {
    display: inline-block;
    vertical-align: middle;
  }
  .div-image {
    background-color: rgb(217, 217, 217);
  }
  .chart-container {
    display: grid;
    padding-top: 20px;
    /* justify-content: space-between; */
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    position: relative;
    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
  }

  .instruction {
    display: flex;
    padding-top: 10px;
    text-align: justify;
    line-height: 1.4;
    font-size: 14px;

    &-title {
      text-decoration: underline;
      font-weight: bold;
    }
  }

  .chartStyle {
    /* width: 50%; */
    width: 100%;
    zoom: 1.2;
    height: 100%;
    padding: 20px 10px 20px 0px;
    border-right: 1px solid #969696;
    background-color: #fff;
  }

  .chartTableStyle {
    /* width: 50%; */

    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .rdt_TableBody {
      max-height: 350px;
      @media screen and (min-width: 1800px) {
        max-height: 585px;
      }
      display: block !important;
      overflow: auto;
      border-bottom: 1px solid black !important;

      :last-child {
        border-bottom: none;
      }
    }

    .rdt_TableCell div:first-child {
      white-space: normal !important;
    }

    .visual-header {
      padding: 0;
      font-size: 12px;
      text-align: center;
      white-space: normal;
      word-break: break-word;
    }
  }

  .table-block {
    display: flex;
    flex-direction: column;
    white-space: normal !important;
    overflow: auto;
    .title {
      word-wrap: normal;
      font-weight: bolder;
    }
    .label {
      word-wrap: normal;
    }
  }

  .no-data-container {
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 20px;
  }

  .info-icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: #f0f0f0;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  .info-icon::before {
    content: "ℹ️";
    font-size: 24px;
    line-height: 50px;
    text-align: center;
    color: #333;
  }

  .no-data-message {
    font-size: 18px;
    color: #555;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    .chartStyle {
      width: 100%;
      height: 100%;
    }

    .chartTableStyle {
      width: 100%;
      overflow: auto;
    }
  }
`;
