import React, { useContext, useEffect } from "react";
import { Visualization } from "../home/visualization";
import { useOutletContext, useParams } from "react-router-dom";
import useFrostSi8Api from "../../hooks/useFrostSi8Api";
import useGetTopTenDataForVisualization from "../../hooks/get/useGetTopTenDataForVisualization";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { TransformationPageContextType } from "../../layout/TransformationPagesLayout";
import { FilterContext } from "../../context/FilterProvider";
import useLocalStorage from "../../hooks/customHooks/useLocalStorage";
import { VisualizationLocalStorageType } from "../../types/localStorageTypes";

export default function TransformationVisualization() {
  const { si8DataRequestId } = useParams();
  const { handleRequest } = useFrostSi8Api();
  const { id, updateId } = useOutletContext<TransformationPageContextType>();
  const { setActive } = useContext<any>(FilterContext);
  const [visualizationStorage, setVisualizationStorage] =
    useLocalStorage<VisualizationLocalStorageType>("visualizationStorage", {
      isPooling: false,
      si8RequestId: "",
      data: [],
    });
  // useEffect(() => {
  //   console.log(visualizationStorage);
  //   setVisualizationStorage((prev: VisualizationLocalStorageType) => {
  //     return { ...prev, si8RequestId: id };
  //   });
  // }, [id, setVisualizationStorage, visualizationStorage]);
  // const pdfExportSlice = useSelector(
  //   (state: RootState) => state.pdfExportSlice
  // );
  const { topTenData } = useGetTopTenDataForVisualization(
    handleRequest,
    si8DataRequestId as string
  );

  useEffect(() => {
    updateId(si8DataRequestId as string);
  }, [si8DataRequestId, updateId]);

  return (
    <div>
      {/* TransformationVisualization {id} */}
      <Visualization
        setActive={setActive}
        tableData={topTenData}
        si8DataRequestId={id}
      />
    </div>
  );
}
