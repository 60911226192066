import { type UseMutationResult, useMutation } from "react-query";
import {
  FSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto,
  Si8DataRequestService,
} from "../../services/frost-si8-services";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { removeGrowthOpportunities } from "../../store/slices/GrowthSlice";
import {
  setPoolingStatus,
  setsi8RequestID,
} from "../../store/slices/SI8PoolingStatusSlice";

const usePostCancelAndRegenerate = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  setSi8DataResponse?: any
): UseMutationResult<
  FSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto | undefined,
  unknown,
  any
> => {
  const dispatch = useDispatch();
  return useMutation(
    async (si8RequestID: string) => {
      return await handleRequest(
        Si8DataRequestService.postCoreApiApiAppSI8DataRequestExpireSI8Data(
          si8RequestID
        )
      );
    },
    {
      onSuccess: async (response: any | undefined) => {
        if (response != null) {
          dispatch(setsi8RequestID(response?.si8DataRequestId as string));
          if (
            response?.si8DataProcessStageSystemName === "requested" ||
            response?.si8DataProcessStageDisplayName === "Requested"
          ) {
            dispatch(setPoolingStatus("pooling"));
          }
        }
      },
      onError: (err) => {
        console.log("error", err);
      },
    }
  );
};

export default usePostCancelAndRegenerate;
