import { Text, View } from "@react-pdf/renderer";

export default function SourceComp() {
  return (
    <View style={{ paddingTop: 4, borderTop: "1px solid black", marginTop: 4 }}>
      <Text style={{ textAlign: "right", fontSize: 8 }}>
        Source: Frost & Sullivan
      </Text>
    </View>
  );
}
