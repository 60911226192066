import { PdfPageProps } from "../types";
import PdfPageLayout from "../Layouts/PdfPageLayout";
import { Image, Text } from "@react-pdf/renderer";
import TopText from "../components/TopText";
import SourceComp from "../components/SourceComp";
import chartImage from "../image/chartImage.png";
import { textCapitalize } from "../../../utils/textCapitalize";

export default function ChartPage({ size, pageId, data }: PdfPageProps) {
  return (
    <PdfPageLayout data={data} size={size} pageId={pageId}>
      <>
        <Text
          style={{ color: "#172D55", fontWeight: "bold", marginBottom: 20 }}
        >
          The x-axis represents the timeline while the y-axis represents the
          ranking by magnitude of impact. The color gradient indicates the
          impact curve changing over time between moderate and high.
        </Text>
        <TopText
          type="seconday"
          text={`Exhibit 4: Top Transformations Impacting Growth in ${textCapitalize(
            data?.title.sector
          )}, ${data?.title.region}, ${data?.title.date}`}
        />
        {/* <Image
          src={chartImage}
          style={{ margin: "10px auto", width: "100%" }}
        /> */}
        <Image
          src={data?.chartImage}
          style={{ margin: "10px auto", width: "100%" }}
        />
        <SourceComp />
      </>
    </PdfPageLayout>
  );
}
