import { FC, useEffect, useRef, useState } from "react";
import { InputContainer } from "./search.styled";

interface SearchProps {
  placeholder: string;
  valueSetter: any;
  resetSearch?: () => void;
  setSkipCount?: (val: number) => void;
  setPage?: (val: number) => void;

}

const DebounceSearch: FC<SearchProps> = ({
  placeholder,
  valueSetter,
  resetSearch,
  setSkipCount,
  setPage,
}) => {
  const [showReset, setShowReset] = useState<boolean>(false);
  const inputRef = useRef<any>(null);

  const handleSearch = (e: any) => {
    if (e?.target?.value?.length) {
      setShowReset(true);
      setSkipCount && setSkipCount(0)
      setPage && setPage(1)
    } else {
      setShowReset(false);
    }
    const handler = setTimeout(() => {
      valueSetter(e?.target?.value);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  };

  const handleReset = () => {
    valueSetter("");
    inputRef.current.value = "";
    setShowReset(false);
    resetSearch && resetSearch();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  return (
    <InputContainer>
      <input
        ref={inputRef}
        placeholder={placeholder}
        className="form-control"
        onChange={handleSearch}
      />
      {showReset && (
        <i className="material-icons reset" onClick={handleReset}>
          cancel
        </i>
      )}
    </InputContainer>
  );
};

export default DebounceSearch;
