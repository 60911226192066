import { Text, View } from "@react-pdf/renderer";

interface TopTitleProps {
  text: string;
  type?: "primary" | "seconday";
  id?: string;
}

export default function TopText({ text, type, id }: TopTitleProps) {
  return (
    <View id={id}>
      <Text
        style={{
          color: type === "seconday" ? "#1E4896" : "#1582C5",
          fontSize: type === "seconday" ? 10 : 14,
          marginBottom: 5,
        }}
      >
        {text}
      </Text>
    </View>
  );
}
