import { useMutation } from "react-query";
import { ExcelProcessingService } from "../../services/frost-si8-services";

const useGetExcelExport = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  handleDownloadFile: any
) => {
  return useMutation(
    async (id: string) => {
      return await handleRequest(
        ExcelProcessingService.getCoreApiApiAppExcelProcessingExportSi8Data(id)
      );
    },
    {
      onSuccess: async (response) => {
        if (response != null) {
          handleDownloadFile(
            `${String(response?.fileName ?? "")}`,
            response?.content
          );
        }
      },
      onError: (err) => {
        console.log("error", err);
      },
    }
  );
};

export default useGetExcelExport;
