export const headerHeight = 0.9;
export const heroSectionHeight = 1;
export const footerHeight = 0.5;

export const slideHeight = 11.69;
export const slideWidth = 8.27;

export const primaryColor = "172D55";
export const secondaryColor = "1582C5";
export const tertiaryColor = "81B3E4";

export const tableBorderColor = "81B3E4";

export const sideMargin = 0.4;

export const containerWidth = slideWidth - sideMargin * 2;
