import moment from "moment";

export const visualTableColumn = [
  {
    name: <div className="visual-header">Rank</div>,
    accessor: "strategicImperative",
    width: "12%",
    center: "true",
    selector: (row, index) => index + 1,
  },
  {
    name: <div className="visual-header">Strategic Imperative</div>,
    accessor: "strategicImperative",
    width: "39%",
    selector: (row) => (
      <div className="table-block">
        <span className="title">{row?.strategicImperative}</span>
        <span className="label">{row?.event}</span>
      </div>
    ),
  },
  {
    name: <div className="visual-header">Timeline</div>,
    accessor: "impactStartYear",
    width: "17%",
    center: "true",
    selector: (row) => {
      return `${row.impactStartYear} - ${moment(row.impactStartYear.toString())
        .add(row.impactDuration, "years")
        .format("YY")}`;
    },
  },
  {
    name: <div className="visual-header">Impact (%)</div>,
    accessor: "impactScore",
    width: "15%",
    center: "true",
    selector: (row) => row.impactScore,
  },
  {
    name: <div className="visual-header">Impact Curve</div>,
    accessor: "durationCurve",
    width: "17%",
    center: "true",

    selector: (row) => row.durationCurve,
  },
];
