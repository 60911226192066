import PptxGenJS from "pptxgenjs";
import {
  footerHeight,
  primaryColor,
  slideHeight,
  slideWidth,
} from "./SlideConstants";
import { getInchesFromPixels } from "./getInchesfromPixels";
import logo from "../../assets/pdf/logo.png";
import { SlidePageNum } from "./SlidePageNum";

export const SlideFooter = (slide: PptxGenJS.Slide, pageNo?: number) => {
  SlidePageNum(slide, pageNo);

  slide.addShape("rect", {
    x: 0,
    y: slideHeight - footerHeight,
    w: "100%", // Full slide width in inches
    h: footerHeight, // Height in inches for a 200px section
    fill: { color: "172C55" },
  });

  // Add an image to the slide
  slide.addImage({
    path: logo,
    x: 0.2,
    y: 11.3,
    w: 2.9,
    h: 0.278,
  });

  slide.addText(
    "How is your organization maximizing its future growth potential?​",
    {
      x: 3.4,
      y: 11.38,
      w: slideWidth - 3.6,
      fontSize: 12,
      h: getInchesFromPixels(12),
      color: "F26722",
    }
  );

  // Add subtitle or description text
  // slide.addText(`Page ${pageNo ?? "-"} of 24`, {
  //   x: 0.4,
  //   y: slideHeight - (footerHeight - footerHeight / 2) - 0.08,
  //   w: "100%",
  //   fontSize: 12,
  //   h: getInchesFromPixels(12),
  //   color: "FFFFFF",
  // });
};
