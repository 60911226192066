import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";

interface ChartData {
  subSegment: string;
  magnitude: number;
  immediacy: number;
}
interface SubSegmentChartProps {
  data: ChartData[];
}

const SubSegmentChart = ({ data }: SubSegmentChartProps) => {
  const [isZoomed, setIsZoomed] = useState<any>();

  const chartRef = useRef(null);
  const zoomRef = useRef<any>(null);
  const colorRef = useRef<{ [key: string]: string }>({});

  useEffect(() => {
    const baseColors = [
      "#172d55",
      "#22427d",
      "#82a2dd",
      "#1582c5",
      "#3694ab",
      "#266979",
      "#55b3c9",
      "#f26722",
      "#d34e0c",
      "#f69768",
      "#505050",
      "#86a071",
      "#ac5349",
      "#e6609a",
    ];

    const shuffleArray = (array: string[]) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    if (Object.keys(colorRef.current).length === 0) {
      shuffleArray(baseColors).forEach((color, index) => {
        colorRef.current[data[index]?.subSegment] = color;
      });
    }

    const getRandomColor = (subSegment: string) => {
      return colorRef.current[subSegment];
    };

    const { margin, width, height } = calculateDimensions();

    const svg = createSvg(width, height, margin);

    const { group1, group2 } = createGroups(svg, margin);

    drawQuadrants(group1, width, height);

    drawAxes(group2, width, height);

    drawAxisLabels(group2, width, height);

    drawDataPoints(group1, data, width, height, getRandomColor);

    drawForeignObject(group1);

    // const tooltip = createTooltip();

    const chartElement = chartRef.current;
    if (chartElement) {
      d3.select(chartElement).selectAll("svg").remove();
      d3.select(chartElement).append(() => svg.node());
      // d3.select(chartElement).append(() => tooltip.node());
    }
    const zoom = d3
      .zoom()
      .scaleExtent([1, 5])
      .translateExtent([
        [0, 0],
        [width, height],
      ])
      .extent([
        [0, 0],
        [width, height],
      ])
      .on("start", () => d3.select(chartElement).style("cursor", "grabbing"))
      .on("zoom", (event) => {
        setIsZoomed(event.transform.k !== 1 && event.transform.k !== undefined);
        zoomed(event, group1, group2, width, height);
      })
      .on("end", () => d3.select(chartElement).style("cursor", "grab"));

    svg.call(zoom);
    // .on("mouseover", () => d3.select(chartElement).style("cursor", "grab"))
    // .on("mouseout", () => d3.select(chartElement).style("cursor", "default"));
    zoomRef.current = zoom;
  }, [data, isZoomed]);

  const calculateDimensions = () => {
    const margin = { top: 60, right: 20, bottom: 80, left: 20 };
    const width = 600 - (margin.left + margin.right);
    const height = 400 - (margin.top + margin.bottom);
    return { margin, width, height };
  };

  const createSvg = (width, height, margin) => {
    const svg = d3
      .create("svg")
      .attr("width", "100vw")
      .attr("height", "140vh")
      // .attr("preserveAspectRatio", "xMidYMid meet")
      .attr(
        "viewBox",
        `-20 50 ${width + margin.left + margin.right} ${
          height + margin.top + margin.bottom - 100
        }`
      );

    // Define clipping path **outside the group element**
    svg
      .append("defs")
      .append("clipPath")
      .attr("id", "chartClip")
      .append("rect")
      .attr("x", 0) // Adjust to align with axes
      .attr("y", 0) // Adjust to align with axes
      .attr("width", width + margin.left + margin.right - 40)
      .attr("height", height + margin.top + margin.bottom - 140);

    return svg;
  };

  const createGroups = (svg, margin) => {
    // Create the first group
    const group1 = svg
      .append("g")
      .attr("transform", `translate(${margin.left} ${margin.top})`)
      .attr("clip-path", "url(#chartClip)");

    // Create the second group (optional positioning)
    const group2 = svg
      .append("g")
      .attr("transform", `translate(${margin.left} ${margin.top})`);

    return { group1, group2 };
  };

  const drawQuadrants = (group, width, height) => {
    const quadrants = group
      .append("g")
      .attr("class", "quadrants")
      .selectAll("g.quadrant")
      .data(["", "", "", ""])
      .enter()
      .append("g")
      .attr("class", "quadrant")
      .attr(
        "transform",
        (d, i) =>
          `translate(${i % 2 === 0 ? 0 : width / 2} ${i < 2 ? 0 : height / 2})`
      )
      .attr("clip-path", "url(#chartClip)");
    quadrants
      .append("rect")
      .attr("x", 0) 
      .attr("y", -3) 
      .attr("width", 280) 
      .attr("height", 133) 
      .attr("fill", (d, i) => (i === 1 ? "#1582c5" : "none")); 

    quadrants
      .append("rect")
      .attr("x", (d, i) => (i === 1 ? 5 : 0))
      .attr("y", 0)
      .attr("width", (d, i) => (i === 1 ? 270 : 280))
      .attr("height", (d, i) => (i === 1 ? 125 : 130))
      .attr("fill", (d, i) =>
        i === 2
          ? "rgba(219,227,232,1)"
          : i === 1
          ? "rgba(201,205,211,1)"
          : "rgba(165,170,180,1)"
      );
  };

  const drawAxes = (group, width, height) => {
    const immediacyScale = d3.scaleLinear().domain([11, -1]).range([0, width]);

    const magnitudeScale = d3.scaleLinear().domain([-1, 11]).range([height, 0]);

    const immediacyAxis = d3
      .axisBottom(immediacyScale)
      .tickFormat((d) => `${d}%`);
    const magnitudeOfImpactAxis = d3
      .axisLeft(magnitudeScale)
      .tickFormat((d) => `${d}%`);

    group
      .append("g")
      .attr("transform", `translate(0 ${height})`)
      .attr("class", "axis axis-immediacy")
      .call(immediacyAxis);

    group
      .append("g")
      .attr("class", "axis axis-magnitudeOfImpact")
      .call(magnitudeOfImpactAxis);
    group.selectAll("path").remove(); 
    group.selectAll(".axis-immediacy").selectAll(".tick").remove();
    group.selectAll(".axis-magnitudeOfImpact").selectAll(".tick").remove();
  };

  const drawForeignObject = (group) => {
    if (isZoomed) {
      const button = group
        .append("g")
        .attr("class", "reset-zoom-button visible")
        .attr("cursor", "pointer")
        .on("click", resetZoom);

      button
        .append("rect")
        .attr("x", 525)
        .attr("y", 230)
        .attr("width", 25)
        .attr("height", 25)
        .attr("fill", "#172d55")
        .attr("rx", 5); 
      button
        .append("foreignObject")
        .attr("x", 529) 
        .attr("y", 230) 
        .attr("width", 25)
        .attr("height", 25)
        .append("xhtml:div").html(`
          <svg width="100%" height="100%" viewBox="0 0 30 21" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" transform="translate(3 1)">
              <path d="m.5 7.5c0 2.7614237 2.23857625 5 5 5 2.76142375 0 5-2.2385763 5-5 0-2.76142375-2.23857625-5-5-5-1.60217594 0-3.02834512.75357449-3.94340319 1.92561913"/>
              <path d="m1.5.5v4h4"/>
              <path d="m14.5 16.5-5.379-5.379"/>
            </g>
          </svg>
        `);
    }
  };

  const drawAxisLabels = (group, width, height) => {
    group
      .selectAll(".axis-immediacy")
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", width)
      .attr("height", 5)
      .attr("fill", "rgba(0,32,96,1)");

    group
      .selectAll(".axis-immediacy")
      .append("rect")
      .attr("x", 0)
      .attr("y", -260)
      .attr("width", width)
      .attr("height", 5)
      .attr("fill", "rgba(0,32,96,1)");

    group
      .selectAll(".axis-immediacy")
      .append("text")
      .text("Immediacy")
      .attr("x", 240)
      .attr("y", 17)
      .attr("text-anchor", "start")
      .attr("dominant-baseline", "hanging")
      .style("font-size", "15px")
      .attr("fill", "black");

    group
      .append("text")
      .text("20 Years")
      .attr("x", 35)
      .attr("y", height + 25)
      .attr("text-anchor", "middle")
      .style("font-size", "10px")
      .attr("fill", "black");

    group
      .append("text")
      .text("Now")
      .attr("x", width - 30)
      .attr("y", height + 25)
      .attr("text-anchor", "middle")
      .style("font-size", "10px")
      .attr("fill", "black");

    group
      .selectAll(".axis-magnitudeOfImpact")
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 5)
      .attr("height", height)
      .attr("fill", "rgba(0,32,96,1)");
    group
      .selectAll(".axis-magnitudeOfImpact")
      .append("rect")
      .attr("x", 555)
      .attr("y", 0)
      .attr("width", 5)
      .attr("height", height)
      .attr("fill", "rgba(0,32,96,1)");

    group
      .selectAll(".axis-magnitudeOfImpact")
      .append("text")
      .text("Magnitude of impact")
      .attr("x", -60)
      .attr("y", -25)
      .attr("text-anchor", "end")
      .attr("dominant-baseline", "hanging")
      .style("font-size", "15px")
      .attr("fill", "black")
      .attr("transform", "rotate(-90)");
    group
      .append("text")
      .text("Low")
      .attr("x", -240)
      .attr("y", height - 275)
      .attr("text-anchor", "middle")
      .style("font-size", "10px")
      .attr("fill", "black")
      .attr("transform", "rotate(-90)");

    group
      .append("text")
      .text("High")
      .attr("x", -25)
      .attr("y", height - 275)
      .attr("text-anchor", "middle")
      .style("font-size", "10px")
      .attr("fill", "black")
      .attr("transform", "rotate(-90)");
  };

  const drawDataPoints = (
    group,
    segmentData,
    width,
    height,
    getRandomColor
  ) => {
    const immediacyScale = d3.scaleLinear().domain([11, -1]).range([0, width]);

    const magnitudeScale = d3.scaleLinear().domain([-1, 11]).range([height, 0]);

    const dataPointsGroup = group
      .append("g")
      .attr("class", "data")
      .selectAll("g.data-point")
      .data(segmentData)
      .enter()
      .append("g")
      .attr("class", "data-point")
      .attr(
        "transform",
        (d) =>
          `translate(${immediacyScale(d.immediacy)}, ${magnitudeScale(
            d.magnitude
          )})`
      );
    // .on("mouseover", showTooltip)
    // .on("mouseout", hideTooltip);

    dataPointsGroup
      .append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 5)
      .attr("fill", (d) => getRandomColor(d.subSegment));

    dataPointsGroup
      .append("text")
      .attr("x", 0)
      .attr("y", 10) 
      .attr("class", "name")
      .text(({ subSegment }) => `${subSegment.replace(/ *\([^)]*\) */g, "")}`) 
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "hanging") 
      .style("font-size", "0.55rem")
      .style("letter-spacing", "0.05rem")
      .style("pointer-events", "none");
  };

  // const createTooltip = () => {
  //   return d3
  //     .create("div")
  //     .attr("class", "tooltip")
  //     .style("opacity", 0)
  //     .style("position", "absolute")
  //     .style("background-color", "white")
  //     .style("border", "solid")
  //     .style("border-width", "1px")
  //     .style("border-radius", "5px")
  //     .style("padding", "10px")
  //     .style("pointer-events", "none");
  // };

  // const showTooltip = (event, d) => {
  //   const tooltip = d3.select(".tooltip");
  //   tooltip.style("opacity", 1);
  //   tooltip
  //     .html(`<strong>Name:</strong> ${d.subSegment}<br/>`)
  //     .style("left", event.pageX + "px")
  //     .style("top", event.pageY - 220 + "px")
  //     .style("width", 200 + "px");
  // };

  // const hideTooltip = () => {
  //   d3.select(".tooltip").style("opacity", 0);
  // };

  const zoomed = (event, group1, group2, width, height) => {
    const transform = event.transform;

    const newImmediacyScale = transform.rescaleX(
      d3.scaleLinear().domain([11, -1]).range([0, width])
    );
    const newMagnitudeScale = transform.rescaleY(
      d3.scaleLinear().domain([-1, 11]).range([height, 0])
    );

    const immediacyAxis = d3
      .axisBottom(newImmediacyScale)
      .tickFormat((d) => `${d}%`);
    const magnitudeOfImpactAxis = d3
      .axisLeft(newMagnitudeScale)
      .tickFormat((d) => `${d}%`);

    
    group2.selectAll(".axis-immediacy").call(immediacyAxis);
    group2.selectAll(".axis-magnitudeOfImpact").call(magnitudeOfImpactAxis);

    group1
      .selectAll(".quadrants")
      .attr("transform", transform.toString())
      .attr("width", width * transform.k)
      .attr("height", height * transform.k);

    group1
      .selectAll(".data-point")
      .attr(
        "transform",
        (d) =>
          `translate(${newImmediacyScale(d.immediacy)}, ${newMagnitudeScale(
            d.magnitude
          )})`
      );
    group1.selectAll(".data-point circle").attr("r", (d) => 5 + transform.k);

    group2.selectAll("path").remove();
    group2.selectAll(".axis-immediacy").selectAll(".tick").remove();
    group2.selectAll(".axis-magnitudeOfImpact").selectAll(".tick").remove();
  };

  const resetZoom = () => {
    if (zoomRef.current) {
      const chartElement = chartRef.current;
      if (chartElement) {
        d3.select(chartElement)
          .select("svg")
          .transition()
          .duration(100)
          .call(zoomRef.current.transform, d3.zoomIdentity);
        setIsZoomed(false);
      }
    }
  };

  return <div className="sub-segment-chart" ref={chartRef}></div>;
};

export default SubSegmentChart;
