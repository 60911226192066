import PptxGenJS from "pptxgenjs";
import { headerHeight, primaryColor, slideWidth } from "./SlideConstants";
import { getInchesFromPixels } from "./getInchesfromPixels";
import { SlideHeroSection } from "./SlideHeroSection";
import { textCapitalize } from "../../../utils/textCapitalize";

export const SlideHeader = (slide: PptxGenJS.Slide, data?: any) => {
  slide.addShape("rect", {
    x: 0,
    y: 0,
    w: "100%", // Full slide width in inches
    h: headerHeight, // Height in inches for a 200px section
    fill: { color: primaryColor }, // Hex color without '#'
  });
  // // Add title text
  // slide.addText("test", {
  //   x: 1,
  //   y: 0.5,
  //   fontSize: 24,
  //   bold: true,
  //   color: "FFFFFF", // White text
  //   align: "center",
  // });

  // Add subtitle or description text
  slide.addText(
    `Top 10 Strategic Imperatives in ${textCapitalize(data?.title.sector)}, ${
      data?.title.region
    }, ${data?.title.date}`,
    {
      x: 0.25,
      y: 0.38,
      w: slideWidth / 2 + 0.3,
      fontSize: 18,
      h: getInchesFromPixels(14),
      color: "FFFFFF",
      align: "left",
    }
  );

  slide.addText("Strategic Imperative​", {
    x: slideWidth / 2 + 0.4,
    y: 0.38,
    w: slideWidth / 2 - 0.6,
    fontSize: 18,
    h: getInchesFromPixels(14),
    color: "01B2F7",
    align: "right",
  });

  SlideHeroSection(slide);
};
