import { Text, View, StyleSheet } from "@react-pdf/renderer";
import TopText from "../components/TopText";
import PdfPageLayout from "../Layouts/PdfPageLayout";
import BlueLineTitle from "../components/BlueLineTitle";
import { PdfPageProps } from "../types";
import B from "../components/B";

// Create styles for the PDF document
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  section: {
    margin: 0,
    padding: 0,
    marginBottom: 10,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 5,
  },
  content: {
    fontSize: 12,
    marginBottom: 10,
  },
  bulletPoint: {
    marginLeft: 10,
    marginTop: 2,
    marginBottom: 4,
    fontSize: 11,
  },
  bold: {
    fontFamily: "roboto",
  },
});

// Main document component
const AxisPage = ({ size, pageId, data }: PdfPageProps) => (
  <>
    <PdfPageLayout data={data} size={size} pageId={pageId}>
      <>
        {/* Title Section */}
        <TopText
          text="Frost Radar Metrics: 2 Major Indices, 10 Analytical Ingredients, 1
          Platform"
        />

        {/* Growth Index Section */}
        <View style={styles.section}>
          <BlueLineTitle text="Vertical Axis - The Growth Index" />
          <Text style={styles.content}>
            Growth index is a measure of a company’s growth performance and
            track record, along with its ability to develop and execute a fully
            aligned growth strategy and vision; a robust growth pipeline system;
            and effective market, competitor, and end-user focused sales and
            marketing strategies.
          </Text>

          <Text style={styles.bulletPoint}>
            <B> • GI1: Market Share (previous 3 years):</B> Market share
            relative to its competitors in a given market space for the previous
            three years.
          </Text>
          <Text style={styles.bulletPoint}>
            <B>• GI2: Revenue Growth (previous 3 years):</B> Revenue growth rate
            for the previous three years in the market/industry/category that
            forms the context for the given Frost Radar.
          </Text>
          <Text style={styles.bulletPoint}>
            <B>• GI3: Growth Pipeline:</B> Evaluation of the strength and
            leverage of the company’s growth pipeline system to capture and
            prioritize growth opportunities.
          </Text>
          <Text style={styles.bulletPoint}>
            <B>• GI4: Vision and Strategy:</B> Assessment of how well a
            company’s growth strategy is aligned with its vision.
          </Text>
          <Text style={styles.bulletPoint}>
            <B>• GI5: Sales and Marketing:</B> Measure of the effectiveness of a
            company’s sales and marketing efforts in driving demand and
            achieving growth objectives.
          </Text>
        </View>

        {/* Innovation Index Section */}
        <View style={styles.section}>
          <BlueLineTitle text="Horizontal Axis – The Innovation Index" />
          <Text style={styles.content}>
            Innovation index measures a company’s ability to develop products/
            services/ solutions that are developed with a clear understanding of
            disruptive Mega Trends and evolving customer needs.
          </Text>

          <Text style={styles.bulletPoint}>
            <B> • II1: Innovation Scalability:</B> Determines whether the
            organization’s innovation(s) is/are globally scalable and applicable
            in multiple markets and verticals.
          </Text>
          <Text style={styles.bulletPoint}>
            <B>• II2: Research and Development: </B>
            Measures the efficacy of a company’s R&D strategy based on its
            investment and contribution to the innovation pipeline.
          </Text>
          <Text style={styles.bulletPoint}>
            <B>• II3: Product Portfolio:</B> Evaluates the contribution of new
            products to the company’s annual revenues.
          </Text>
          <Text style={styles.bulletPoint}>
            <B>• II4: Megatrends Leverage:</B> Assesses how a company leverages
            evolving long-term opportunities and new business models.
          </Text>
          <Text style={styles.bulletPoint}>
            <B>• II5: Customer Alignment:</B> Evaluates the applicability of a
            company’s products to current and potential customers over a 7-year
            horizon.
          </Text>
        </View>
      </>
    </PdfPageLayout>
  </>
);

export default AxisPage;
