import TableAndDataLayout from "../Layouts/TableAndDataLayout";
import TableAndDataRow from "../components/TableAndDataRow";
import { PdfPageProps } from "../types";

interface dataType {
  title: string;
  content: string;
}

export default function BestPractices({ size, pageId, data }: PdfPageProps) {
  const dataList: Array<dataType> = [
    {
      title: "Competitive Strategy",
      content:
        "Most companies appreciate the need for incorporating competitive information into decision making, but few are adept at treating it as an integral component of a long-term growth strategy.",
    },
    {
      title: "Customer Strategy",
      content:
        "The process of increasing revenue by better understanding, anticipating, and responding to customers’ changing needs. Turn this into action and see a positive return on those actions.",
    },
    {
      title: "Distribution Channel Optimization",
      content:
        "We help our clients develop and implement compelling supply chain, distribution, and retail strategies integrating digital solutions.",
    },
    {
      title: "Geographic Expansion",
      content:
        "We examine key political, economic, cultural, legal, customer, and infrastructural issues within each country, and ensure our clients leave no stone unturned when undertaking a geographic expansion effort.",
    },
    {
      title: "Mergers & Acquisitions",
      content:
        "Companies looking to expand into new markets, pursue new growth opportunities and hit aggressive targets must build Mergers & Acquisitions into their long-term growth strategies.",
    },
    {
      title: "New Product Development",
      content:
        "We understand that the path to new product development success is through the application of a rigorous, balanced process for evaluating any idea prior to entering the market.",
    },
    {
      title: "New Product Launch",
      content:
        "We have developed a new product launch process that is unbiased, repeatable, and focused on implementation success.",
    },
    {
      title: "Strategic Partnerships",
      content:
        "Strategic partnerships deliver access to new markets or customers, accelerate new product development cycles, and improve a company’s competitive positioning.",
    },
    {
      title: "Technology Strategy",
      content:
        "We are dedicated to helping our clients foster a culture of innovation and creativity within their organizations, leveraging technology growth opportunities to define strategic goals.",
    },
    {
      title: "Vertical Market Expansion",
      content:
        "Successful companies consistently look beyond their current markets for new growth opportunities. Vertical markets are a compelling path to those new revenue streams.",
    },
  ];

  return (
    <TableAndDataLayout
      size={size}
      title="Next Steps: Best Practices Implementation with the 10 Growth Processes"
      pageId={pageId}
      data={data}
    >
      <>
        {dataList.map((item: dataType) => (
          <TableAndDataRow left={item.title} right={item.content} />
        ))}
      </>
    </TableAndDataLayout>
  );
}
