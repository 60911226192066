import {
  useMutation,
  useQueryClient,
  type UseMutationResult,
} from "react-query";
import { toast } from "react-toastify";
import { Si8SegmentRequestService } from "../../services/frost-si8-services";

const usePutSubSegementData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseMutationResult<any | undefined, unknown, any, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(
    async ([id, payload]) => {
      return await handleRequest(
        Si8SegmentRequestService.putCoreApiApiAppSi8SegmentRequestSi8SegmentData(
          id,
          payload
        )
      );
    },
    {
      onSuccess: async (response) => {
        if (response != null) {
          // await queryClient.invalidateQueries("getAllUpdate");
          toast.success("Data Updated Successfully");
        }
      },
      onError: (err) => {
        console.log("error", err);
      },
    }
  );
};

export default usePutSubSegementData;
