import { PdfPageProps } from "../types";
import PdfPageLayout from "../Layouts/PdfPageLayout";
import TopText from "../components/TopText";
import { Image } from "@react-pdf/renderer";
import SourceComp from "../components/SourceComp";
import methodologyImage from "../image/methodologyImage.png";

export default function Methodology({ size, pageId, data }: PdfPageProps) {
  return (
    <PdfPageLayout data={data} size={size} pageId={pageId}>
      <>
        <TopText text="Frost & Sullivan Analytics Methodology" />

        <Image
          src={methodologyImage}
          style={{ margin: "0px auto", width: "100%" }}
        />
        <SourceComp />
      </>
    </PdfPageLayout>
  );
}
