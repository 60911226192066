import TopText from "../components/TopText";
import PdfPageLayout from "../Layouts/PdfPageLayout";
import { PdfPageProps } from "../types";
import benefitsImage from "../image/benefitsImage.png";
import tgpImage from "../image/tgpImage.png";
import { Image } from "@react-pdf/renderer";
import SourceComp from "../components/SourceComp";

export default function BenefitsPage({ size, pageId, data }: PdfPageProps) {
  return (
    <PdfPageLayout data={data} size={size} pageId={pageId}>
      <>
        <TopText text="Next Steps: Benefits & Impacts of Growth Opportunities" />
        <Image
          src={benefitsImage}
          style={{ margin: "10px auto", width: "100%" }}
        />
        <TopText text="Next Steps: The Transformational Growth Partnership" />
        <Image src={tgpImage} style={{ margin: "10px auto", width: "100%" }} />
        <SourceComp />
      </>
    </PdfPageLayout>
  );
}
