import React, { useEffect } from "react";
import { AiGenerateFilterType } from "../../types/common";
import { setPDFTitle } from "../../store/slices/PdfExportSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { textCapitalize } from "../../utils/textCapitalize";

interface IHeaderType {
  filterData?: AiGenerateFilterType;
  title: string;
  display: boolean;
  headerData?: any;
}

const HeaderBlock = ({
  filterData,
  title,
  display,
  headerData,
}: IHeaderType) => {
  const dispatch = useDispatch();
  const pdfExportSlice = useSelector(
    (state: RootState) => state.pdfExportSlice
  );

  useEffect(() => {
    dispatch(
      setPDFTitle({
        ...pdfExportSlice.title,
        market: headerData?.Industry as string,
        sector: headerData?.Sector as string,
        region: headerData?.region as string,
      })
    );
  }, [headerData, dispatch]);

  return (
    <div className="header-list">
      <h4>
        {title}
        {display && <sup style={{ fontSize: "14px" }}>TM</sup>}
      </h4>

      <div>
        <span className="title-head">Industry</span>:{" "}
        <span className="title-value">
          {textCapitalize(headerData?.Industry)}
        </span>
      </div>
      {headerData?.Sector && (
        <div>
          <span className="title-head">Sector </span>:{" "}
          <span className="title-value">
            {textCapitalize(headerData?.Sector)}
          </span>
        </div>
      )}
      {headerData?.SubSector && (
        <div>
          <span className="title-head">Sub-sector</span>:{" "}
          <span className="title-value">
            {textCapitalize(headerData?.SubSector)}
          </span>
        </div>
      )}
      {headerData?.keywordToAvoid && (
        <div>
          <span className="title-head">Keyword to Avoid</span>:{" "}
          <span className="title-value">{headerData?.keywordToAvoid}</span>
        </div>
      )}
      <div>
        <span className="title-head">Region</span>:{" "}
        <span className="title-value">{headerData?.region}</span>
      </div>
      {headerData?.company && (
        <div>
          <span className="title-head">Company</span>:{" "}
          <span className="title-value">
            {textCapitalize(headerData?.company)}
          </span>
        </div>
      )}
      {headerData?.additionalInformation && (
        <div>
          <span className="title-head">Additional Sources</span>:{" "}
          <span className="title-value">
            {headerData?.additionalInformation}
          </span>
        </div>
      )}
      {headerData?.description && (
        <div
          style={{
            width: "80%",
            maxHeight: "80px",
            overflowY: "auto",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <span className="title-head">Description </span>:{" "}
          <span
            className="title-value"
            style={{
              paddingLeft: "4px",
              wordBreak: "break-word",
              flex: 1,
              overflowY: "auto",
            }}
          >
            {headerData?.description}
          </span>
        </div>
      )}
    </div>
  );
};

export default HeaderBlock;
