import styled from "styled-components";
import { colors } from "../../constants/themeConstants";

export const TableWrapper = styled.div`
  ::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fafafa;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fafafa;
    border-radius: 7px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fafafa;
  }

  .box ::-webkit-scrollbar {
    height: 8px;
    border-radius: 4px;
  }

  .box ::-webkit-scrollbar-track {
    background: #fafafa;
    box-shadow: 0px -1px 0px 0px #f0f0f0 inset, 0px 1px 0px 0px #e8e8e8 inset;
  }

  .box ::-webkit-scrollbar-thumb {
    background: #c1d9ec;
    border-radius: 4px;
  }

  .table-holder {
    width: 100%;
    overflow: auto;
    padding-bottom: 30px;
  }

  .table-holder table {
    table-layout: fixed;
    width: 100%;
    text-align: left;
    min-width: 100%;
    min-width: 1400px;
  }

  .table th:first-child,
  .table td:first-child {
    width: 135px;
  }

  .table th:nth-child(2),
  .table td:nth-child(2) {
    width: 160px;
  }

  .table th:nth-child(3),
  .table td:nth-child(3) {
    width: 190px;
  }
  .table td:nth-child(3) textarea[disabled] {
    text-align: center;
  }

  .table th:nth-child(4),
  .table td:nth-child(4) {
    width: 85px;
  }
  .table td:nth-child(4) textarea[disabled] {
    text-align: center;
  }

  .table th:nth-child(5),
  .table td:nth-child(5) {
    width: 80px;
  }
  .table td:nth-child(5) textarea[disabled] {
    text-align: center;
  }

  .table th:nth-child(6),
  .table td:nth-child(6) {
    width: 60px;
  }
  .table td:nth-child(6) textarea[disabled] {
    text-align: center;
  }

  .table th:nth-child(7),
  .table td:nth-child(7) {
    width: 75px;
  }

  .table td:nth-child(7) textarea[disabled] {
    text-align: center;
  }

  .table th:nth-child(8),
  .table td:nth-child(8) {
    width: 75px;
  }
  .table td:nth-child(8) textarea[disabled] {
    text-align: center;
  }

  .table th:nth-child(9),
  .table td:nth-child(9) {
    width: 50px;
  }

  .table th:nth-child(10),
  .table td:nth-child(10) {
    width: 100px;
    // vertical-align: middle;
  }
  .table td .table-btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    padding: 10px 0;
  }

  .table-btns material-icons button {
    border: none;
    font-size: 14px;
    padding: 8px 10px;
    line-height: 1.3;
  }
  .table-btns material-icons button:hover {
    background-color: #172d55;
    color: #fff;
  }

  table {
    border-collapse: collapse;
    // border-left: 1px solid #172d55;
    // border-bottom: 1px solid #172d55;
    min-height: 100px;
  }

  .table-holder table tbody {
    display: block;
    // height: 500px;
    // max-height: 500px;
    // overflow: auto;
  }

  table thead,
  table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-color: white;
  }

  .table-holder table th {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
    font-family: "Nunito Sans";
    padding: 4px 2px 8px;

    background: #172d55;
    color: #fff;
    text-align: center;
  }

  .table-holder th button {
    background: none;
    border: none;
    padding: 0;
    color: #fff;
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
  }

  .table-holder th svg {
    display: inline-block;
    vertical-align: middle;
    margin-left: 2px;
  }

  .table-holder th path {
    fill: #fff;
  }
  .table-holder--noborder table th {
    border: none;
  }

  .table-holder table td {
    font-family: "Nunito Sans";
    font-size: 16px;
    line-height: 1.3;
    color: #000;
    background-color: #f3f4f6;
    vertical-align: top;
    // border-left: 1px solid #172d55;
    // border-bottom: 1px solid #172d55;
    padding: 16px 5px 16px;
  }

  .table-holder table textarea {
    display: block;
    font-family: "Nunito Sans";
    font-size: 13px;
    width: 100%;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    border: none;
    resize: none;
    background: none;
    overflow: hidden;
    color: #172d55;
    border: 1px solid #333 !important;
    padding: 8px 3px 8px;
    border-radius: 5px;
  }

  .table-holder table textarea[disabled] {
    color: #000;
    border-color: transparent !important;
    opacity: 1;
    height: auto !important;
  }

  // .table-holder tr td:last-child,
  // .table-holder tr th:last-child {
  //   border-right: 1px solid #172d55;
  // }

  .table-holder td div {
    // margin-bottom: 7px;
  }

  .table-holder table td a:not([class]) {
    text-decoration: none;
    color: #3a81bf;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .table-holder--noborder tbody {
    counter-reset: counter;
  }

  .table-holder--noborder tr {
    counter-increment: counter;
  }

  .table-holder--noborder td:first-child div {
    position: relative;
    padding-left: 30px;
  }

  .table-holder--noborder td:first-child .item_num {
    color: #00b0f0;
    position: absolute;
    width: 20px;
    text-align: right;
    left: 0;
    top: 0;
  }

  .table-holder th .up path:first-child {
    fill: #f28e36;
  }
  .table-holder th .down path:last-child {
    fill: #f28e36;
  }

  .no-data {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
  }

  @media (min-width: 768px) {
    .box {
      background: #fff;
      // border-radius: 8px;
      box-shadow: 0px 0px 40px 0px rgba(21, 30, 40, 0.08);
      padding: 5px 0px 10px;
      position: relative;
    }
  }
  svg {
    display: inline-block;
    stroke: currentcolor;
    fill: currentcolor;
    width: 25px;
    height: 20px;
    display: inline-block;
    stroke: currentcolor;
    fill: currentcolor;
    width: 25px;
    height: 20px;
  }
  .chat-icon {
    position: absolute;
    bottom: 10px;
    color: #fff;
    background: linear-gradient(150deg, #172d55 0%, #0e4c8b 100%);
    padding: 5px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    width: 86%;
    box-sizing: border-box;
    cursor: pointer;
    border: none;
    outline: none;
  }
  .chat-icon:hover {
    filter: drop-shadow(0 0 0.2rem #172d55);
  }
  .btn-hover {
    margin-top: 2px;
    color: #fff;
    padding: 5px 0px;
  }
  .chat-icon:disabled {
    background: linear-gradient(150deg, #9eaebc 0%, #b0c4de 100%);
    cursor: not-allowed;
    opacity: 0.6;
    filter: none;
  }
`;

const baseStyles = `
  min-height: 20px;
  max-width: 500px;
  z-index: 99;
  box-shadow: 0px 2px 20px rgba(109, 109, 109, 0.15);
  background: #fff;
  // border-radius: 8px;
  padding: 10px;
  word-wrap: break-word;
`;

export const PreviewBox = styled.div`
  position: relative;

  .box-preview {
    ${baseStyles}
  }
`;

export const PreviewBox1 = styled.div`
  position: absolute;
  right: -49px;
  top: 70px;

  .box-preview1 {
    ${baseStyles}
    overflow-y: auto;
  }
`;

export const PreviewBox2 = styled.div`
  position: relative;

  .box-preview1 {
    ${baseStyles}
    max-height: 100px;
    overflow-y: auto;
  }
`;

export const Filters = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  .box-preview1 {
    min-height: 40px;
    max-width: 1000px;
    box-shadow: 0px 2px 20px rgba(109, 109, 109, 0.15);
    background: #fff;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px; /* Add margin bottom to create space between items */
  }

  .col-auto {
    margin-bottom: 8px; /* Add margin bottom to create space between Select components */
  }
`;

export const IconBox = styled.span`
  cursor: pointer;
`;
export const PopupBox = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    font-size: 14px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  h2 {
    margin: 0;
    font-size: 24px;
    line-height: 1.1667;
    font-weight: 700;
    color: ${colors.secondary};
  }

  .popup-btns {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
    & Button {
      margin: 5px;
    }
  }
`;

export const ErrorMessage = styled.div`
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 14px m !important;
  color: #dc3545 !important;
  margin-top: 5px;
  transition: all 0.2s linear;
`;
