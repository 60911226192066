import Button, { PrimaryButton } from "../../../components/button/button";
import CustomDataTable from "../../../components/dataTable/dataTable";
import { visualTableColumn } from "../../../constants/tableHeaders";
import { VisualTableContainer } from "./visual.styled";

const VisualTable = ({
  data,
  isTopTenViewDetailsPooling,
  topTenData,
  callTopTenViewDetails,
  isLoading,
}: any) => {
  return (
    <VisualTableContainer>
      <div className="text-top">
        <span>
          <span className="first-icon-color">▶</span>
          <span className="second-icon-color">▶</span>
          <span className="third-icon-color">▶</span> Does your team understand
          these transformations?
        </span>
        <PrimaryButton
          disabled={isTopTenViewDetailsPooling || isLoading}
          text={
            isTopTenViewDetailsPooling
              ? "Generating..."
              : topTenData?.items?.length > 0
              ? "Regenerate Details"
              : "Generate Details"
          }
          id="hide-during-photoshot"
          onClick={() => callTopTenViewDetails()}
        />
      </div>
      <CustomDataTable columns={visualTableColumn} data={data} />
      <div className="text-bottom">
        <span>
          ▶<span className="second-icon-color">▶</span>
          <span className="third-icon-color">▶</span> Does your team have a
          growth strategy around them?
        </span>
      </div>
      <span className="instruction">
        <span className="instruction-title">Instructions: </span>{" "}
        <span style={{ paddingLeft: "4px" }}>
          The graph on the left is a visual illustration not drawn to scale. The
          x-axis represents the timeline, while the y-axis represents the
          ranking by magnitude of impact. The color gradient indicates the
          impact curve changing over time between moderate and high. The table
          on the right highlights the category, timeline, impact, and curve
          associated with each imperative.
        </span>
      </span>
    </VisualTableContainer>
  );
};

export default VisualTable;
