import { PdfPageProps } from "../types";
import PdfPageLayout from "../Layouts/PdfPageLayout";
import BlueBgTitle from "../components/BlueBgTitle";
import TopText from "../components/TopText";
import { Image } from "@react-pdf/renderer";

import diagram from "../image/diagram.png";
import SourceComp from "../components/SourceComp";
import { textCapitalize } from "../../../utils/textCapitalize";

export default function DiagramPage({ size, data, pageId }: PdfPageProps) {
  return (
    <PdfPageLayout data={data} size={size} pageId={pageId}>
      <>
        <BlueBgTitle
          text={`Transformation in ${textCapitalize(data?.title?.sector)}`}
        />
        <TopText text="Why Is It Increasingly Difficult to Grow?" id="3-1" />
        <TopText
          text="Exhibit 1:The Strategic Imperative 8™: Factors Creating Pressure on Growth"
          type="seconday"
        />
        <Image src={diagram} style={{ margin: "30px auto", width: "80%" }} />
        <SourceComp />
      </>
    </PdfPageLayout>
  );
}
