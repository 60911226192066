import PptxGenJS from "pptxgenjs";
import { SlideHeader } from "../Components/SlideHeader";
import {
  headerHeight,
  heroSectionHeight,
  primaryColor,
  secondaryColor,
  sideMargin,
  slideWidth,
} from "../Components/SlideConstants";
import { SlideFooter } from "../Components/SlideFooter";
import { SlideSource } from "../Components/SlideSource";
import { getInchesFromPixels } from "../Components/getInchesfromPixels";
import { textCapitalize } from "../../../utils/textCapitalize";

export const ChartSlide = (pptx: PptxGenJS, pageNo?: number, data?: any) => {
  const slide = pptx.addSlide();
  SlideHeader(slide, data);

  slide.addText(
    `The x-axis represents the timeline while the y-axis represents the ranking by magnitude of impact. The color gradient indicates the impact curve changing over time between moderate and high`,
    {
      x: sideMargin,
      y: headerHeight + heroSectionHeight + 0.7,
      w: slideWidth - sideMargin * 2,
      fontSize: 13,
      h: getInchesFromPixels(13 * 3),
      color: primaryColor,
      bold: true,
    }
  );
  slide.addText(
    `Exhibit 4: Top Transformations Impacting Growth in ${textCapitalize(
      data?.title.sector
    )}, ${data?.title.region}, ${data?.title.date}`,
    {
      x: sideMargin,
      y: headerHeight + heroSectionHeight + 1.4,
      color: secondaryColor,
      fontSize: 12,
      w: slideWidth - sideMargin * 2,
      bold: false,
    }
  );
  const imageData = data?.chartImage;

  imageData
    ? slide.addImage({
        data: imageData,
        x: sideMargin,
        y: 4.2,
        w: slideWidth - sideMargin * 2,

        h: 5.5,
      })
    : null;
  SlideSource(slide, 10.5);

  SlideFooter(slide, pageNo);
};
