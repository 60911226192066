import PptxGenJS from "pptxgenjs";
import { SlideHeader } from "../Components/SlideHeader";
import {
  headerHeight,
  heroSectionHeight,
  sideMargin,
  tertiaryColor,
} from "../Components/SlideConstants";
import { SlideFooter } from "../Components/SlideFooter";
import { SlideSource } from "../Components/SlideSource";
import methodologyImage from "../../V1/image/methodologyImage.png";
import { getInchesFromPixels } from "../Components/getInchesfromPixels";

export const AnalyticsMethodologySlide = (
  pptx: PptxGenJS,
  pageNo?: number,
  data?: any
) => {
  const slide = pptx.addSlide();
  SlideHeader(slide, data);

  slide.addText("Frost & Sullivan Analytics Methodology", {
    x: sideMargin,
    y: headerHeight + heroSectionHeight + 0.4,
    color: tertiaryColor,
    fontSize: 16,
    h: getInchesFromPixels(16),
    bold: false,
  });

  slide.addImage({
    path: methodologyImage,
    x: sideMargin,
    y: headerHeight + heroSectionHeight + 0.6,
    w: 7.6,

    h: 6.8,
  });
  SlideSource(slide, 9.5);

  SlideFooter(slide, pageNo);
};
