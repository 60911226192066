import PptxGenJS from "pptxgenjs";
import { SlideHeader } from "../Components/SlideHeader";
import {
  headerHeight,
  heroSectionHeight,
  primaryColor,
  sideMargin,
  slideWidth,
  tableBorderColor,
} from "../Components/SlideConstants";
import { SlideFooter } from "../Components/SlideFooter";
import { SlideSource } from "../Components/SlideSource";
import { getInchesFromPixels } from "../Components/getInchesfromPixels";

export const BestPracticesSlide = (
  pptx: PptxGenJS,
  pageNo?: number,
  data?: any
) => {
  const slide = pptx.addSlide();
  SlideHeader(slide, data);

  slide.addText(
    `Next Steps: Best Practices Implementation with the 10 Growth Processes`,
    {
      x: 0.4,
      y: headerHeight + heroSectionHeight + 0.5,
      color: primaryColor,
      fontSize: 14,
      h: getInchesFromPixels(14),
      bold: true,
      w: "90%",
    }
  );

  const tableData = [
    {
      title: "Competitive Strategy",
      description:
        "Most companies appreciate the need for incorporating competitive information into decision making, but few are adept at treating it as an integral component of a long-term growth strategy.",
    },
    {
      title: "Customer Strategy",
      description:
        "The process of increasing revenue by better understanding, anticipating, and responding to customers' changing needs. Turn this into action and see a positive return on those actions.",
    },
    {
      title: "Distribution Channel Optimization",
      description:
        "We help our clients develop and implement compelling supply chain, distribution, and retail strategies integrating digital solutions.",
    },
    {
      title: "Geographic Expansion",
      description:
        "We examine key political, economic, cultural, legal, customer, and infrastructural issues within each country, and ensure our clients leave no stone unturned when undertaking a geographic expansion effort.",
    },
    {
      title: "Mergers & Acquisitions",
      description:
        "Companies looking to expand into new markets, pursue new growth opportunities and hit aggressive targets must build Mergers & Acquisitions into their long-term growth strategies.",
    },
    {
      title: "New Product Development",
      description:
        "We understand that the path to new product development success is through the application of a rigorous, balanced process for evaluating any idea prior to entering the market.",
    },
    {
      title: "New Product Launch",
      description:
        "We have developed a new product launch process that is unbiased, repeatable, and focused on implementation success.",
    },
    {
      title: "Strategic Partnerships",
      description:
        "Strategic partnerships deliver access to new markets or customers, accelerate new product development cycles, and improve a company’s competitive positioning.",
    },
    {
      title: "Technology Strategy",
      description:
        "We are dedicated to helping our clients foster a culture of innovation and creativity within their organizations, leveraging technology growth opportunities to define strategic goals.",
    },
    {
      title: "Vertical Market Expansion",
      description:
        "Successful companies consistently look beyond their current markets for new growth opportunities. Vertical markets are a compelling path to those new revenue streams.",
    },
  ];

  const columnWidths = [2, 5.5];

  // const contentRow = [
  //   {
  //     text: "New Product Development",
  //     options: {
  //       align: "right",
  //       valign: "middle",
  //       color: "ffffff",
  //       fill: { color: "1582C5" },
  //     },
  //   },
  //   {
  //     text: "We examine key political, economic, cultural, legal, customer, and infrastructural issues within each country, and ensure our clients leave no stone unturned when undertaking a geographic expansion effort.",
  //     options: { align: "left", valign: "middle" },
  //   },
  // ];
  const rows = [
    ...tableData.map((item: any) => {
      return [
        {
          text: item?.title,
          options: {
            align: "right" as PptxGenJS.HAlign,
            valign: "middle" as PptxGenJS.VAlign,
            color: "ffffff",
            fill: { color: "1582C5" },
          },
        },
        {
          text: item?.description,
          options: {
            align: "left" as PptxGenJS.HAlign,
            valign: "middle" as PptxGenJS.VAlign,
          },
        },
      ];
    }),
  ];

  slide.addTable(rows, {
    x: 0.4, // x position
    y: headerHeight + heroSectionHeight + 0.8, // y position
    w: "100%", // width
    h: "60%", // height
    border: { pt: 1, color: tableBorderColor }, // Border for the table
    fontSize: 12, // Font size for text in the table
    // fill: ["#FFFFFF"], // Background color for cells
    align: "left" as PptxGenJS.HAlign, // Center align text in cells
    colW: columnWidths,
  });

  SlideSource(slide, 10.5);

  SlideFooter(slide, pageNo);
};
