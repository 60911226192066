import React, { useContext, useEffect, useState } from "react";
import HistoryModal from "../home/historyModal";
import { useNavigate, useOutletContext } from "react-router-dom";
import { TransformationPageContextType } from "../../layout/TransformationPagesLayout";

export default function TransformationHome() {
  const { id, updateId } = useOutletContext<TransformationPageContextType>();

  useEffect(() => {
    updateId("");
  }, []);

  return (
    <div>
      <HistoryModal />
    </div>
  );
}
