import PptxGenJS from "pptxgenjs";
import { SlideHeader } from "../Components/SlideHeader";
import {
  headerHeight,
  heroSectionHeight,
  secondaryColor,
} from "../Components/SlideConstants";
import { SlideFooter } from "../Components/SlideFooter";
import { SlideSource } from "../Components/SlideSource";
import { getInchesFromPixels } from "../Components/getInchesfromPixels";
import { textCapitalize } from "../../../utils/textCapitalize";

export const StrategicImperativeTableSlide = (
  pptx: PptxGenJS,
  pageNo?: number,
  data?: any
) => {
  const slide = pptx.addSlide();
  SlideHeader(slide, data);

  slide.addText(
    `Exhibit 3a: Top Transformations Impacting Growth in ${textCapitalize(
      data?.title?.sector
    )}, ${data?.title.region}, ${data?.title.date}`,
    {
      x: 0.4,
      y: headerHeight + heroSectionHeight + 0.5,
      fontSize: 12,
      h: getInchesFromPixels(12),
      color: secondaryColor,
      bold: false,
    }
  );
  const columnWidths = [0.5, 1.5, 2.5, 0.8, 1, 1];

  const headerRow = [
    {
      text: "Rank",
      options: {
        align: "center" as PptxGenJS.HAlign,
        color: "ffffff",
        fill: { color: "1582C5" },
      },
    },
    {
      text: "Strategic Imperative Category",
      options: {
        align: "left" as PptxGenJS.HAlign,
        color: "ffffff",
        fill: { color: "1582C5" },
      },
    },
    {
      text: "Strategic Imperative",
      options: {
        align: "left" as PptxGenJS.HAlign,
        color: "ffffff",
        fill: { color: "1582C5" },
      },
    },
    {
      text: "Impact Score(%)",
      options: {
        align: "center" as PptxGenJS.HAlign,
        color: "ffffff",
        fill: { color: "1582C5" },
      },
    },
    {
      text: "Timeline",
      options: {
        align: "center" as PptxGenJS.HAlign,
        color: "ffffff",
        fill: { color: "1582C5" },
      },
    },
    {
      text: "Duration Curve",
      options: {
        align: "center" as PptxGenJS.HAlign,
        color: "ffffff",
        fill: { color: "1582C5" },
      },
    },
  ];

  const getContentRow = (isOdd: boolean, data: any, index) => {
    const fill = isOdd ? "CCD8EA" : "E7EDF5";
    return [
      { text: index + 1, options: { align: "center", fill: { color: fill } } },
      {
        text: data?.strategicImperative,
        options: { align: "left" as PptxGenJS.HAlign, fill: { color: fill } },
      },
      {
        text: data?.event,
        options: { align: "left" as PptxGenJS.HAlign, fill: { color: fill } },
      },
      {
        text: data?.impactScore,
        options: { align: "center" as PptxGenJS.HAlign, fill: { color: fill } },
      },
      {
        text: `${data?.impactStartYear}-${
          data?.impactStartYear + data?.impactDuration
        }`,
        options: { align: "center" as PptxGenJS.HAlign, fill: { color: fill } },
      },
      {
        text: data?.durationCurve,
        options: { align: "center" as PptxGenJS.HAlign, fill: { color: fill } },
      },
    ];
  };

  const contentRow = data?.strategicImperativeTable?.map(
    (item, index: number) => {
      return getContentRow(index % 2 === 0, item, index);
    }
  );

  // Define the data for the table
  const rows = [...contentRow];
  // console.log(rows);

  slide.addTable([headerRow], {
    x: 0.4, // x position
    y: headerHeight + heroSectionHeight + 0.75,
    w: "100%", // width
    h: 0.5, // height
    border: { pt: 1, color: "ffffff" }, // Border for the table

    fontSize: 10, // Font size for text in the table
    fill: { color: "CCCCCC" },
    align: "center", // Center align text in cells
    colW: columnWidths,
  });

  // Add the table to the slide
  slide.addTable(rows, {
    x: 0.4, // x position
    y: headerHeight + heroSectionHeight + 1.25,
    w: "100%", // width
    h: "20%", // height
    border: { pt: 1, color: "ffffff" }, // Border for the table

    fontSize: 10, // Font size for text in the table
    // fill: ["#FFFFFF"], // Background color for cells
    align: "center", // Center align text in cells
    colW: columnWidths,
    rowH: 0.55,
    valign: "middle",
  });
  SlideSource(slide);
  SlideFooter(slide, pageNo);
};
