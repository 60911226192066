import PptxGenJS from "pptxgenjs";
import { SlideHeader } from "../Components/SlideHeader";
import {
  headerHeight,
  heroSectionHeight,
  secondaryColor,
} from "../Components/SlideConstants";
import { SlideFooter } from "../Components/SlideFooter";
import { SlideSource } from "../Components/SlideSource";
import c2aImage from "../../V1/image/c2aImage.png";
import { getInchesFromPixels } from "../Components/getInchesfromPixels";

export const C2ASlide = (pptx: PptxGenJS, pageNo?: number, data?: any) => {
  const slide = pptx.addSlide();
  SlideHeader(slide, data);

  slide.addText(`Next Steps: Companies to Action (C2A) `, {
    x: 0.4,
    y: headerHeight + heroSectionHeight + 0.5,
    color: secondaryColor,
    fontSize: 16,
    h: getInchesFromPixels(16),
    bold: true,
    w: "90%",
  });
  slide.addText(
    "Companies to Action are leaders in the industry that will shape the future of the industry. These are companies that any organization must engage with to achieve growth objectives.",
    {
      x: 0.4,
      y: headerHeight + heroSectionHeight + 1.2,
      color: "000000",
      fontSize: 12,
      h: getInchesFromPixels(12 * 4),
      bold: false,
      w: "46%",
    }
  );
  const tableData = [
    "Which companies are shaping your ecosystem?",
    "Which companies should you be working with?",
    "Who should be your suppliers, customers, partners?",
    "Which companies should be on your radar for strategic investments?",
    "How are you engaging with the companies shaping the future?",
  ];
  const contentRow = tableData.map((data: string) => {
    return [
      { text: "•", options: { align: "left" as PptxGenJS.HAlign } },
      {
        text: data,
        options: { align: "left" as PptxGenJS.HAlign },
      },
    ];
  });
  slide.addTable(contentRow, {
    x: 0.65, // x position
    y: headerHeight + heroSectionHeight + 1.8,
    w: "100%", // width
    h: "5%", // height
    border: { pt: 0, color: "ffffff" }, // Border for the table
    fontSize: 12, // Font size for text in the table
    // fill: ["#FFFFFF"], // Background color for cells
    align: "left", // Center align text in cells
    colW: [0.2, 3.7],
  });
  slide.addImage({
    path: c2aImage,
    x: 4.5,
    y: headerHeight + heroSectionHeight + 0.3,
    w: 3.6,
    h: 3.6,
  });
  SlideSource(slide, 6.5);

  SlideFooter(slide, pageNo);
};
