import { PdfPageProps } from "../types";
import PdfPageLayout from "../Layouts/PdfPageLayout";
import { View } from "@react-pdf/renderer";
import StripSection from "../components/StripSection";

export default function NextStepPage({ size, pageId, data }: PdfPageProps) {
  return (
    <PdfPageLayout data={data} size={size} pageId={pageId}>
      <View
        style={{
          marginLeft: "-20px",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StripSection secondary="Frost & Sullivan Analytics Methodology & Next Steps" />
      </View>
    </PdfPageLayout>
  );
}
