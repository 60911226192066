import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { useDispatch } from "react-redux";
import { setPdfChartImage } from "../../../store/slices/PdfExportSlice";

interface BarChartProps {
  data: any;
  options: any;
}

const BarChart: React.FC<BarChartProps> = ({ data, options }) => {
  const chartRef = useRef<HTMLCanvasElement | null | any>(null);
  const chartInstance = useRef<Chart | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.labels.length === 0 && data.datasets.length === 0) {
      return; // Exit the useEffect early if data is empty
    }
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        // Destroy the previous chart instance if it exists
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }

        // Create a new chart instance
        chartInstance.current = new Chart(ctx, {
          type: "bar",
          data,
          options,
        });

        // due to animation ,image was not properly created. thus to cope up with animation time 1500ms delay was added
        setTimeout(() => {
          const base64Image = chartRef.current?.toDataURL("image/png", 1.0);
          dispatch(setPdfChartImage(base64Image));
        }, 2000);
      }
    }

    return () => {
      if (chartInstance.current) {
        dispatch(setPdfChartImage(null));
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  // Add ResizeObserver for responsive chart
  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (chartInstance.current) {
        chartInstance.current.resize();
      }
    });

    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "100%",
        // position: "relative",
      }}
    >
      <canvas
        className="bar-chart"
        ref={chartRef}
        width={"100%"}
        height={"70%"}
        style={{
          boxSizing: "inherit",
          display: "inline",
        }}
      ></canvas>
    </div>
  );
};

export default BarChart;
// import React, { useEffect, useRef, useState } from "react";import React, { useEffect, useRef, useState } from "react";

// interface BarChartProps {
//   data: any;
//   options: any;
// }

// const BarChart: React.FC<BarChartProps> = ({ data, options }) => {
//   const chartRef = useRef<HTMLCanvasElement | null>(null);
//   const chartInstance = useRef<Chart | null>(null);

//   useEffect(() => {
//     if (chartRef.current && data && data.datasets && data.labels) {
//       const ctx = chartRef.current.getContext("2d");
//       if (ctx) {
//         if (chartInstance.current) {
//           chartInstance.current.destroy();
//         }

//         chartInstance.current = new Chart(ctx, {
//           type: "bar",
//           data: data,
//           options: options,
//         });
//       }
//     }

//     return () => {
//       if (chartInstance.current) {
//         chartInstance.current.destroy();
//       }
//     };
//   }, [data, options]);

//   if (!data || !data.datasets || !data.labels) {
//     return <div>Loading chart data...</div>;
//   }

//   return (
//     <canvas
//       className="bar-chart"
//       ref={chartRef}
//       width={"100%"}
//       height={"70%"}
//       style={{
//         boxSizing: "inherit",
//         display: "inline",
//       }}
//     ></canvas>
//   );
// };

// export default BarChart;
