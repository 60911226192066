import { isEmpty } from "lodash";
import moment from "moment";
import { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Button from "../../components/button/button";
import CustomDataTable from "../../components/dataTable/dataTable";
import DebounceSearch from "../../components/debounceSearch";
import { PreviewBox2 } from "../../components/table/table.styled";
import { useGenerateHistory } from "../../hooks";
import useDataTableSort from "../../hooks/customHooks/useDataTableSort";
import useFrostSi8Api from "../../hooks/useFrostSi8Api";
import { useDispatch } from "react-redux";
import { setNewGenerated } from "../../store/slices/subSegmentSlice";
import MaterialIcon from "../../components/materialIcon/materialIcon";
import { colors } from "../../constants/themeConstants";
import { Pagination } from "antd";
import { replaceNullWithEmptyString } from "../../utils";
import { textCapitalize } from "../../utils/textCapitalize";
import { useNavigate } from "react-router-dom";

const HistoryModal = ({
  setFilterData,
  setShowHistoryModal,
  generateData,
  setButtonRest,
  setToggle,
  setActive,
  viewHistoryRef,
}: any) => {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [maxResultCount, setMaxResultCount] = useState<number>(10);
  const [skipCount, setSkipCount] = useState<number>(0);

  const { handleRequest } = useFrostSi8Api();
  const {
    data: generatedHistory,
    isLoading,
    refetch,
  } = useGenerateHistory(
    handleRequest,
    searchKeyword,
    skipCount,
    maxResultCount
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const noData =
    !generatedHistory?.items || generatedHistory?.items.length === 0;

  const { handleSort } = useDataTableSort();

  const handleNext = (page: number): void => {
    setSkipCount(maxResultCount * page);
    setPage(page + 1);
  };

  const handlePrev = (page: number): void => {
    // debugger;
    setSkipCount(maxResultCount * (page - 1) - maxResultCount);
    setPage(page - 1);
  };

  const onHandleView = (row?: any) => {
    // const rowData = replaceNullWithEmptyString(row);
    // const payloadView = {
    //   ...rowData,
    //   newTransformational: !isEmpty(row?.customTransformationalJourney)
    //     ? false
    //     : true,
    // };
    // viewHistoryRef.current = true;
    // setFilterData(payloadView);
    // generateData(payloadView);
    // setButtonRest(true);
    // setShowHistoryModal(false);
    navigate(`/transformation/strategic-imperative/${row?.si8DataRequestId}`);
  };

  const truncate = (input) =>
    input?.length > 20 ? `${input.substring(0, 25)}...` : input;

  const columns = [
    {
      name: "S.No.",
      selector: (row, index: number) => skipCount + index + 1,
      width: "80px",
      center: "true",
    },
    {
      name: "Industry",
      accessor: "Industry",
      selector: (row) => textCapitalize(row?.Industry),
      center: "true",
    },
    {
      name: "Sector",
      accessor: "Sector",
      selector: (row) => textCapitalize(row?.Sector),
      center: "true",
    },
    {
      name: "Sub-sector",
      accessor: "SubSector",
      selector: (row) => textCapitalize(row.SubSector) ?? "-",
      center: "true",
    },
    {
      name: "Region",
      accessor: "regionName",
      selector: (row) => row.regionName,
      center: "true",
    },
    {
      name: "Description",
      accessor: "description",
      center: "true",
      selector: (row) => (
        <OverlayTrigger
          trigger={["click"]}
          rootClose
          placement="top"
          overlay={
            <Popover
              id="popover-trigger-hover-focus"
              title="Popover bottom"
              style={{ zIndex: 9999 }}
            >
              <PreviewBox2>
                <div className="box-preview1">{row.description}</div>
              </PreviewBox2>
            </Popover>
          }
        >
          <td style={{ cursor: "pointer" }}>{truncate(row.description)}</td>
        </OverlayTrigger>
      ),
    },
    {
      name: "Keyword to Avoid",
      accessor: "keywordsToAvoid",
      selector: (row) =>
        row.keywordsToAvoid && row.keywordsToAvoid.trim() !== ""
          ? row.keywordsToAvoid
          : "-",
      center: "true",
    },
    {
      name: "Additional Source ",
      accessor: "additionalSources",
      center: "true",
      selector: (row) =>
        row.additionalSources && row.additionalSources.trim() !== ""
          ? row.additionalSources
          : "-",
    },
    {
      name: "Completed Date",
      accessor: "completedDateTime",
      selector: (row) =>
        row.completedDateTime && row.completedDateTime.trim() !== ""
          ? row.completedDateTime
          : "-",
      format: (row) => moment(row.completedDateTime).format("YYYY-MM-DD"),
      center: "true",
    },
    {
      center: "true",
      cell: (row) => (
        <div className="d-flex justify-content-center">
          <Button
            variant="primary"
            title="View"
            onClick={() => {
              onHandleView(row);
              if (setToggle) {
                setToggle(1);
              }
              setActive(false);
              dispatch(setNewGenerated(true));
            }}
            size="sm"
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="col-12 mb-3">
        <div className="row justify-content-start">
          <div className="col-4">
            <DebounceSearch
              placeholder="Search History"
              valueSetter={setSearchKeyword}
              setSkipCount={setSkipCount}
              setPage={setPage}
            />
          </div>
        </div>
      </div>
      <div className="history-modal-body">
        <CustomDataTable
          columns={columns}
          data={generatedHistory?.items}
          paginationPerPage={maxResultCount}
          paginationTotalRows={generatedHistory?.totalCount}
          onSort={handleSort}
          onRowClicked={(row) => {
            onHandleView(row);
            setToggle(1);
            setActive(false);
            dispatch(setNewGenerated(true));
          }}
        />
        {!isLoading && (
          <Pagination
            align="center"
            current={page}
            pageSize={maxResultCount}
            total={generatedHistory?.totalCount}
            showSizeChanger
            onChange={(page) => {
              setPage(page);
              setSkipCount((page - 1) * maxResultCount);
            }}
            onShowSizeChange={(current, size) => {
              setMaxResultCount(size);
              setSkipCount((page - 1) * size);
            }}
            style={{ margin: "24px 0px" }}
          />
        )}
      </div>
    </>
  );
};

export default HistoryModal;
