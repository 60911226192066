import PptxGenJS from "pptxgenjs";
import { SlideHeader } from "../Components/SlideHeader";
import {
  headerHeight,
  heroSectionHeight,
  secondaryColor,
  sideMargin,
  slideWidth,
} from "../Components/SlideConstants";
import { SlideFooter } from "../Components/SlideFooter";
import { getInchesFromPixels } from "../Components/getInchesfromPixels";

export const LegalDisclaimerSlide = (
  pptx: PptxGenJS,
  pageNo?: number,
  data?: any
) => {
  const slide = pptx.addSlide();
  SlideHeader(slide, data);

  slide.addShape(pptx.ShapeType.rect, {
    x: sideMargin,
    y: headerHeight + heroSectionHeight + 0.3,
    w: slideWidth - sideMargin * 2,
    h: 0.32,
    fill: { color: secondaryColor },
  });

  slide.addText(`Legal Disclaimer `, {
    x: sideMargin + 0.1,
    y: headerHeight + heroSectionHeight + 0.45,
    color: "ffffff",
    fontSize: 12,
    bold: true,
  });
  slide.addText(
    "Frost & Sullivan is not responsible for any incorrect information supplied to us by manufacturers or users. Quantitative market information is based primarily on interviews and therefore is subject to fluctuation. Frost & Sullivan research services are limited publications containing valuable market information provided to a select group of customers. Our customers acknowledge, when ordering or downloading, that Frost & Sullivan research services are for customers' internal use and not for general publication or disclosure to third parties. No part of this research service may be given, lent, resold or disclosed to noncustomers without written permission. Furthermore, no part may be reproduced, stored in a retrieval system, or transmitted in any form or by any means, electronic, mechanical, photocopying, recording or otherwise, without the permission of the publisher.",
    {
      x: sideMargin,
      y: headerHeight + heroSectionHeight + 1.2,
      color: "000000",
      h: getInchesFromPixels(12 * 9),
      w: slideWidth - sideMargin * 2,
      fontSize: 12,
      bold: false,
    }
  );
  slide.addText(
    [
      {
        text: "For information regarding permission, write to: ",
      },
      {
        text: "permission@frost.com",
        options: {
          color: secondaryColor,
        },
      },
    ],
    {
      x: sideMargin,
      y: headerHeight + heroSectionHeight + 2.4,
      color: "000000",
      h: "10%",
      w: slideWidth - sideMargin * 2,
      fontSize: 12,
      bold: false,
    }
  );

  SlideFooter(slide, pageNo);
};
