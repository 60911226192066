import React, { useEffect, useState } from "react";

export default function VisualizationDetailCard({ data, index }) {
  const [copySuccess, setCopySuccess] = useState("");
  const contentData = splitDataIntoSections(data?.topRankSi8Data);

  const plainText =
    contentData
      ?.map(
        (item) =>
          `Transformation ${index + 1}: ${data?.strategicImperative}\n${
            item.title
          }\n${item.content}`
      )
      .join("\n\n") || "";

  const htmlContent = `
  <div class="dc-title" style="
    font-family: Nunito Sans;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #172d55;
    margin-bottom: 3px;
  ">
    Transformation ${index + 1}: ${data?.strategicImperative}<br />
  </div>
  <div class="dc-content">
    <ul>
      ${contentData
        .map(
          (item) => `
            <li>
              <b>${item?.title}</b> ${item?.content}
              <br />
            </li>`
        )
        .join("")}
    </ul>
  </div>
`;

  const copyToClipboard = () => {
    const clipboardItem = new ClipboardItem({
      "text/plain": new Blob([plainText], { type: "text/plain" }),
      "text/html": new Blob([htmlContent], { type: "text/html" }),
    });

    // Copy both formats to the clipboard
    navigator.clipboard.write([clipboardItem]).then(
      () => setCopySuccess("Copied!"),
      () => setCopySuccess("Failed to copy!")
    );
  };

  useEffect(() => {
    const clear = setTimeout(() => {
      if (copySuccess !== "") {
        setCopySuccess("");
      }
    }, 1000);

    return () => {
      clearTimeout(clear);
    };
  }, [copySuccess]);

  return (
    <div className="details_card">
      <div
        className="details_container"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      ></div>

      <div className="dc-bottom">
        <button type="button" className="copy-btn" onClick={copyToClipboard}>
          {copySuccess ? (
            copySuccess
          ) : (
            <>
              <svg
                width="13"
                height="15"
                viewBox="0 0 13 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 4.88223V11.9999H2.94116V0.999878H8.44116M12 4.88223H8.44116V0.999878M12 4.88223L8.44116 0.999878"
                  stroke="#DE7A22"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 6.17639V13.9411H7.79412"
                  stroke="#DE7A22"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.88232 6.17639H6.17644M4.88232 8.76463H8.76468"
                  stroke="#DE7A22"
                  strokeLinecap="round"
                />
              </svg>
            </>
          )}
        </button>
      </div>
    </div>
  );
}

export const VisualizationDetailCardSkeleton = () => {
  return (
    <>
      <div
        className="details_card details_card--skeleton "
        style={{ padding: "20px" }}
      >
        <p
          className="skeleton"
          style={{ borderRadius: 8, height: "20px", width: "100%" }}
        ></p>
        <p
          className="skeleton"
          style={{
            borderRadius: 8,
            height: "20px",
            width: "30%",
            marginTop: "-15px",
          }}
        ></p>
        {[...Array(3)].map(() => {
          return (
            <p
              className="skeleton"
              style={{ borderRadius: 8, height: "70px", width: "100%" }}
            ></p>
          );
        })}
        <div className="dc-bottom">
          <div
            className="skeleton"
            style={{
              borderRadius: 8,
              height: "35px",
              width: "35px",
            }}
          ></div>
        </div>
      </div>
      {/* <div className="details_card--skeleton skeleton"></div> */}
    </>
  );
};

export const splitDataIntoSections = (text) => {
  const sections = text
    // Split into paragraphs
    ?.split("\n\n")
    ?.map((item) => {
      const [title, content] = item?.split("--");
      return {
        title: title?.replace(/\*\*/g, "")?.trim() ?? "", // Remove the ** around the bold parts
        content: content?.trim() ?? "",
      };
    });
  return sections;
};
