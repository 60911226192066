import PptxGenJS from "pptxgenjs";
import heroImage from "../../assets/heroImage.png";
import { headerHeight } from "./SlideConstants";
import { getInchesFromPixels } from "./getInchesfromPixels";

export const SlideHeroSection = (slide: PptxGenJS.Slide) => {
  slide.addImage({
    path: heroImage,
    x: 0.5,
    y: headerHeight + 0.3,
    w: 7.6,
    h: 0.804,
  });

  slide.addText("TRANSFORMATIONAL GROWTH JOURNEY​", {
    x: 0.4,
    y: headerHeight + 0.2,
    w: "92%",
    fontSize: 12,
    h: getInchesFromPixels(12),
    color: "2F5596",
    align: "center",
  });
};
