import { FC } from "react";
import DataTable, { TableProps } from "react-data-table-component";
import styled from "styled-components";
import { colors } from "../../constants/themeConstants";
import Loader from "../loader/Loader";
import MaterialIcon from "../materialIcon/materialIcon";

const StyledDataTable = styled(DataTable)`
  .rdt_TableRow:hover {
    background-color: ${colors.tableRowHover};
  }
`;

interface DataTableProps extends TableProps<any> {
  columns: any;
  data: any;
  onRowClicked?: (row: any) => void;
}

const customStyles = {
  rows: {
    style: {
      minHeight: "42px",
      backgroundColor: "#f3f4f6",
      margin: "1px 0px",
      fontFamily: "Nunito Sans",
      cursor: "pointer",
    },
  },
  headCells: {
    style: {
      backgroundColor: `${colors.secondary}`,
      color: "#ffffff",
      border: 0,
      fontFamily: "Nunito Sans",
    },
  },
  cells: {
    style: {
      fontSize: "13.33px",
      padding: "8px",
      borderRight: `1px solid ${colors.secondary}`,
      border: "none",
    },
  },
};

const CustomDataTable: FC<DataTableProps> = ({
  columns,
  data,
  onRowClicked,
  ...rest
}) => {
  return (
    <StyledDataTable
      columns={columns}
      data={data}
      sortServer
      sortIcon={<MaterialIcon iconName="arrow_drop_down" />}
      progressPending={!data ? true : false}
      customStyles={customStyles}
      paginationRowsPerPageOptions={[10, 20, 30, 40]}
      // progressComponent={<Loader />}
      progressComponent={<TransformationHomeTableLoading />}
      onRowClicked={onRowClicked}
      {...rest}
    />
  );
};

export default CustomDataTable;

const TransformationHomeTableLoading = () => {
  return (
    <TransformationHomeTableLoadingSkeleton className="">
      <div className="head skeleton"></div>
      {[...Array(9)].map(() => {
        return <div className="body skeleton"></div>;
      })}
    </TransformationHomeTableLoadingSkeleton>
  );
};

const TransformationHomeTableLoadingSkeleton = styled.div`
  background-color: #f3f4f6;
  /* padding: 12px 8px; */
  display: block;
  width: 100%;
  margin: 0;
  /* height: 40vh; */
  .head {
    height: 60px;
    width: 100%;
    background-color: #172d55;
    /* background-color: #ffffff; */
  }
  .body {
    height: 50px;
    width: 100%;
    margin: 0 !important;
  }
`;
