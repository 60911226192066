import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Si8SegmentRequestService } from "../../services/frost-si8-services";
interface IProp {
  segmentRequestId: string;
  requestBody?: any;
}
const usePostSubSegmentData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): any => {
  return useMutation(
    async ({ segmentRequestId, requestBody }: IProp) => {
      {
        return await handleRequest(
          Si8SegmentRequestService.postCoreApiApiAppSi8SegmentRequestSi8SegmentData(
            segmentRequestId,
            requestBody
          )
        );
      }
    },
    {
      onSuccess: async (response) => {
        if (response != null) {
          if (response?.success) {
            toast.success(response?.message ?? "Added New Row sucessfully");
          } else {
            toast.error(response?.message);
          }
        }
      },
      onError: async (error) => {
        console.error("Mutation error:", error);
      },
    }
  );
};

export default usePostSubSegmentData;
