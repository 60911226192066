import { Si8DataRequestService } from "../../services/frost-si8-services";
import { useQuery } from "react-query";

const useGetTopTenDataForVisualization = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: string
) => {
  const { data, error, isLoading } = useQuery(
    ["topTenData", id],
    async () => {
      return await handleRequest(
        Si8DataRequestService.getCoreApiApiAppSI8DataRequestPagedAndSortedSi8Data(
          id
        )
      );
    },
    {
      enabled: !!id,
      staleTime: 5 * 60 * 1000, // 5 minutes
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  return { topTenData: data?.data?.si8DataResults ?? [], error, isLoading };
};

export default useGetTopTenDataForVisualization;
