import React from "react";
import { PdfPageProps } from "../types";
import PdfPageLayout from "../Layouts/PdfPageLayout";
import TopText from "../components/TopText";
import { Image, Text } from "@react-pdf/renderer";
import BlueLineTitle from "../components/BlueLineTitle";

import selectionProcessImage from "../image/selectionProcessImage.png";
import SourceComp from "../components/SourceComp";

export default function BenchMarkPage({ size, pageId, data }: PdfPageProps) {
  return (
    <PdfPageLayout data={data} size={size} pageId={pageId}>
      <>
        <TopText text="Next Steps: Competitive Benchmarking with the Frost Radar" />
        <Text>
          Frost & Sullivan’s global team of analysts and consultants
          continuously research a wide range of industries across the globe. The
          primary and secondary analysis spans the entire value chain,
          identifying organizations that consistently develop new growth
          strategies based on a visionary understanding of the future and a
          proven ability to effectively address emerging challenges and
          opportunities. The research and analysis is the foundation of the
          Frost Radar, a robust analytical tool that benchmarks companies’
          innovation focus and growth performance in a given market. The dynamic
          solution offers frequent rating and positioning of companies that are
          agitating, contending or revolutionizing the industry.
        </Text>
        <BlueLineTitle text="Our Selection Process:" />
        <Image
          src={selectionProcessImage}
          style={{ margin: "30px auto", width: "100%" }}
        />
        <SourceComp />
      </>
    </PdfPageLayout>
  );
}
