import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  setVisualizationPoolingState,
  subscribeToLocalStorageForGlobalPooling,
} from "../../store/slices/GlobalPoolingStatusSlice";

export default function GlobalPoolingStatus() {
  const [count, setCount] = useState<number>(0);
  const globalPoolingStatusState = useSelector(
    (state: RootState) => state.GlobalPoolingStatusSlice
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // for inital syncing to localstorage and redux state
    dispatch(subscribeToLocalStorageForGlobalPooling());
  }, [dispatch]);

  return (
    <div>
      {/* TEST HERE
      {JSON.stringify(globalPoolingStatusState)}
      <button
        type="button"
        onClick={() => {
          setCount((prev) => {
            return prev + 1;
          });
          dispatch(
            setVisualizationPoolingState({
              isPooling: true,
              si8RequestId: `${count}`,
            })
          );
        }}
      >
        Update and Store to localStorage too
      </button> */}
      {/* {JSON.stringify(visualizationStorage)}{" "}
      <button
        type="button"
        onClick={() => {
          setVisualizationStorage((prev: VisualizationLocalStorageType) => {
            return { ...prev, si8RequestId: "checked" };
          });
        }}
      >
        Check
      </button> */}
    </div>
  );
}
