import PdfPageLayout from "../Layouts/PdfPageLayout";
import { PdfPageProps } from "../types";
import TopText from "../components/TopText";
import { Image, Text, View } from "@react-pdf/renderer";
import SourceComp from "../components/SourceComp";
import c2aImage from "../image/c2aImage.png";

export default function C2APage({ size, pageId, data }: PdfPageProps) {
  return (
    <PdfPageLayout data={data} size={size} pageId={pageId}>
      <>
        <TopText text="Next Steps: Companies to Action (C2A)" />
        <View style={{ display: "flex", flexDirection: "row", gap: 20 }}>
          <View style={{ width: 320 }}>
            <Text style={{ marginBottom: 20 }}>
              Companies to Action are leaders in the industry that will shape
              the future of the industry. These are companies that any
              organization must engage with to achieve growth objectives.
            </Text>
            {[
              "Which companies are shaping your ecosystem?",
              "Which companies should you be working with?",
              "Who should be your suppliers, customers, partners?",
              "Which companies that should be on your radar for strategic investments?",
              "How are you engaging with the companies shaping the future?",
            ].map((item: string) => {
              return (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 10,
                    paddingLeft: 20,
                    marginBottom: 5,
                  }}
                >
                  <View
                    style={{
                      height: 5,
                      width: 5,
                      borderRadius: "50%",
                      backgroundColor: "black",
                    }}
                  ></View>
                  <Text>{item}</Text>
                </View>
              );
            })}
          </View>
          <View style={{ width: 250, height: 250 }}>
            <Image src={c2aImage} style={{ width: 250, height: 250 }} />
          </View>
        </View>
        <SourceComp />
      </>
    </PdfPageLayout>
  );
}
