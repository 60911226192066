import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "../../../components/button/button";
import { Loader } from "../../../components/loader/Loader";
import MaterialIcon from "../../../components/materialIcon/materialIcon";
import NoDataFound from "../../../components/no-data-found";
import { PopupBox } from "../../../components/table/table.styled";
import useFrostSi8Api from "../../../hooks/useFrostSi8Api";
import { Si8SegmentRequestService } from "../../../services/frost-si8-services";
import {
  setGeneratedSegment,
  setGenerating,
  setUpdateFlag,
} from "../../../store/slices/subSegmentSlice";
import { RootState } from "../../../store/store";
import { exportAsImage } from "../../../utils";
import { SubSegmentWrapper } from "./subSegment.styled";
import SubSegmentChart from "./subSegmentChart";
import SubSegmentTable from "./subSegmentTable";
import { useLocation, useParams } from "react-router-dom";

interface SubSegmentCompProps {
  tableGenerated?: boolean;
  data?: any;
  toggle?: number;
}

const SubSegmentComp = ({
  data,
  tableGenerated,
  toggle,
}: SubSegmentCompProps) => {
  const [exporting, setExporting] = useState(false);
  const [subSegmentData, setSubSegmentData] = useState<any>(null);
  const [storeSegmentRequestId, setStoreSegmentRequestId] = useState<
    string | null
  >(null);
  const [isDataExpired, setExpired] = useState<boolean | null | undefined>(
    false
  );
  const [tableEdited, setTableEdited] = useState<boolean | null | undefined>(
    false
  );
  const [pollingIntervalId, setPollingIntervalId] =
    useState<NodeJS.Timeout | null>(null);

  const generating = useSelector(
    (state: RootState) => state.subSegment.generating
  );
  const edited = useSelector((state: RootState) => state.subSegment.edited);
  const newGenerate = useSelector(
    (state: RootState) => state.subSegment.newGenerated
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const locationPath = location.pathname?.split("/")[2];
  const { si8DataRequestId } = useParams();

  // console.log("subSegmentData", data, tableGenerated, toggle);
  const chartRef = useRef<any>(null);
  const { handleRequest } = useFrostSi8Api();
  const tableId = si8DataRequestId ?? "";

  const fetchdata = () => {
    handleRequest(
      Si8SegmentRequestService.getCoreApiApiAppSi8SegmentRequestSegmentData(
        tableId
      )
        .then((res) => {
          if (res?.data === null) {
            dispatch(setGenerating(false));
          } else if (
            res &&
            res?.data?.si8SegmentDataProcessStageSystemName === "completed"
          ) {
            dispatch(setGenerating(false));
            setSubSegmentData(res?.data?.si8SegmentDataResults);
            setStoreSegmentRequestId(res?.data?.si8SegmentRequestId ?? null);
            setExpired(res?.data?.isSi8RequestExpired);
            setTableEdited(res?.data?.needsSegmentDataRegeneration);
            setPollingIntervalId(null);
            dispatch(setGeneratedSegment("false"));
          } else if (
            res &&
            res?.data?.si8SegmentDataProcessStageSystemName === "failed"
          ) {
            dispatch(setGenerating(false));
            setPollingIntervalId(null);
            dispatch(setGeneratedSegment("false"));
            toast.error("Subsegment Generation has failed. Please Try Again");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
    );
  };

  const regenerateData = async () => {
    await handleRequest(
      Si8SegmentRequestService.postCoreApiApiAppSi8SegmentRequestExpireSegmentData(
        tableId
      ).then(() => {
        fetchdata();
        const intervalId = setInterval(fetchdata, 5000);
        setPollingIntervalId(intervalId);
        dispatch(setUpdateFlag(false));
      })
    ).catch((error) => {
      console.error("Error expiring data:", error);
      dispatch(setGenerating(false));
      dispatch(setGeneratedSegment("false"));
    });
  };

  const handleCancel = () => {
    dispatch(setGeneratedSegment("false"));
    handleRequest(
      Si8SegmentRequestService.postCoreApiApiAppSi8SegmentRequestCancelSegmentRequest(
        tableId
      ).then(() => {
        dispatch(setGenerating(false));
        setPollingIntervalId(null);
      })
    );
  };

  const handleGenerate = () => {
    fetchdata();
    const intervalId = setInterval(fetchdata, 5000);
    setPollingIntervalId(intervalId);
  };

  const handleRegenerate = () => {
    setSubSegmentData(null);
    regenerateData();
  };

  const actionHandler = (type?: string) => {
    const notify = () =>
      toast(type === "Cancel" ? actionPopup() : null, {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        hideProgressBar: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    notify();
  };

  const actionPopup = () => (
    <>
      <PopupBox>
        <h2>Cancel</h2>
        <p>Are you sure you want to cancel request </p>
        <div className="popup-btns">
          <Button
            variant="secondary-outline"
            title="Confirm"
            size="md"
            type="button"
            onClick={() => {
              {
                handleCancel();
              }
              toast.dismiss();
            }}
          />
          <Button
            variant="primary"
            title="Cancel"
            size="md"
            type="button"
            onClick={() => {
              toast.dismiss();
            }}
          />
        </div>
      </PopupBox>
    </>
  );

  useEffect(() => {
    return () => {
      if (pollingIntervalId) {
        clearInterval(pollingIntervalId);
      }
    };
  }, [pollingIntervalId]);

  useEffect(() => {
    if (newGenerate) {
      setSubSegmentData(null);
    }
  }, [newGenerate, tableId]);
  useEffect(() => {
    if (
      locationPath == "sub-segment" &&
      localStorage.getItem("generatedSegment") === "true"
    ) {
      handleGenerate();
    } else {
      setPollingIntervalId(null);
    }
  }, [locationPath]);

  return subSegmentData ? (
    <>
      <SubSegmentWrapper>
        {exporting && (
          <div
            style={{
              position: "fixed",
              bottom: 50,
              right: 20,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              padding: 10,
              borderRadius: 5,
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              zIndex: 9999,
            }}
          >
            Exporting...
          </div>
        )}
        {edited || isDataExpired || tableEdited ? (
          <>
            <div className="tooltip-div">
              <MaterialIcon
                showTooltip={false}
                className="btn-hover"
                iconName="report"
                onClick={() => {}}
                color={"red"}
              />
              Please click on regenerate to view the latest visualization.
            </div>
          </>
        ) : (
          ""
        )}
        <div className="generated-action-btn">
          <Button
            variant="secondary"
            type="submit"
            title="Regenerate"
            btnClassName="btn"
            size="lg"
            icon="restart_alt"
            onClick={() => {
              dispatch(setGenerating(true));
              dispatch(setGeneratedSegment("true"));
              handleRegenerate();
            }}
          />
          <Button
            variant="secondary"
            type="submit"
            btnClassName="btn"
            size="md"
            icon="download"
            onClick={() => {
              setExporting(true);
              exportAsImage(
                chartRef.current,
                "Subsegment",
                16 / 9,
                setExporting
              );
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // flexDirection: "column",
            // fontSize: "14px",
            // color: "#555",
            // marginBottom: "-100px",
            // marginTop: "20px",
            width: "100%",
            paddingLeft: "30px",
          }}
        >
          <p style={{ maxWidth: "70%", fontSize: "15px" }}>
            <span style={{ fontWeight: "bold", fontSize: "15px" }}>
              How to read this chart:
            </span>
            &nbsp;Sub-segments are identified and mapped to determine 1) which
            subsegments are most impacted and 2) which subsegments are impacted
            soon. <br /> The top-right box in the chart specifies the
            sub-segments that will be disrupted most & soonest by the coming
            transformations.
          </p>
        </div>
        <div ref={chartRef} className="sub-wrap">
          <SubSegmentChart data={subSegmentData} />
        </div>
      </SubSegmentWrapper>
      <SubSegmentTable
        subSegmentData={subSegmentData}
        setSubSegmentData={setSubSegmentData}
        segmentRequestId={storeSegmentRequestId ?? ""}
      />
    </>
  ) : (
    <SubSegmentWrapper>
      {!generating ? (
        <div className="generate-msg-div">
          <div className="generate-message">
            Click on the button below to generate sub-segment visualization.
          </div>
          <Button
            variant="secondary"
            type="submit"
            title="Generate"
            btnClassName="btn"
            size="lg"
            onClick={() => {
              dispatch(setGenerating(true));
              dispatch(setGeneratedSegment("true"));
              handleGenerate();
            }}
          />
        </div>
      ) : (
        <div className="generating-msg-div">
          <Loader></Loader>
          Loading...
          <div className="generating-message">
            Please wait while the system visualizes the sub-segments.
          </div>
          <Button
            variant="secondary"
            type="submit"
            title="Cancel"
            btnClassName="btn"
            size="lg"
            onClick={() => {
              actionHandler("Cancel");
            }}
          />
        </div>
      )}
    </SubSegmentWrapper>
  );
};

export default SubSegmentComp;
