import { PdfPageProps } from "../types";
import { Page, View, Text, Image } from "@react-pdf/renderer";

import logo from "../../assets/pdf/logo.png";
import heroImage from "../../assets/heroImage.png";
import { textCapitalize } from "../../../utils/textCapitalize";

export default function PdfPageLayout(props: PdfPageProps) {
  const { size, pageId, data, children } = props;
  return (
    <>
      <Page
        size={size}
        style={{ backgroundColor: "white" }}
        id={pageId as string}
      >
        <View
          style={{
            height: "84px",
            width: "100%",
            backgroundColor: "#172D55",
            paddingTop: "2px",
            paddingBottom: "2px",
            paddingLeft: "20px",
            paddingRight: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: 10,
            color: "#f5f5f5",
          }}
        >
          <View
            style={{
              // backgroundColor: "red",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flex: 1,
              width: "500px",
            }}
          >
            <View>
              <Text style={{ width: "350px", fontSize: "16px" }}>
                Top 10 Strategic Imperatives in
              </Text>
            </View>
            <View>
              <Text style={{ fontSize: "16px", width: "500px" }}>
                {textCapitalize(data?.title.sector)}, {data?.title.region},{" "}
                {data?.title.date}
              </Text>
            </View>
          </View>

          <View
            style={{
              // backgroundColor: "green",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
              flex: 1,
            }}
          >
            <Text style={{ fontSize: "16px", color: "#00B0F0" }}>
              Strategic Imperative
            </Text>
            {/* <Image src={logo} style={{ width: 300 }} /> */}
            {/* <Text style={{ textAlign: "center", color: "white" }}>
            Top 10 Strategic Imperatives in {data?.title.sector},{" "}
            {data?.title.region}, {data?.title.date}
          </Text> */}
          </View>
        </View>

        <View
          style={{
            padding: "20px",
            paddingBottom: "0px",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontSize: "12px",
              textAlign: "center",
              color: "#2F5596",
            }}
          >
            TRANSFORMATIONAL GROWTH JOURNEY
          </Text>
          <Image src={heroImage} style={{ width: "580px" }} />
        </View>

        <View
          style={{
            padding: "20px",
            paddingTop: "18px",
            paddingBottom: 0,
            flex: 1,
          }}
        >
          {children}
        </View>
        <View
          style={{
            height: "35px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "6px 20px",
            fontSize: 9,
            textAlign: "center",
            backgroundColor: "#172D55",
            color: "white",
            position: "relative",
          }}
        >
          <View
            style={{
              position: "absolute",
              height: "30px",
              width: "25px",
              backgroundColor: "#00B0F0",
              top: -30,
              right: 20,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Text
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber - 1} of ${totalPages - 1}`
            }
            fixed
            style={{ width: "33%", textAlign: "left" }}
          /> */}
            <Text
              render={({ pageNumber, totalPages }) => `${pageNumber - 1}`}
              fixed
              style={{ fontSize: 10 }}
            />
          </View>

          <Image src={logo} style={{ width: 200 }} />
          <Text
            style={{
              textAlign: "right",
              fontSize: 10,
              fontWeight: "bold",
              color: "#F26722",
            }}
          >
            How is your organization maximizing its future growth potential?
          </Text>
        </View>
      </Page>
    </>
  );
}
