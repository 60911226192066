import { Document, StyleSheet, Font } from "@react-pdf/renderer";

// import logo from "./assets/pdf/logo.png";
// import coverContent from "./assets/pdf/coverContent.png";

// import bgImage from "./assets/pdf/bgImage.png";
// import benefits from "./assets/pdf/benefits.png";

// import font from "./assets/font/Nunito_Sans/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf";
import regularFont from "../assets/font/Nunito_Sans/static/NunitoSans_10pt-Regular.ttf";
import semiBoldFont from "../assets/font/Nunito_Sans/static/NunitoSans_10pt-SemiBold.ttf";
import boldFont from "../assets/font/Nunito_Sans/static/NunitoSans_10pt-Bold.ttf";
import CoverPage from "./pages/CoverPage";
import TransformationPage from "./pages/TransformationPage";
import DefinedPage from "./pages/DefinedPage";
import BestPractices from "./pages/BestPractices";
import NextStepPage from "./pages/NextStepPage";
import StrategicImperativeTable from "./pages/StrategicImperativeTable";
import AxisPage from "./pages/AxisPage";
import DiagramPage from "./pages/DiagramPage";
import TOC1 from "./pages/TOC1";
import TOC2 from "./pages/TOC2";
import C2APage from "./pages/C2APage";
import BenchMarkPage from "./pages/BenchMarkPage";
import Methodology from "./pages/MethodologyPage";
import BenefitsPage from "./pages/BenefitsPage";
import LegalDisclaimerPage from "./pages/LegalDisclaimerPage";
import PerspectivePage from "./pages/PerspectivePage";
import ChartPage from "./pages/ChartPage";
import { memo, useRef } from "react";
// import CoverPage from "./pages/CoverPage";

Font.registerHyphenationCallback((word) => [word]);

Font.register({
  family: "Nunito Sans",
  format: "truetype",
  // src: font,
  fonts: [
    {
      src: regularFont,
    }, // Regular
    {
      src: semiBoldFont,
      fontWeight: 600,
    }, // Semi-bold
    {
      src: boldFont,
      fontWeight: 700,
    }, // Bold
  ],
});
Font.register({
  family: "bold",
  format: "truetype",
  // src: font,
  fonts: [
    {
      src: boldFont,
    }, // Regular
    {
      src: semiBoldFont,
      fontWeight: 600,
    }, // Semi-bold
    {
      src: boldFont,
      fontWeight: 700,
    }, // Bold
  ],
});

// Step 2: Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Nunito Sans",
    fontSize: 12,
    margin: 20,
    wrap: false,
  },
  header: {
    fontSize: 20,
    fontWeight: 700, // Bold
    marginBottom: 10,
  },
  primaryColor: {
    color: "#172e55",
  },
});
const V1 = ({ data }: any) => {
  // const size = [1500, 1125];
  const size = "A4";
  return (
    <>
      <Document style={styles.page}>
        <CoverPage size={size} data={data} />
        <TOC1 size={size} pageId={1} data={data} />
        <TOC2 size={size} pageId={2} data={data} />
        <DiagramPage size={size} pageId={3} data={data} />
        <DefinedPage size={size} pageId={4} data={data} />
        <StrategicImperativeTable size={size} pageId={5} data={data} />
        <ChartPage size={size} pageId={6} data={data} />

        {data?.transformationData?.map((item, id: number) => (
          <TransformationPage
            size={size}
            pageId={id + 7}
            pageData={item}
            data={data}
          />
        ))}
        {/* {[...Array(10)]?.map((item, id: number) => (
          <TransformationPage
            size={size}
            pageId={id + 7}
            pageData={item}
            data={data}
          />
        ))} */}
        {/* <PerspectivePage size={size} pageId={17} data={data} /> */}
        <LegalDisclaimerPage size={size} pageId={17} data={data} />
        <NextStepPage size={size} pageId={18} data={data} />
        <Methodology size={size} pageId={19} data={data} />
        <BenefitsPage size={size} pageId={20} data={data} />
        <BenchMarkPage size={size} pageId={21} data={data} />
        <AxisPage size={size} pageId={22} data={data} />
        <BestPractices size={size} pageId={23} data={data} />
        <C2APage size={size} pageId={24} data={data} />
      </Document>
    </>
  );
};

export default memo(V1);
