import React, { useEffect, useRef, useState } from "react";
import VisualizationDetailCard, {
  splitDataIntoSections,
  VisualizationDetailCardSkeleton,
} from "./VisualizationDetailCard";
import { useDispatch } from "react-redux";
import { setPdfTransformationData } from "../../../store/slices/PdfExportSlice";

export default function VisualizationDetail({
  data,
  isLoading,
  isTopTenViewDetailsPooling,
}) {
  const elementRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const offset = 100;
    if (elementRef.current) {
      const { top } = elementRef?.current?.getBoundingClientRect();
      const scrollToPosition = top + window.scrollY - offset;

      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && !isTopTenViewDetailsPooling && data?.items?.length > 0) {
      const contentData = splitDataIntoSections(data?.items?.topRankSi8Data);
      dispatch(setPdfTransformationData(data?.items));
    }
  }, [data, isLoading, isTopTenViewDetailsPooling]);
  return (
    <>
      {" "}
      <div className="details_card_container" ref={elementRef}>
        {isTopTenViewDetailsPooling || !data?.items || isLoading
          ? [...Array(3)].map((_, i) => (
              <VisualizationDetailCardSkeleton key={i} />
            ))
          : !isLoading &&
            !isTopTenViewDetailsPooling &&
            data?.items?.length > 0 &&
            data.items.map((item, i) => (
              <VisualizationDetailCard data={item} key={i} index={i} />
            ))}
      </div>
    </>
  );
}
