import styled from "styled-components";

export const SubSegmentWrapper = styled.div`
  min-height: calc(100vh - 290px);
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  position: relative;
  /* overflow: scroll; */
  .tooltip-div {
    position: absolute;
    background: white;
    padding: 10px;
    margin: 5px;
    box-shadow: 5px 6px 43px -14px rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    .btn-hover {
      margin: 5px;
    }
  }
  .generated-action-btn {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin: 8px;
  }
  .generate-msg-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    gap: 50px;
    margin-top: 200px;
  }
  .generate-message {
    font-size: x-large;
    color: #000;
  }

  .generating-msg-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    gap: 10px;
  }
  .generating-message {
    font-size: x-large;
    color: #000;
  }
  .chartContainer {
    overflow: scroll;
  }
  svg {
    width: 100%;
    height: 100%;
  }
  .sub-wrap {
    /* Adjusting the top margin specifically for larger screens to resolve the height issue.
     Direct manipulation of the SVG caused issues in PNG export, hence using CSS for layout adjustment instead.
     - Abid Adhikari
     */
    @media (min-width: 1300px) {
      margin-top: -100px;
    }
  }

  .sub-segment-chart {
    height: 50%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .reset-zoom-button {
    background-color: #172d55;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .sub-segment-chart reset-zoom-button:hover {
    background-color: #22427d;
  }

  .sub-segment-chart reset-zoom-button:focus {
    outline: none;
  }

  .sub-segment-chart reset-zoom-button .icon {
    margin-left: 10px;
  }

  .sub-segment-chart reset-zoom-button .icon svg {
    fill: white;
    width: 16px;
    height: 16px;
  }
`;
