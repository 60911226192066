import { Text } from "@react-pdf/renderer";

interface PropsType {
  text: string;
}

export default function BlueBgTitle({ text }: PropsType) {
  return (
    <Text
      style={{
        backgroundColor: "#1582C5",
        color: "#fff",
        padding: "4px 8px",
        marginBottom: 4,
        fontFamily: "bold",
      }}
    >
      {text}
    </Text>
  );
}
