/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FResponseDto_1OfOfFSi8_Dtos_DocumentProcessing_DownloadDocumentDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/FResponseDto_1OfOfFSi8_Dtos_DocumentProcessing_DownloadDocumentDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { FSi8_Dtos_DocumentProcessing_DocumentRequestDto } from '../models/FSi8_Dtos_DocumentProcessing_DocumentRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocumentProcessingService {

    /**
     * @param requestBody
     * @returns FResponseDto_1OfOfFSi8_Dtos_DocumentProcessing_DownloadDocumentDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static postCoreApiApiAppDocumentProcessingConvertPdfIntoDocument(
        requestBody?: FSi8_Dtos_DocumentProcessing_DocumentRequestDto,
    ): CancelablePromise<FResponseDto_1OfOfFSi8_Dtos_DocumentProcessing_DownloadDocumentDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/document-processing/convert-pdf-into-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
