/* eslint-disable @typescript-eslint/no-unused-vars */
import PptxGenJS from "pptxgenjs";
import { SlideHeader } from "../Components/SlideHeader";
import {
  headerHeight,
  heroSectionHeight,
  secondaryColor,
  sideMargin,
  slideWidth,
} from "../Components/SlideConstants";
import { SlideFooter } from "../Components/SlideFooter";
import { SlideSource } from "../Components/SlideSource";
import { getInchesFromPixels } from "../Components/getInchesfromPixels";
import { textCapitalize } from "../../../utils/textCapitalize";

export const TOC1Slide = (pptx: PptxGenJS, pageNo?: number, data?: any) => {
  const slide = pptx.addSlide();
  SlideHeader(slide, data);

  slide.addShape(pptx.ShapeType.rect, {
    x: sideMargin,
    y: headerHeight + heroSectionHeight + 0.3,
    w: slideWidth - sideMargin * 2,
    h: 0.32,
    fill: { color: secondaryColor },
  });

  slide.addText(`Contents`, {
    x: sideMargin + 0.1,
    y: headerHeight + heroSectionHeight + 0.3,
    h: 0.32,
    color: "ffffff",
    fontSize: 12,
    bold: true,
  });

  const columnWidths = [0.4, slideWidth - sideMargin * 2 - 0.5 - 0.2, 0.5];
  const dynmaicTOC = data?.transformationData?.map((item: any, i: number) => {
    const temp = {
      title: `Transformation ${i + 1}: ${item?.strategicImperative}`,
      pageNo: `${i + 7}`,
      inner: true,
    };
    return temp;
  });

  const rowData = [
    {
      title: `Transformation in ${textCapitalize(data?.title.sector)}`,
      pageNo: "3",
      inner: false,
    },
    {
      title: "Why Is It Increasingly Diffcult to Grow?",
      pageNo: "3",
      inner: true,
    },
    ...dynmaicTOC,

    { title: "Legal Disclaimer", pageNo: "17", inner: false },
  ];

  const tocRow = [
    ...rowData.map((item) => {
      return getTOCRow(item.title, item.pageNo, item.inner);
    }),
  ];

  slide.addTable(tocRow, {
    x: 0.2, // x position
    y: headerHeight + heroSectionHeight + 0.75, // y position
    w: slideWidth, // width
    h: 0.4, // height
    border: { pt: 1, color: "ffffff" }, // Border for the table
    fontSize: 14, // Font size for text in the table
    // fill: ["CCCCCC"],

    colW: columnWidths,
  });

  SlideFooter(slide, pageNo);
};

export const getTOCRow = (title: string, pageNo: string, inner?: boolean) => {
  const totalSpace = 115;
  const adjustedTitle = title;
  //   const adjustedTitle =
  //     title +
  //     "   " +
  //     "-".repeat(title.length < 115 ? totalSpace - title.length - 25 : 0);
  const textCol = {
    text: `${adjustedTitle}`.trim(),
    options: {
      align: "left",
      color: "000000",
      valign: "center",
      margin: [5, 10, 5, 20],
      colspan: inner ? 1 : 2,
    },
  };
  const pageNoCol = {
    text: pageNo,
    options: { align: "right", color: "000000", valign: "center" },
  };
  const dummyCol = {
    text: "",
  };
  return inner ? [dummyCol, textCol, pageNoCol] : [textCol, pageNoCol];
};
