import { TableAndDataPageProps } from "../types";
import PdfPageLayout from "./PdfPageLayout";
import { View } from "@react-pdf/renderer";
import SourceComp from "../components/SourceComp";
import TopText from "../components/TopText";

export default function TableAndDataLayout({
  size,
  children,
  title,
  pageId,
  data,
}: TableAndDataPageProps) {
  return (
    <PdfPageLayout data={data} size={size} pageId={pageId}>
      <>
        <View>
          <TopText text={title} />
          <View style={{ border: "1px solid #81B3E4", borderBottom: "none" }}>
            {children}
          </View>
        </View>
        <SourceComp />
      </>
    </PdfPageLayout>
  );
}
