import { pdf, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import V1 from "../../../pdf/V1/V1";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import styled from "styled-components";
import { PrimaryButton } from "../../../components/button/button";
import PPT from "../../../pdf/PPT/PPT";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { textCapitalize } from "../../../utils/textCapitalize";
import usePostPdftoDocx from "../../../hooks/post/usePostPdftoDocx";
import useFrostSi8Api from "../../../hooks/useFrostSi8Api";

export default function VisualizationPdfExport({
  data,
  isLoading,
  isTopTenViewDetailsPooling,
}) {
  const { handleRequest } = useFrostSi8Api();
  const [showDownloadBtns, setShowDownloadBtns] = useState(false);
  const [pdfPrepareToDocxLoading, setPdfPrepareToDocxLoading] = useState(false);
  const pdfExportSlice = useSelector(
    (state: RootState) => state.pdfExportSlice
  );

  // TO DO
  useEffect(() => {
    let timer;
    if (
      !isLoading &&
      !isTopTenViewDetailsPooling &&
      data?.items?.length > 0 &&
      data?.items?.length === 10
    ) {
      timer = setTimeout(() => {
        setShowDownloadBtns(true);
      }, 4000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isLoading, isTopTenViewDetailsPooling, data]);

  const { mutate: mutateDocxDownload, isLoading: isDocxDownloadLoading } =
    usePostPdftoDocx(handleRequest);

  const downloadDOCX = async (data: any) => {
    const instance = pdf(<V1 data={data} />);
    const blob = await instance.toBlob();
    const arrayBuffer = await blob.arrayBuffer();
    const byteData = new Uint8Array(arrayBuffer);
    const binaryString = Array.from(byteData)
      .map((byte) => String.fromCharCode(byte))
      .join("");
    const base64String = btoa(binaryString);
    // console.log(base64String);

    const fileName =
      `Top-10-Transformation-Model-${textCapitalize(data?.title?.sector)}` ||
      "Report";

    mutateDocxDownload({ fileName, pdfBase64: base64String });
    setPdfPrepareToDocxLoading(false);
  };

  useEffect(() => {
    if (pdfPrepareToDocxLoading) {
      downloadDOCX(pdfExportSlice);
    }
  }, [pdfPrepareToDocxLoading]);

  return (
    <PdfExportWrapper>
      {/* <PDFViewer width={1300} height={1000}>
        <V1 data={pdfExportSlice} />
      </PDFViewer> */}
      <div className="title-text-transformation">
        Top 10 Strategic Imperatives in{" "}
        {textCapitalize(pdfExportSlice?.title?.sector)},{" "}
        {pdfExportSlice?.title?.region}
      </div>
      {isTopTenViewDetailsPooling || !data?.items || isLoading ? (
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <div className="skeleton skeleton-btn"></div>
          <div className="skeleton skeleton-btn"></div>
          <div className="skeleton skeleton-btn"></div>
        </div>
      ) : (
        !isLoading &&
        !isTopTenViewDetailsPooling &&
        data?.items?.length > 0 &&
        data?.items?.length === 10 && (
          <div
            style={{ display: "flex", alignItems: "center", gap: "20px" }}
            className="report-download"
          >
            <PDFDownloadLink
              document={<V1 data={pdfExportSlice} />}
              fileName={
                `Top-10-Transformation-Model-${textCapitalize(
                  pdfExportSlice?.title?.sector
                )}` || "Report"
              }
            >
              <PrimaryButton text="Download PDF" onClick={() => {}} />
              {/* {({ loading }) =>
              loading ? (
                <>
                  <PrimaryButton text="Preparing..." onClick={() => {}} />
                </>
              ) : (
                <PrimaryButton text="Download PDF" onClick={() => {}} />
              )
            } */}
            </PDFDownloadLink>
            <PPT />
            <PrimaryButton
              text={
                isDocxDownloadLoading || pdfPrepareToDocxLoading
                  ? "Downloading DOCX..."
                  : "Download DOCX"
              }
              onClick={() => {
                setPdfPrepareToDocxLoading(true);
              }}
              disabled={isDocxDownloadLoading || pdfPrepareToDocxLoading}
            ></PrimaryButton>
          </div>
        )
      )}
    </PdfExportWrapper>
  );
}

const PdfExportWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 40px; */
  background-color: #f3f4f6;
  margin-bottom: 20px;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 8px;
  .title-text-transformation {
    font-size: 18px;
    font-weight: bold;
    color: #0e4c8b;
  }
  a {
    text-decoration: none;
  }
  .skeleton-wrapper {
    /* background-color: #fff; */
  }
  .skeleton-btn {
    height: 40px;
    width: 180px;
    border-radius: 6px;
    /* background-color: aliceblue; */
  }
  /* background-color: red; */
`;
