import { Link, StyleSheet, Text, View } from "@react-pdf/renderer";
import BlueBgTitle from "../components/BlueBgTitle";
import PdfPageLayout from "../Layouts/PdfPageLayout";
import { PdfPageProps } from "../types";
import { textCapitalize } from "../../../utils/textCapitalize";

const styles = StyleSheet.create({
  tocWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    marginTop: 10,
  },
  tocRow: {
    displayL: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textDecoration: "none",
    gap: 0,
    color: "#000",
    fontSize: 11,
  },
  tocText: {
    maxWidth: "80%",
  },
  tocDotContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  tocDot: {
    width: "100%",
    height: 10,
    flex: 1,
    borderBottom: "1px dashed black",
  },
});

export default function TOC1({ size, data }: PdfPageProps) {
  return (
    <PdfPageLayout data={data} size={size}>
      <>
        <BlueBgTitle text="Contents" />
        <View style={styles.tocWrapper}>
          <TOCRow
            text={`Transformation in ${textCapitalize(data?.title.sector)}`}
            pageNo="3"
          />
          <TOCRow
            inner
            text="Why Is It Increasingly Difficult to Grow?"
            pageNo="3"
            pageId="3-1"
          />
          {data?.transformationData?.map((item: any, i: number) => (
            <TOCRow
              inner
              text={`Transformation ${i + 1}: ${item?.strategicImperative}`}
              pageNo={`${i + 7}`}
              pageId={`${i + 7}`}
            />
          ))}
          {/* <TOCRow
            text="Frost & Sullivan Analytics Perspective"
            pageNo="17"
            pageId="17"
          /> */}
          <TOCRow text="Legal Disclaimer" pageNo="17" pageId="17" />
        </View>
      </>
    </PdfPageLayout>
  );
}

interface TOCRowProps {
  inner?: boolean;
  text?: string;
  pageNo: string;
  pageId?: string;
}

export const TOCRow = (props: TOCRowProps) => {
  const { inner, text, pageNo, pageId } = props;
  return (
    <Link
      style={{ ...styles.tocRow, paddingLeft: inner ? 20 : 0 }}
      src={pageId ? `#${pageId}` : `#${pageNo}`}
    >
      <Text style={styles.tocText}>{text}</Text>
      <View style={styles.tocDotContainer}>
        <View style={styles.tocDot}></View>
      </View>
      <Text>{pageNo}</Text>
    </Link>
  );
};
