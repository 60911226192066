import {
  type UseMutationResult,
  useMutation,
  useQueryClient,
} from "react-query";

import { toast } from "react-toastify";
import {
  FResponseDto_1OfOfSystem_String,
  Si8SegmentRequestService,
} from "../../services/frost-si8-services";

const useDeleteSubsegment = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseMutationResult<
  FResponseDto_1OfOfSystem_String | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id) => {
      if (id != null && id !== "") {
        return await handleRequest(
          Si8SegmentRequestService.deleteCoreApiApiAppSi8SegmentRequestSi8Segment(
            id
          )
        );
      }
      return;
    },
    {
      onSuccess: async (response) => {
        if (response != null) {
          toast.success("Subsegement Deleted successfully");
        }
      },
    }
  );
};

export default useDeleteSubsegment;
