import { useMutation, UseMutationResult } from "react-query";
import {
  FSi8_Dtos_SI8DataRequests_SI8DataResponseDto,
  Si8DataRequestService,
} from "../../services/frost-si8-services";
import { toast } from "react-toastify";

const usePutStrategicImperativeRow = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  data?: any
): UseMutationResult<
  FSi8_Dtos_SI8DataRequests_SI8DataResponseDto | undefined,
  unknown,
  any,
  unknown
> => {
  return useMutation(
    async (payload: any) => {
      const rowId = payload?.id;
      const newPayload = {
        ...payload,
      };

      return await handleRequest(
        Si8DataRequestService.putCoreApiApiAppSI8DataRequestSI8Data(
          rowId,
          newPayload
        )
      );
    },
    {
      onSuccess: async (
        response: FSi8_Dtos_SI8DataRequests_SI8DataResponseDto | undefined,
        payload?: any
      ) => {
        if (response != null) {
          await toast.success("Item has been updated.", {
            position: "top-center",
            autoClose: 5000,
            closeOnClick: true,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return true;
        }
      },
      onError: (err) => {
        data.pop();
      },
    }
  );
};

export default usePutStrategicImperativeRow;
