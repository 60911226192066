import styled from "styled-components";

export const StyledGlossary = styled.div`
  overflow-x: auto;
  width: 100%;
  .glossary__wrapper {
    /* background-color: red; */

    .gw-header {
      background-color: #0070c0;
      color: #fff;
      padding: 10px;
      margin-bottom: 20px;
      &--top {
        font-size: 32px;
        sup {
          font-size: 16px;
        }
      }
      &--bottom {
        font-size: 20px;
      }
      &--secondary {
        font-weight: bold;
        text-decoration: underline;
      }
    }
    .gw-header-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      gap: 20px;
      flex-wrap: wrap;
      .left {
        padding: 10px;
        flex: 1;
        background-color: #e7e6e6;
        min-width: 300px;
        p {
          font-size: 16px;
        }
      }
      .right {
        width: 400px;
        flex: 1;
        height: 100%;
        .r-header {
          /* background-color: red; */
          background-color: #305496;
          padding: 10px 10px;
          padding-bottom: 5px;
          display: flex;
          flex-direction: column;
          gap: 4px;

          h1 {
            color: #fff;
          }
          color: #fff;
        }
        .table-container {
          width: 100%;
          border: 1px solid black;
          .highlight-row {
            background-color: #d9d9d9;
          }
          tr {
            td {
              /* background-color: pink; */
              /* place-items: center; */
              display: grid;
              grid-template-columns: 100px auto;
              vertical-align: middle;
              img {
                margin-right: 20px;
                width: 100%;
                mix-blend-mode: darken;
              }
              span {
                padding: 5px;
              }
            }
          }
        }
      }
    }
    .gw-toogle-btn-wrapper {
      display: flex;
      margin-bottom: 20px;
      border-bottom: 1px solid black;
      /* background-color: red; */
      position: sticky;
      top: 20vh;
      button {
        all: unset;
        cursor: pointer;
        padding: 10px 16px;
        font-size: 16px;
        /* background-color: #bcd9ee; */
        background-color: #bfbfbf;
        &.active {
          /* background-color: #00b0f0; */
          background-color: #172d55;
          color: white;
        }
      }
    }

    .gw-table {
      border: 1px solid black;
      margin-bottom: 20px;
      .gwt-head {
        /* background-color: pink; */
        font-weight: bold;
        padding: 10px;
        font-size: 18px;
        background-color: #9bc2e6;
      }
      .gwt-row {
        background-color: #e7e6e6;
        padding: 10px;
        font-size: 16px;
        border-top: 1px solid black;
        b,
        u {
          font-size: 16px;
        }
      }
    }
    .gw-step-table {
      border: 1px solid black;
      .gws-top {
        background-color: gray;
        padding: 10px;
        font-weight: bold;
        font-size: 16px;
        text-decoration: underline;
      }
      .gws-two {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .gws-one {
          padding: 10px;
          font-size: 16px;
          li,
          b,
          u,
          strong {
            font-size: 16px;
          }
          &:first-child {
            border-right: 1px solid black;
          }
          &.gws-one--yellow {
            background-color: #fff2cc;
          }
        }
      }
    }
    .gw-step {
      margin-top: 20px;
      display: flex;
      max-width: 100%;
      overflow: auto;
      .left {
        min-width: 40px;
        display: grid;
        place-items: center;
        background-color: #0070c0;
        font-weight: bolder;
        color: #f5f5f5;
      }
      .right {
        flex: 1;
        .gw-step1 {
          /* background-color: yellow; */
          span {
            color: red;
          }
          .table1 {
            /* background-color: red; */
            width: 100%;
            border: 1px solid black;

            tbody {
              background-color: #9bc2e6;
              tr {
                td {
                  border-right: 1px solid black;
                  min-width: 300px;
                  padding: 10px;
                  &:nth-child(1) {
                    width: 300px;
                  }
                }
              }
            }
          }
          > div {
            padding: 10px;
            border: 1px solid black;
            font-size: 18px;
            font-weight: bold;
          }
          .table2 {
            /* background-color: red; */
            width: 100%;
            border: 1px solid black;
            thead {
              tr {
                th {
                  padding: 10px;
                  border-right: 1px solid black;
                  &:not(:nth-child(1)) {
                    text-align: center;
                  }
                  &:nth-child(1) {
                    width: 300px;
                  }
                  &:nth-child(2) {
                    background-color: #9bc2e6;
                  }
                }
              }
            }
            &.step2table {
              thead,
              tbody {
                tr {
                  th,
                  td {
                    &:nth-child(6),
                    &:nth-child(11) {
                      background-color: #9bc2e6;
                    }
                  }
                }
              }
            }
            &.step5table {
              thead,
              tbody {
                tr {
                  th,
                  td {
                    &:nth-child(2) {
                      background-color: transparent !important;
                    }
                    &:nth-child(6) {
                      background-color: #9bc2e6;
                    }
                  }
                  &:not(:first-child) {
                    td {
                      &:nth-child(5) {
                        background-color: #9bc2e6;
                      }
                    }
                  }
                }
              }
            }
            tbody {
              tr {
                td {
                  padding: 10px;
                  border-right: 1px solid black;
                  border-top: 1px solid black;
                  &:not(:nth-child(1)) {
                    text-align: center;
                  }
                  &:nth-child(2) {
                    background-color: #9bc2e6;
                  }
                }
              }
            }
          }
        }
      }
    }
    .type-of-curve {
      /* background-color: red; */
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
      .left {
        all: unset;
        text-align: left !important;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 10px;
      }

      .right {
        /* background-color: gray; */
        width: 100%;
        img {
          mix-blend-mode: darken;
        }
      }
    }
    .gw-comp {
      display: grid;
      grid-template-columns: 40px auto;
      margin-top: 20px;
      border: 1px solid black;
      .gw-left {
        width: 40px;
        display: grid;
        place-items: center;
        background-color: gray;
        font-weight: bolder;
        color: #f5f5f5;
        border-right: 1px solid black;
      }
      .gw-right {
        display: flex;
        flex-direction: column;
        .gw-right-top {
          padding: 12px;
          > div {
            font-size: 16px;
            font-weight: bold;
            text-decoration: underline;
          }
          border-bottom: 1px solid black;
        }
        .gw-right-content {
          padding: 12px;
          .formula {
            color: red;
            font-size: 20px;
            text-align: center;
            font-weight: bold;
          }
          ul,
          ol {
            li {
              span {
                color: red;
              }
            }
          }
        }
      }
    }
  }
  img {
    border: none;
    height: auto;
    max-width: 100%;
    vertical-align: top;
  }
  .img-wrapper-table {
    min-width: 738px;
    overflow: hidden;
    width: 100%;
  }
  .img-row {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }
  @media (min-width: 1440px) {
    .img-row {
      -webkit-column-gap: 0;
      column-gap: 0;
      display: flex;
      flex-direction: row;
      margin: 0 -10px 20px;
      row-gap: 20px;
    }
  }

  .img-row {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }

  .img-wrapper-tab {
    overflow-x: auto;
    width: 100%;
  }

  .img-wrapper-table {
    min-width: 738px;
    width: 100%;
    overflow: hidden;
  }

  @media (min-width: 1440px) {
    .img-row {
      display: flex;
      margin: 0 -10px 20px;
      row-gap: 20px;
      column-gap: 0;
      flex-direction: row;
    }

    .img-row-wrap {
      flex-wrap: wrap;
    }

    .first-img {
      width: 100%;
      padding: 0 10px;
    }
    .first-img:first-child {
      width: 74%;
    }

    .img-col {
      width: 25.79997%;
      padding: 0 10px;
    }

    .img-col:first-child {
      width: 100%;
    }

    .img-col--full {
      width: 100%;
    }

    .img-col--half {
      width: 50%;
    }

    .img-col--40 {
      width: 40%;
    }
  }
`;
